import React from "react";
import { Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import greenTick from "../../img/greentick.png";
import Otpentercode from "./Otpentercode";

const Passwordreset = () => {
  return (
    <Col lg={6}>
      <div className="loginFieldBox otp">
        <Otpentercode
          heading="Password Reset"
          imgSrc={greenTick}
          paragraph="Password reset successful. Login again to access your account."
        />
        <NavLink className="loginButton" to="/login">
          Login now
        </NavLink>
      </div>
    </Col>
  );
};

export default Passwordreset;
