import {
  ArcElement,
  CategoryScale,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Tooltip,
} from "chart.js";
import { Chart as ChartJS } from "chart.js/auto";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Bar, Doughnut, Line } from "react-chartjs-2";
import { DynamicTooltipbox } from "../Resetpasswordpage/Tooltipbox";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement
);

const ChartGraphTab = ({ data = {} }) => {
  useEffect(() => {
    try {
      if (data.productPrice[0].rowColumn.rows) {
        let { rows } = data.productPrice[0].rowColumn;
        let prices = [];
        let Quantity = [];
        let labels = [];
        rows.forEach((element) => {
          labels.push(element.Type);
          prices.push(Number(element["Unit Price"] || element.UnitPrice) || 0);
          Quantity.push(Number(element.Quantity || element.QTY) || 0);
        });
        let datasets = [
          {
            label: "Unit Price",
            data: prices,
            backgroundColor: "#3B82F6",
          },
          {
            label: "Quantity",
            data: Quantity,
            backgroundColor: "#0EA5E9",
          },
        ];
        setBarChartsData({ labels, datasets });
      }
    } catch (error) {
      console.error();
    }
  }, [data]);
  // Bar-chart
  const [barChartdata, setBarChartsData] = useState({
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "Income",
        data: [5, 8, 6, 9, 3, 7, 8, 4, 8, 2, 6, 7],
        backgroundColor: "#3B82F6",
      },
      {
        label: "Expense",
        data: [3, 6, 4, 7, 2, 5, 8, 2, 6, 1, 4, 5],
        backgroundColor: "#0EA5E9",
      },
    ],
  });
  const optionsBarChart = {
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          color: "rgba(0, 0, 0, 0.1)",
          borderColor: "rgba(0, 0, 0, 0.1)",
        },
        ticks: {
          callback: (value) => (value === 0 ? value : `$${value}`),
        },
      },
      x: {
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        position: "bottom",
      },
    },
    elements: {
      bar: {
        barThickness: 30,
        borderRadius: 10,
      },
    },
  };
  // Pie-chart
  const pieChartdata = {
    labels: ["Income", "expense"],
    datasets: [
      {
        label: ["value"],
        data: [5, 8],
        backgroundColor: ["#F59E0B", "#FCD34D"],
      },
    ],
  };
  const pieChartOptions = {
    radius: "100%",
    cutout: "75%",
    plugins: {
      legend: {
        display: true,
        position: "bottom",
      },
    },
  };
  // Line-chart
  const lineChartdata = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "Income",
        data: [5, 8, 6, 9, 3, 7, 8, 4, 8, 2, 6, 7],
        backgroundColor: "#059669",
        fill: false,
        borderColor: "#059669",
        tension: 0.1,
      },
      {
        label: "Expense",
        data: [3, 6, 4, 7, 2, 5, 5, 2, 6, 1, 4, 5],
        backgroundColor: "#34D399",
        fill: false,
        borderColor: "#34D399",
        tension: 0.1,
      },
    ],
  };
  const optionsLineChart = {
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          color: "rgba(0, 0, 0, 0.1)",
          borderColor: "rgba(0, 0, 0, 0.1)",
        },
        ticks: {
          callback: (value) => (value === 0 ? value : `$${value}k`),
        },
      },
      x: {
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        position: "bottom",
      },
    },
  };

  return (
    <div className="Chartandgraph">
      <Container>
        {/* Bar-chart-start */}
        {/* Bar-chart-start */}
        <Row>
          <Col lg={6}>
            <div className="charts">
              <h5>Chart based on data provided</h5>
              <Bar data={barChartdata} options={optionsBarChart}></Bar>
              <div className="chartBtn">
                <Button>Edit</Button>
              </div>
            </div>
          </Col>
          <Col lg={6}>
            <div className="charts">
              <h5>AI Suggested Chart</h5>
              <Bar data={barChartdata} options={optionsBarChart}></Bar>
              <div className="chartBtn">
                <Button>Edit</Button>
                <Button>
                  <DynamicTooltipbox
                    style={{ justifyContent: "center" }}
                    infoText={"Coming Soon"}
                    label={"Re Generate with AI"}
                  />
                </Button>
              </div>
            </div>
          </Col>
        </Row>
        {/* Pie-chart-start */}
        <Row className="mt-4">
          <Col lg={6}>
            <div className="charts">
              <h5>Chart based on data provided</h5>
              <div className="doughnut">
                <Doughnut
                  data={pieChartdata}
                  options={pieChartOptions}
                ></Doughnut>
              </div>
              <div className="chartBtn">
                <Button>Edit</Button>
              </div>
            </div>
          </Col>
          <Col lg={6}>
            <div className="charts">
              <h5>AI Suggested Chart</h5>
              <div className="doughnut">
                <Doughnut
                  data={pieChartdata}
                  options={pieChartOptions}
                ></Doughnut>
              </div>
              <div className="chartBtn">
                <Button>Edit</Button>
                <Button>Re Generate with AI</Button>
              </div>
            </div>
          </Col>
        </Row>
        {/* Line-chart-start */}
        <Row className="mt-4">
          <Col lg={6}>
            <div className="charts">
              <h5>Chart based on data provided</h5>
              <Line data={lineChartdata} options={optionsLineChart}></Line>
              <div className="chartBtn">
                <Button>Edit</Button>
              </div>
            </div>
          </Col>
          <Col lg={6}>
            <div className="charts">
              <h5>Chart based on data provided</h5>
              <Line data={lineChartdata} options={optionsLineChart}></Line>
              <div className="chartBtn">
                <Button>Edit</Button>
                <Button>Re Generate with AI</Button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ChartGraphTab;
