import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import {
  useGetUserAccountsQuery,
  useGetUserTemplatesQuery,
} from "../../redux-setup/apiSlice";
import Accountaccordian from "./Accountaccordian";
import AccountsPage from "./AccountsPage";
import Frameworkacccordian from "./Frameworkacccordian";
import Frameworkheader from "./Frameworkheader";
import FrameworksPage from "./FrameworksPage";
import PitchDecksPage from "./PitchDecksPage";
import Pitchdeckaccordian from "./Pitchdeckaccordian";
import SideNav from "./SideNav";

const FrameworkHomepageNew = () => {
  const [searchParams] = useSearchParams();
  const view = searchParams.get("view") || "dashboard";
  const [isSideNavCollapsed, setIsSideNavCollapsed] = useState(true);

  const user = useSelector((state) => state.dataSlice.user);
  const { data: templates = {} } = useGetUserTemplatesQuery(user?.id, {
    skip: !user?.id,
  });
  const {
    data: accounts = {},
    isLoading: accountsLoading,
    refetch,
  } = useGetUserAccountsQuery(user?.id, { skip: !user?.id });
  // page-scrolling-top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="homepageFramework">
      <Frameworkheader />
      <div className="container-fluid">
        <div className="row flex-nowrap">
          <SideNav
            isSideNavCollapsed={isSideNavCollapsed}
            setIsSideNavCollapsed={setIsSideNavCollapsed}
          />
          <div
            className={`mainContent ${isSideNavCollapsed ? "expanded" : ""}`}
          >
            {view && view === "dashboard" && (
              <>
                <Accountaccordian
                  accounts={accounts.account}
                  loading={accountsLoading}
                  fetchAccounts={refetch}
                  // deleteAccount={deleteAccount}
                />
                <Pitchdeckaccordian uniqueName="pitchDeck" />
                <Frameworkacccordian
                  templates={templates}
                  uniqueName="FrameworkBox"
                />
                {/* <NoDealMeetingsAccordian data={noDealsMeetingsData} /> */}
              </>
            )}
            {view && view === "accounts" && (
              <AccountsPage
                accounts={accounts.account}
                loading={accountsLoading}
                fetchAccounts={refetch}
              />
            )}
            {view && view === "pitchdecks" && <PitchDecksPage />}
            {view && view === "frameworks" && (
              <FrameworksPage templates={templates} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FrameworkHomepageNew;
