import React from "react";
import Plansdetailbox from "./Plansdetailbox";

const choosePlandata = [
  {
    id: "1",
    price: "0",
    planName: "Free",
    planDetail: "Have a go and test your  superpowers",
    btnText: "Signup for free",
    listDetails: [
      "2 Users",
      "2 Files",
      "Public Share & Comments",
      "Chat Support ",
      "New income apps",
    ],
  },
  {
    id: "2",
    price: "8",
    planName: "Pro",
    saveMoney: "Save 50$ a year",
    planDetail: "Experiment the power of infinite possibilities",
    btnText: "Go Pro",
    listDetails: [
      "4 Users",
      "All apps",
      "Unlimited editable exports",
      "Folders and collaboration  ",
      "All incoming apps",
    ],
  },
  {
    id: "3",
    price: "16",
    planName: "Business",
    planDetail: "Unveil new superpowers and join the Design Leaque",
    btnText: "Go to Business",
    listDetails: [
      "All the features of pro plan",
      "Account success Manager",
      "Single Sign-On (SSO)",
      "Co-conception pogram  ",
      "Collaboration-Soon",
    ],
  },
];

const Sliderbox = () => {
  return (
    <div className="sliderBox">
      {choosePlandata.map((data) => {
        return (
          <>
            <Plansdetailbox data={data} />
          </>
        );
      })}
    </div>
  );
};

export default Sliderbox;
