import React, { Fragment } from "react";
import { Col, Dropdown, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import { ReactComponent as PitchDecksIcon } from "../../img/pitchDecksIcon.svg";
import { formatDate } from "../../utils/CommonFuntions";

export const PlaceholderCard = ({ count = 2 }) => {
  return (
    <>
      {Array.from({ length: count }).map((it, i) => (
        <Col key={i} md={2} className="placeholder-wave ">
          <div className="placeholder rounded-4 h-100 w-100 "></div>
        </Col>
      ))}
    </>
  );
};

const PitchDecksPage = ({
  accounts = [],
  // fetchAccounts,
  //  deleteAccount,
  // loading,
}) => {
  // const [modalDeleteShow, setModalDeleteShow] = useState(false);

  const [modalShow, setModalShow] = React.useState(false);
  // const [selectedAccount, setSelectedAccount] = useState({});
  const navigate = useNavigate();

  return (
    <>
      <div className="frameworksPage">
        <div className="header">
          <div className="titleWithIcon">
            <PitchDecksIcon />
            <h3>Pitch Decks</h3>
          </div>
          {/* === */}
          <div className="headerButtons">
            {/* <div className="displaySelector">
              <button
                className={`${display === "grid" ? "primary-button" : ""}`}
                onClick={() => setDisplay("grid")}
              >
                Grid View
              </button>
              <button
                className={`${display === "list" ? "primary-button" : ""}`}
                onClick={() => setDisplay("list")}
              >
                List View
              </button>
            </div> */}
            <button
              className="popupbtn primary-button"
              onClick={() => setModalShow(true)}
            >
              Add a New Pitch Deck <span>+</span>
            </button>
          </div>

          {/* ========================================= */}
        </div>

        {/* ================================================= */}
        <Row>
          {accounts.map((data) => {
            const { id, accountName, lastMeeting, APR } = data;
            return (
              <Fragment key={id}>
                <Col
                  onClick={() => {
                    // e.preventDefault();
                    navigate(`/opendeals?account=${data.id}`);
                  }}
                  className="col-md-5-cols"
                >
                  <div className="accountBox">
                    <div className="companyName">
                      <div
                        style={{
                          width: 25,
                          height: 25,
                          background: "#ccc",
                          fontWeight: "bold",
                          borderRadius: "50%",
                          marginRight: "10px",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {accountName && accountName[0]?.toUpperCase()}
                      </div>
                      <p className="name">{accountName}</p>
                    </div>

                    <Dropdown onClick={(e) => e.stopPropagation()}>
                      <Dropdown.Toggle
                        variant="transparent"
                        id="dropdown-basic"
                      >
                        <i className="fa-solid fa-ellipsis-vertical"></i>
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item>
                          <button
                            className="editDeletebtn"
                            onClick={() => {
                              setModalShow(true);
                              // setSelectedAccount(data);
                            }}
                          >
                            <i className="fa-solid fa-pen"></i> Edit
                          </button>
                        </Dropdown.Item>

                        <Dropdown.Item>
                          <button
                            className="editDeletebtn"
                            // onClick={() => setModalDeleteShow(true)}
                          >
                            <i className="fa-solid fa-trash-can"></i> Delete
                          </button>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>

                    <p className="lastmeeting">
                      Last Meeting: <span>{formatDate(lastMeeting)}</span>
                    </p>
                    <p className="lastmeeting">
                      ARR: <span>{APR}</span>
                    </p>

                    {/* <ul className="frameworkList">
                        {titles.map((data, i) => (
                          <li key={i}>
                            {isString(data) ? data : data?.name ?? data.title}
                          </li>
                        ))}
                      </ul> */}
                  </div>
                </Col>
              </Fragment>
            );
          })}

          <Col className="col-md-5-cols">
            <button
              className="addAaccountBtn"
              // onClick={() => setModalShow(true)}
              onClick={() => setModalShow(true)}
            >
              <span>+</span>
              Add a New Pitch Deck
            </button>
          </Col>
        </Row>
      </div>
      <PitchdeckModalBox show={modalShow} onHide={() => setModalShow(false)} />
    </>
  );
};

function PitchdeckModalBox(props) {
  const handleDone = () => {
    props.onHide();
  };
  const handleCancel = () => {
    props.onHide();
  };

  return (
    <Modal
      className="frameworkModal"
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Add a New Pitch Deck
        </Modal.Title>
      </Modal.Header>

      <Form className="frameworkPopup">
        <Modal.Body>
          <Row>
            <Col sm={12}>
              <Form.Group>
                <Form.Label>Deal Name</Form.Label>
                <Form.Control type="text" placeholder="Type Here" />
              </Form.Group>
            </Col>
            <Col sm={6}>
              <Form.Group>
                <Form.Label>Select Deal</Form.Label>
                <Form.Select aria-label="Default select example">
                  <option>Select</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col sm={6}>
              <Form.Group>
                <Form.Label>Select Account</Form.Label>
                <Form.Select aria-label="Default select example">
                  <option>Select</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <button type="button" className="cancelBtn" onClick={handleCancel}>
            Close
          </button>
          <button type="button" className="doneBtn" onClick={handleDone}>
            Done
          </button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default PitchDecksPage;
