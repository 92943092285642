import React from "react";
import { Col, Row } from "react-bootstrap";
import automaticallyImage from "../../img/automaticallyRecord.png";
import Button from "./Custombutton";

const Automaticallybox = (props) => {
  const {  headingBlueText, headingText, pointDetails } = props.data;

  return (
    <div className={`automaticallybox commonPadding ${props.className}`}>
      <Row>
        <Col xl={6} lg={6}>
          <div className="details">
            <h3>
              {headingBlueText}
              <span> {headingText}</span>
            </h3>
            <ol>
              {pointDetails.map((data) => {
                return <li>{data}</li>;
              })}
            </ol>
            <Button
              color={"blue"}
              text={"Get Started for free"}
              btnvisible={"dbtn"}
            />
          </div>
        </Col>
        <Col xl={6} lg={6}>
          <div className="img">
            <img src={automaticallyImage} alt="" />
            <div className="bg1"></div>
            <Button
              color={"blue"}
              text={"Get Started for free"}
              btnvisible={"mbtn"}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Automaticallybox;
