import { useRef } from "react";
import uploadImg from "../../img/uploadimg.png";

export const FileUploader = ({ handleFile }) => {
  // Create a reference to the hidden file input element
  const hiddenFileInput = useRef(null);

  // Programatically click the hidden file input element
  // when the Button component is clicked
  const handleClick = (event) => {
    event.preventDefault();
    hiddenFileInput.current.click();
  };
  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file
  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    handleFile(fileUploaded);
  };
  return (
    <>
      <button type="button" className="button-upload" onClick={handleClick}>
        <div>
          <p>
            <img src={uploadImg} alt="uploadImg" />
            Upload Company Logo
          </p>
          <span>Upload</span>
        </div>
      </button>
      <input
        type="file"
        onChange={(e) => handleChange(e)}
        ref={hiddenFileInput}
        style={{ display: "none" }} // Make the file input element invisible
      />
    </>
  );
};
