import React from "react";
import { Col } from "react-bootstrap";
import editimage from "../../img/editimage.png";
import Otpentercode from "../Resetpasswordpage/Otpentercode";
import Questiondetails from "../Resetpasswordpage/Questiondetails";

import verifyAccountImg from "../../img/verifyaccount.png";

const Verifyaccount = ({ email = {} }) => {
  return (
    <Col lg={6}>
      <div className="loginFieldBox otp">
        <Otpentercode
          heading="Verify your account"
          subpara="Check your inbox to login"
          imgSrc={verifyAccountImg}
          paragraph="To complete setup and login, click the verification link in the email, we’ve sent to"
          infPara={email.current}
          editimage={editimage}
        />
        <Questiondetails linkText="Resend Verification" link="/login" />
      </div>
    </Col>
  );
};

export default Verifyaccount;
