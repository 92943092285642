import { useFormik } from "formik";
import React, { useState } from "react";
import { Col } from "react-bootstrap";
import { Icon } from "react-icons-kit";
import { eye } from "react-icons-kit/feather/eye";
import { eyeOff } from "react-icons-kit/feather/eyeOff";
import { useRegisterMutation } from "../../redux-setup/authSlice";
import { registerSchema } from "../../schemas";
import Introdetails from "../Resetpasswordpage/Introdetails";
import Passwordstrength from "../Resetpasswordpage/Passwordstrength";
import Questiondetails from "../Resetpasswordpage/Questiondetails";

const Registeraccount = ({ showComponent2, email }) => {
  const initialValues = {
    email: "",
    password: "",
    confirmpassword: "",
  };
  const [registerUser, { isLoading }] = useRegisterMutation();
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setErrors,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: registerSchema,
    onSubmit: (values, action) => {
      const { password, email } = values;
      const formData = new FormData();
      formData.append("email", email);
      formData.append("password", password);
      formData.append("firstName", "");
      formData.append("lastName", "");
      formData.append("profilePicture", "");

      registerUser(formData)
        .unwrap()
        .then(() => {
          showComponent2();
          action.resetForm();
        })
        .catch((err) => {
          setErrors({
            ...errors,
            status:
              err.status == 409 ? "Email already exists" : "cannot register",
          });
          console.error(err);
        });
    },
  });

  return (
    <Col lg={6}>
      <div className="loginFieldBox">
        <Introdetails heading="Register an account" />
        {/* <Tooltipbox /> */}
        <form onSubmit={handleSubmit}>
          <div className="fieldsBox">
            <div>
              <label>Email</label>
              <input
                placeholder="Enter you email address..."
                name="email"
                type="email"
                value={values.email}
                onChange={(e) => {
                  handleChange(e);
                  email.current = e.target.value;
                }}
                onBlur={handleBlur}
              />
              {errors.email && touched.email ? (
                <p className="form-error">*{errors.email}</p>
              ) : null}
            </div>
            <Passwordfields
              label="Password"
              names="password"
              value={values.password}
              handleChange={handleChange}
              handleBlur={handleBlur}
              error={errors.password}
              touched={touched.password}
            />
            <Passwordfields
              label="Confirm Password"
              names="confirmpassword"
              value={values.confirmpassword}
              handleChange={handleChange}
              handleBlur={handleBlur}
              error={errors.confirmpassword?.replaceAll(
                "confirmpassword",
                "This"
              )}
              touched={touched.confirmpassword}
            />
          </div>

          <Passwordstrength />
          {errors.status ? <p className="form-error">{errors.status}</p> : null}
          <button disabled={isLoading} className="loginButton" type="submit">
            {isLoading ? "Loading . . ." : "Continue"}
          </button>
        </form>

        <Questiondetails
          text="Already have an account?"
          linkText="Login"
          link="/login"
        />
      </div>
    </Col>
  );
};

const Passwordfields = (props) => {
  const { names, value, handleBlur, handleChange, error, touched } = props;

  const { label } = props;
  const [type, setType] = useState("password");
  const [icon, setIcon] = useState(eyeOff);

  const handleToggle = () => {
    if (type === "password") {
      setIcon(eye);
      setType("text");
    } else {
      setIcon(eyeOff);
      setType("password");
    }
  };

  return (
    <div>
      <label>{label}</label>
      <div className="passwordField">
        <input
          type={type}
          // name="password"
          placeholder="Password"
          name={names}
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
          autoComplete="current-password"
        />
        <span onClick={handleToggle}>
          <Icon className="absolute mr-10" icon={icon} size={25} />
        </span>
        {error && touched ? <p className="form-error">*{error}</p> : null}
      </div>
    </div>
  );
};

export default Registeraccount;
