import React, { useEffect, useState } from "react";
import { Col, Dropdown, Form, Modal, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { BsSearch } from "react-icons/bs";
import { toast } from "react-toastify";
import {
  useDeleteDealMutation,
  useUpdateDealMutation,
} from "../../redux-setup/apiSlice";
import { formatDate } from "../../utils/CommonFuntions";
import MultiSelect from "../common/MultiSelect";
import { useDrag, useDrop } from "react-dnd";
import { getItem } from "../../utils/localstorage-config";
import { useSelector } from "react-redux";

// define for DND items
const ItemType = {
  DEAL: "deal",
};

const RightsidebarOpendeal = ({
  deals = {},
  setAddDeal = () => {},
  setSelected = () => {},
  selected,
}) => {
  const [modalShow, setModalShow] = useState(false);
  const [deleteDeal] = useDeleteDealMutation();
  let { accountName, deal = [] } = deals;
  const [searchTerm, setSearchTerm] = useState("");
  // const [selectedItem, setSelectedItem] = useState(null);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleItemClick = (item) => {
    // setSelectedItem(id);
    setSelected(item);
  };

  const filteredData = deal.filter((item) =>
    item.dealName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleInprogressDeals = () => {
    let inprogress = 0;
    let total = 0;
    deal.forEach((it) => {
      if (it.ARR) {
        let result = it.ARR.replace(/[^0-9]/g, "");
        total = total + (result ? Number(result) : 0);
      }
      it.status.includes("Active") && inprogress++;
    });
    return { inprogress, total };
  };
  const { inprogress = 0, total = 0 } = handleInprogressDeals();
  const handleRemove = (id) => {
    toast.promise(deleteDeal(id), {
      pending: "Deleting",
      success: "Deleted Successfully",
      error: "Cannot Delete",
    });
  };

  const [tempDragItems, setTempDragItems] = useState([
    {
      id: 1,
      title: "Customer Data Management",
      summary:
        "The customer is facing challenges with data management and customer information. They are looking for a solution that can help them manage customer data more efficiently and accurately. They are also interested in integrating their current systems with the new solution to streamline the process.",
      source: "left",
      sortingId: 1,
    },
    {
      id: 2,
      title: "Portfolio Reporting and Dashboarding",
      summary:
        "The customer is interested in creating a portfolio reporting and dashboarding solution to track project status, resource allocation, and project health across multiple projects. They are looking for a solution that can provide real-time visibility and automate reporting processes.",
      source: "left",
      sortingId: 2,
    },
  ]);

  const currentBCBTab = useSelector((state) => state.dataSlice.bcbActiveTab);

  // dnd setup
  const [{ isOver }, drop] = useDrop({
    accept: ItemType.DEAL,
    drop: (item) => {
      // Handle the drop action
      let tempItem = item.item;
      let tempArr = [...tempDragItems];
      if (tempDragItems.length) {
        tempArr = tempArr.filter((i) => i.id !== tempItem.id);
      }
      tempItem["source"] = "left";
      tempArr.push(tempItem);
      setTempDragItems([...tempArr]);
      // You can update your state or perform other actions here
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });

  return (
    <div className="sidebarOpendeals">
      <div className="selectedDealData">
        <div className="d-flex justify-content-between  w-100 align-items-center">
          <h5>{accountName}</h5>
        </div>
        <div className="dealAccountBox">
          <p>
            <span>Deals In Progress: {inprogress}</span>
          </p>
          <p>
            Annual Account Spend:<span> ${total}</span>
          </p>
        </div>
      </div>
      <div className="addOrSearchOpenDeals openbuttonbox">
        <button className="bluebtn" onClick={() => setAddDeal(true)}>
          Add a New Deal <span>+</span>
        </button>
        <div className="searchOpenDeals">
          <BsSearch />
          <input
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={handleSearch}
            className="input-hidden"
          />
        </div>
      </div>

      <ul
        style={{
          maxHeight: `calc(100vh - 460px)`,
          paddingRight: "6px",
        }}
        className="listOpenDeals custom-scroll"
      >
        {filteredData?.map((item, i) => (
          <li
            key={item.id}
            style={{
              position: "relative",
            }}
            onClick={() => handleItemClick(item)}
            className={`timelineList deal-dropDown ${
              (selected?.id ? selected.id === item.id : i === 0)
                ? "selected"
                : ""
            }`}
          >
            <Dropdown>
              <Dropdown.Toggle variant="transparent" id="dropdown-basic">
                <i className="fa-solid fa-ellipsis-vertical"></i>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item>
                  <button
                    className="editDeletebtn"
                    onClick={() => setModalShow(item)}
                  >
                    <i className="fa-solid fa-pen"></i> Edit
                  </button>
                </Dropdown.Item>

                <Dropdown.Item>
                  <button
                    className="editDeletebtn"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleRemove(item.id);
                    }}
                  >
                    <i className="fa-solid fa-trash-can"></i> Delete
                  </button>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>{" "}
            <div className="openDealsBoxes">
              {/* === */}
              <div>
                <h5>{item.dealName}</h5>
                <div className="flex-wrap d-flex gap-2 me-1">
                  {Array.isArray(item.status) ? (
                    item.status.map((it) => (
                      <p
                        className={`multiselect status-${it.replace(" ", "-")}`}
                      >
                        {it}
                      </p>
                    ))
                  ) : (
                    <li
                      className={`multiselect status-${it.replace(" ", "-")}`}
                    >
                      {item.status}
                    </li>
                  )}
                </div>
              </div>
              {/* === */}
              <p>{item.dealDetails}</p>
              {/* === */}
              <div className="arrDateValue">
                <p>
                  ARR:<span> ${item.ARR || 0}</span>
                </p>
                <p>
                  Close Date:
                  <span>
                    {" "}
                    {(item.dealDate && formatDate(item.dealDate)) || "N/A"}
                  </span>
                </p>
              </div>
            </div>
            {/* {item.title} */}
          </li>
        ))}
      </ul>

      <DealModalBox
        show={modalShow !== false}
        data={modalShow}
        onHide={() => setModalShow(false)}
      />
      {filteredData.length === 0 && searchTerm.length > 0 && (
        <p className="nomatchingfound">No matching items found.</p>
      )}
    </div>
  );
};

export default RightsidebarOpendeal;

const DNDItemsArea = ({ item, onSelect, tempDragItems, setTempDragItems }) => {
  const [{ isDragging }, drag] = useDrag({
    type: ItemType.DEAL,
    item: { type: ItemType.DEAL, item }, // Use the item object itself
    end: (item) => {
      if (item.item.source === "right")
        setTempDragItems(tempDragItems.filter((e) => e.id !== item.item.id));
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  return (
    <div
      ref={drag}
      style={{ opacity: isDragging ? 0.5 : 1 }}
      onClick={() => onSelect(item)}
      className="bg-white rounded mb-2 p-3 evidenceSummaryTitleBar mt-2 justify-content-between text-editor-wrapper"
    >
      <h5>{item.title}</h5>
      <p className="extensive-summary mt-1 mb-0">{item.summary}</p>
    </div>
  );
};

// export default DNDItem;

function DealModalBox(props) {
  const [dealData, setDealData] = useState({ ...props.data });
  const statusArr = [
    "Renewal",
    "Active",
    "Closed Lost",
    "Expansion",
    "Closed Won",
  ];
  const [updateDeal, { isLoading }] = useUpdateDealMutation();
  useEffect(() => setDealData(props.data), [props.data]);
  const handleChange = (e) => {
    let { name, value } = e.target;
    setDealData({ ...dealData, [name]: value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    let { People, ...withoutPeople } = dealData;
    toast.promise(
      updateDeal({ id: props.data.id, data: { ...withoutPeople } })
        .unwrap()
        .then(() => {
          props.onHide();
        }),
      {
        pending: "Updating",
        success: "Updated Successfully",
        error: "Update Deal failed",
      }
    );
  };
  return (
    <Modal
      className="frameworkModal"
      show={props.show}
      onHide={props.onHide}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Update Deal
        </Modal.Title>
      </Modal.Header>

      <Form className="frameworkPopup" onSubmit={handleSubmit}>
        <Modal.Body>
          <Row>
            <Col sm={12}>
              <Form.Group>
                <Form.Label>Deal Name</Form.Label>
                <Form.Control
                  type="text"
                  defaultValue={dealData.dealName}
                  onChange={handleChange}
                  name="dealName"
                  placeholder="Name Here"
                />
              </Form.Group>
            </Col>
            <Col sm={6}>
              <Form.Group>
                <Form.Label>Deal Close Date</Form.Label>
                <DatePicker
                  // defaultDate={lastCommunication}
                  className="form-control"
                  selected={
                    dealData.dealDate ? new Date(dealData.dealDate) : ""
                  }
                  onChange={(date) =>
                    setDealData({ ...dealData, dealDate: new Date(date) })
                  }
                />
              </Form.Group>
            </Col>
            <Col sm={6}>
              <Form.Group>
                <Form.Label>Status</Form.Label>
                <MultiSelect
                  list={statusArr}
                  value={dealData.status}
                  onChange={(e) => setDealData({ ...dealData, status: e })}
                />
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <button type="reset" className="cancelBtn" onClick={props.onHide}>
            Cancel
          </button>
          <button disabled={isLoading} type="submit" className="doneBtn">
            {isLoading ? "Updateting..." : "Update"}
          </button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
