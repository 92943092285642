import React from "react";
import { Col, Row } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import statisticImg from "../../img/statistics.png";
import Pitchdeckcomponent from "./Pitchdeckcomponent";

const pitchDeckData = [];

function PitchdeckModalBox(props) {
  const handleDone = () => {
    props.onHide();
  };
  const handleCancel = () => {
    props.onHide();
  };

  return (
    <Modal
      className="frameworkModal"
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Add a New Pitch Deck
        </Modal.Title>
      </Modal.Header>

      <Form className="frameworkPopup">
        <Modal.Body>
          <Row>
            <Col sm={12}>
              <Form.Group>
                <Form.Label>Deal Name</Form.Label>
                <Form.Control type="text" placeholder="Type Here" />
              </Form.Group>
            </Col>
            <Col sm={6}>
              <Form.Group>
                <Form.Label>Select Deal</Form.Label>
                <Form.Select aria-label="Default select example">
                  <option>Select</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col sm={6}>
              <Form.Group>
                <Form.Label>Select Account</Form.Label>
                <Form.Select aria-label="Default select example">
                  <option>Select</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <button type="button" className="cancelBtn" onClick={handleCancel}>
            Close
          </button>
          <button type="button" className="doneBtn" onClick={handleDone}>
            Done
          </button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

const Pitchdeckaccordian = ({ uniqueName }) => {
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <>
      <Accordion defaultActiveKey={["0"]} alwaysOpen>
        {/* ======1======= */}
        <div className={`accordianBox ${uniqueName}`}>
          <div className="details">
            <div className="imgDetails">
              <img src={statisticImg} alt="statisticImg" />
              <h3>Pitch Decks</h3>
            </div>
            <button className="popupbtn" onClick={() => setModalShow(true)}>
              Add a New Pitch Deck <span>+</span>
            </button>
          </div>

          {/* ======1======= */}
          <Accordion.Item eventKey="0">
            <Accordion.Header></Accordion.Header>
            <Accordion.Body>
              <Row>
                {pitchDeckData.map((data) => {
                  return (
                    <>
                      {/* <Accountcomponent  /> */}
                      <Pitchdeckcomponent data={data} />
                    </>
                  );
                })}
                <Col md={2}>
                  <button
                    className="addAaccountBtn"
                    onClick={() => setModalShow(true)}
                  >
                    <span>+</span>Add a new Pitch Deck
                  </button>
                </Col>
              </Row>
            </Accordion.Body>
          </Accordion.Item>
        </div>
      </Accordion>

      <PitchdeckModalBox show={modalShow} onHide={() => setModalShow(false)} />
    </>
  );
};

export default Pitchdeckaccordian;
