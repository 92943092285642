import {
  ArcElement,
  CategoryScale,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Tooltip,
} from "chart.js";
import { Chart as ChartJS } from "chart.js/auto";
import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement
);

const BusinessCase = ({
  templates = {},
  evData = {},
  selectedFrameWork,
  setSelectedFrameWork,
}) => {
  // New setup =================================================================
  const { frameWork = [] } = templates;
  const [selectedFrameWorkData, setSelectedFrameWorkData] = useState({});
  const [evidenceData, setEvidenceData] = useState(evData || {});

  useEffect(() => {
    if (selectedFrameWork) {
      setEvidenceData({ ...evData });
      const tempFrameWork = frameWork.find(
        (fw) => parseInt(fw.id) === parseInt(selectedFrameWork)
      );
      if (tempFrameWork) setSelectedFrameWorkData({ ...tempFrameWork });
    } else {
      setEvidenceData({});
      setSelectedFrameWorkData({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [evData, selectedFrameWork]);

  const displayedItems = selectedFrameWorkData.headlines
    ? selectedFrameWorkData.headlines.map((it) => {
        const item = it.headline.title;
        const value = evidenceData?.userContent?.[item] || {
          content: [],
          title: "",
        };
        return [item, value];
      })
    : [];

  const sortContentByScore = (content) => {
    const inCludedContentOnly = content.filter((item) => item.score !== 0);
    const filteredContent = inCludedContentOnly.filter(
      (item) => item.usedInBussinessCase !== true
    );
    return filteredContent.sort((a, b) => b.score - a.score);
  };

  const [listOfExpandedViews, setListOfExpandedViews] = useState([]);
  const [optionsSliceKeys, setOptionsSliceKeys] = useState({});
  const handleExpandedViews = (title) => {
    setOptionsSliceKeys((prevState) => ({
      ...prevState,
      [title]: 0,
    }));
    setListOfExpandedViews((prevExpandedViews) => {
      if (!prevExpandedViews.includes(title)) {
        return [...prevExpandedViews, title];
      } else {
        return prevExpandedViews.filter((viewId) => viewId !== title);
      }
    });
  };

  // const handleUpdateSliceKeys = (title, length) => {
  //   setOptionsSliceKeys((prevState) => ({
  //     ...prevState,
  //     [title]: (optionsSliceKeys[title] + 3) % length,
  //   }));
  // };
  const handleUpdateSliceKeys = (title, length) => {
    setOptionsSliceKeys((prevState) => {
      const newIndex = optionsSliceKeys[title] + 3;
      if (newIndex >= length) {
        return prevState;
      }
      return {
        ...prevState,
        [title]: newIndex,
      };
    });
  };

  const handleUsageInBusinessCase = (item, evidence, mode) => {
    let updatedEvidenceData = structuredClone(evidenceData);
    for (const sectionKey in updatedEvidenceData.userContent) {
      const section = updatedEvidenceData.userContent[sectionKey];
      const matchingItem = section.find(
        (contentItem) => contentItem.title === item.title
      );

      if (matchingItem) {
        const matchingEvidence = matchingItem.content.find(
          (content) => content.evidence === evidence
        );

        if (matchingEvidence) {
          matchingEvidence.usedInBussinessCase =
            mode === "check" ? true : false;
          break;
        }
      }
    }
    setEvidenceData(updatedEvidenceData);
  };
  // New setup End =================================================================

  return (
    <>
      {/* <button >Download PDF</button> */}
      <Container>
        <div className="tabs-wrapper">
          <div className="bcb__evidence">
            <div className="frameworkTitlebox">
              {/* ========== */}
              <Container>
                <Row>
                  <Col sm={12} className="openbuttonbox">
                    <div className="frameworkSelection">
                      <Form.Label
                        style={{
                          fontWeight: 500,
                          fontSize: "14px",
                          lineHeight: "21px",
                          color: "#475569",
                        }}
                      >
                        Framework:{" "}
                      </Form.Label>
                      <Form.Select
                        defaultValue={selectedFrameWork || ""}
                        value={selectedFrameWork}
                        onChange={(e) =>
                          setSelectedFrameWork(
                            e.target.value === "Select" ? "" : e.target.value
                          )
                        }
                        aria-label="Select Framework"
                      >
                        <option>Select</option>;
                        {frameWork?.map((it, i) => (
                          <option key={i} value={it.id}>
                            {it.title}
                          </option>
                        ))}
                      </Form.Select>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>

          {displayedItems?.length > 0 ? (
            displayedItems.map(
              ([item, value], diplayedItemIndex) =>
                value &&
                value.length > 0 && (
                  <div className="overview-tab mt-3" key={diplayedItemIndex}>
                    <div className="overview-tab-title">
                      <h5 className="title">{item ?? ""}</h5>
                    </div>
                    {value.map((item, itemIndex) => (
                      <div className="overview-tab-content" key={itemIndex}>
                        <div className="content-title">
                          <p>{item?.title ?? ""}</p>
                          {!listOfExpandedViews.includes(item.title) ? (
                            <div className="d-flex gap-2">
                              <button
                                type="button"
                                className="content-title-button"
                                onClick={() =>
                                  handleExpandedViews(item?.title || "")
                                }
                                style={{
                                  color: `${
                                    listOfExpandedViews.includes(item.title)
                                      ? "#E11D48"
                                      : "#017CFF"
                                  }`,
                                }}
                              >
                                {`${
                                  item?.content.filter(
                                    (contentItem) =>
                                      contentItem.usedInBussinessCase
                                  ).length > 0
                                    ? "Edit Evidence"
                                    : "Add Evidence"
                                }`}
                              </button>
                            </div>
                          ) : (
                            <div
                              style={{
                                height: "45px",
                              }}
                            ></div>
                          )}
                        </div>
                        <div
                          className={`content-body ${
                            listOfExpandedViews.includes(item.title) &&
                            "edit-mode"
                          }`}
                        >
                          {listOfExpandedViews.includes(item.title) ? (
                            <>
                              <div className="entry-container">
                                {item?.content
                                  .filter(
                                    (contentItem) =>
                                      contentItem.usedInBussinessCase
                                  )
                                  .map((contentItem, index) => (
                                    <div key={index} className="entry-item">
                                      <div className="entry-badge">
                                        Entry {index + 1}
                                      </div>
                                      <p className="entry-evidence">
                                        {contentItem.evidence}
                                      </p>
                                      <div className="settings">
                                        <div className="inclusionSelector">
                                          <button
                                            className="pill"
                                            onClick={() =>
                                              handleUsageInBusinessCase(
                                                item,
                                                contentItem.evidence,
                                                "uncheck"
                                              )
                                            }
                                          >
                                            <svg
                                              width="16"
                                              height="16"
                                              viewBox="0 0 16 16"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <g clipPath="url(#clip0_4434_12411)">
                                                <path
                                                  d="M12 4L4 12"
                                                  stroke="currentColor"
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"
                                                />
                                                <path
                                                  d="M4 4L12 12"
                                                  stroke="currentColor"
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"
                                                />
                                              </g>
                                              <defs>
                                                <clipPath id="clip0_4434_12411">
                                                  <rect
                                                    width="16"
                                                    height="16"
                                                    fill="white"
                                                  />
                                                </clipPath>
                                              </defs>
                                            </svg>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                              </div>
                            </>
                          ) : (
                            <div>
                              {item?.content.filter(
                                (contentItem) => contentItem.usedInBussinessCase
                              ).length > 0 ? (
                                <ul className="bullet-list">
                                  {item?.content
                                    .filter(
                                      (contentItem) =>
                                        contentItem.usedInBussinessCase
                                    )
                                    .map((contentItem, index) => (
                                      <li key={index}>
                                        {contentItem.evidence}
                                      </li>
                                    ))}
                                </ul>
                              ) : (
                                <p className="no-evidence-message">
                                  No evidence points selected
                                </p>
                              )}
                            </div>
                          )}

                          {listOfExpandedViews.includes(item.title) && (
                            <>
                              <div className="options-to-choose">
                                {sortContentByScore(item?.content) &&
                                  sortContentByScore(item?.content).length >
                                    0 &&
                                  sortContentByScore(item?.content)
                                    .slice(
                                      optionsSliceKeys[item?.title] || 0,
                                      (optionsSliceKeys[item?.title] || 0) + 3
                                    )
                                    .map((contentItem, contentItemIndex) => (
                                      <div
                                        key={contentItemIndex}
                                        className="entry-item"
                                      >
                                        <div className="entry-badge">
                                          Option {contentItemIndex + 1}
                                        </div>
                                        {contentItem.evidence}
                                        <div className="settings">
                                          <div className="inclusionSelector">
                                            <button
                                              className={`pill ${
                                                contentItem.usedInBussinessCase ===
                                                  true && "active-green"
                                              }`}
                                              onClick={() =>
                                                handleUsageInBusinessCase(
                                                  item,
                                                  contentItem.evidence,
                                                  "check"
                                                )
                                              }
                                            >
                                              <svg
                                                width="16"
                                                height="16"
                                                viewBox="0 0 16 16"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <g clip-path="url(#clip0_4434_12436)">
                                                  <path
                                                    d="M3.625 8L6.75 11.125L13 4.875"
                                                    stroke="currentColor"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                  />
                                                </g>
                                                <defs>
                                                  <clipPath id="clip0_4434_12436">
                                                    <rect
                                                      width="15"
                                                      height="15"
                                                      fill="white"
                                                      transform="translate(0.5 0.5)"
                                                    />
                                                  </clipPath>
                                                </defs>
                                              </svg>
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                              </div>
                              <div className="operations">
                                <button
                                  className="btn-tertiary"
                                  onClick={() =>
                                    handleExpandedViews(item?.title || "")
                                  }
                                >
                                  Save & Exit
                                </button>
                                <button
                                  className={`btn-secondary ${
                                    (optionsSliceKeys[item?.title] || 0) + 3 >=
                                    sortContentByScore(item?.content).length
                                      ? "disabled"
                                      : ""
                                  }`}
                                  disabled={
                                    (optionsSliceKeys[item?.title] || 0) + 3 >=
                                    sortContentByScore(item?.content).length
                                  }
                                  onClick={() =>
                                    handleUpdateSliceKeys(
                                      item?.title || "",
                                      sortContentByScore(item?.content).length
                                    )
                                  }
                                >
                                  Refresh the List
                                </button>
                                {/* <button
                                className="btn-primary"
                                // onClick={() => handleOperations("save+refresh")}
                              >
                                Save & Refresh
                              </button> */}
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                )
            )
          ) : (
            <p style={{ marginBottom: 0 }}>No data to show</p>
          )}
        </div>
      </Container>
    </>
  );
};

export default BusinessCase;
