import React, { Fragment, useEffect, useState } from "react";
import { Col, Dropdown, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ReactComponent as AccountsIcon } from "../../img/accountsIcon.svg";
import uploadImg from "../../img/uploadimg.png";
import {
  useAccountDeleteMutation,
  useCreatAccountMutation,
  useUpdateAccountMutation,
} from "../../redux-setup/apiSlice";
import { formatDate } from "../../utils/CommonFuntions";
import { FileUploader } from "./FileUploader";

export const PlaceholderCard = ({ count = 2 }) => {
  return (
    <>
      {Array.from({ length: count }).map((it, i) => (
        <Col key={i} md={2} className="placeholder-wave ">
          <div className="placeholder rounded-4 h-100 w-100 "></div>
        </Col>
      ))}
    </>
  );
};

const AccountsPage = ({ accounts = [], fetchAccounts }) => {
  const [modalDeleteShow, setModalDeleteShow] = useState(false);
  const [display, setDisplay] = useState("list");
  const [modalShow, setModalShow] = React.useState(false);
  const [selectedAccount, setSelectedAccount] = useState({});
  const [accountDelete] = useAccountDeleteMutation();
  const navigate = useNavigate();

  const deleteAccount = (id) => {
    accountDelete(id)
      .unwrap()
      .then()
      .catch((err) => console.error(err));
  };

  return (
    <>
      <div className="frameworksPage">
        <div className="header">
          <div className="titleWithIcon">
            <AccountsIcon />
            <h3>Accounts</h3>
          </div>
          {/* === */}
          <div className="headerButtons">
            <div className="displaySelector">
              <button
                className={`${display === "grid" ? "primary-button" : ""}`}
                onClick={() => setDisplay("grid")}
              >
                Grid View
              </button>
              <button
                className={`${display === "list" ? "primary-button" : ""}`}
                onClick={() => setDisplay("list")}
              >
                List View
              </button>
            </div>
            <button
              className="popupbtn primary-button"
              onClick={() => setModalShow(true)}
            >
              Add Account <span>+</span>
            </button>
          </div>

          {/* ========================================= */}
        </div>

        {/* ================================================= */}

        {display === "grid" ? (
          <Row>
            {accounts.map((data) => {
              const { id, accountName, lastMeeting, APR } = data;
              return (
                <Fragment key={id}>
                  <Col
                    onClick={() => {
                      // e.preventDefault();
                      navigate(`/opendeals?account=${data.id}`);
                    }}
                    className="col-md-5-cols"
                  >
                    <div className="accountBox">
                      <div className="companyName">
                        <div
                          style={{
                            width: 32,
                            height: 32,
                            background: "#A5F3FC",
                            color: "#000",
                            fontSize: "14px",
                            lineHeight: "21px",
                            borderRadius: "50%",
                            marginRight: "10px",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {accountName && accountName[0]?.toUpperCase()}
                        </div>
                        <p className="name">{accountName}</p>
                      </div>

                      <Dropdown onClick={(e) => e.stopPropagation()}>
                        <Dropdown.Toggle
                          variant="transparent"
                          id="dropdown-basic"
                        >
                          <i className="fa-solid fa-ellipsis-vertical"></i>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item>
                            <button
                              className="editDeletebtn"
                              onClick={() => {
                                setModalShow(true);
                                setSelectedAccount(data);
                              }}
                            >
                              <i className="fa-solid fa-pen"></i> Edit
                            </button>
                          </Dropdown.Item>

                          <Dropdown.Item>
                            <button
                              className="editDeletebtn"
                              onClick={() => {
                                setSelectedAccount(data);
                                setModalDeleteShow(true);
                              }}
                            >
                              <i className="fa-solid fa-trash-can"></i> Delete
                            </button>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>

                      <p className="lastmeeting">
                        Last Meeting:{" "}
                        <span>{formatDate(lastMeeting) || "N/A"}</span>
                      </p>
                      <p className="lastmeeting">
                        ARR: <span>{APR || "N/A"}</span>
                      </p>
                    </div>
                  </Col>
                </Fragment>
              );
            })}

            <Col className="col-md-5-cols">
              <button
                className="addAaccountBtn"
                // onClick={() => setModalShow(true)}
                onClick={() => setModalShow(true)}
              >
                <span>+</span>
                Add a New Account
              </button>
            </Col>
          </Row>
        ) : (
          // List View
          <div className="listView">
            {accounts.map((data) => {
              const { id, accountName, lastMeeting, APR, updated_at } = data;

              return (
                <Row
                  onClick={() => navigate(`/opendeals?account=${id}`)}
                  className="accountBox cursor-pointer w-100 gap-1 justify-content-between"
                  key={id}
                >
                  <Fragment>
                    <Col className="col-md-5-cols">
                      <div className="list-column">
                        <div
                          style={{
                            width: 32,
                            height: 32,
                            background: "#A5F3FC",
                            color: "#000",
                            fontSize: "14px",
                            lineHeight: "21px",
                            borderRadius: "50%",
                            marginRight: "10px",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {accountName && accountName[0]?.toUpperCase()}
                        </div>
                        <p className="title">{accountName}</p>
                      </div>
                    </Col>
                    <Col className="col-md-5-cols">
                      <div className="list-column">
                        <p className="">Last Meeting:</p>
                        <p className="bold">
                          {formatDate(lastMeeting) || "N/A"}
                        </p>
                      </div>
                    </Col>
                    <Col className="col-md-5-cols">
                      <div className="list-column">
                        <p className="">ARR:</p>
                        <p className="bold">{APR || "N/A"}</p>
                      </div>
                    </Col>
                    <Col className="col-md-5-cols">
                      <div className="list-column">
                        <p className="">Last Updated:</p>
                        <p className="bold">
                          {formatDate(updated_at) || "N/A"}
                        </p>
                      </div>
                    </Col>
                    <Col className="col-auto">
                      <div className="list-column listViewButtons">
                        <button
                          className="edit"
                          onClick={(e) => {
                            e.stopPropagation();
                            setModalShow(true);
                            setSelectedAccount(data);
                          }}
                        >
                          Edit
                        </button>
                        <button
                          className="delete"
                          onClick={(e) => {
                            e.stopPropagation();
                            setSelectedAccount(data);
                            setModalDeleteShow(true);
                          }}
                        >
                          Delete
                        </button>
                        <button className="viewDetails">View Details</button>
                      </div>
                    </Col>
                  </Fragment>
                </Row>
              );
            })}
          </div>
        )}
        <MyDeletePopup
          show={modalDeleteShow}
          id={selectedAccount.id}
          deleteAccount={deleteAccount}
          onHide={() => setModalDeleteShow(false)}
        />
        <AccountModalBox
          show={modalShow}
          data={selectedAccount}
          fetchAccounts={fetchAccounts}
          onHide={() => {
            setModalShow(false);
            selectedAccount?.id && setSelectedAccount({});
          }}
        />
      </div>
    </>
  );
};

// ============Popup-start===============

const AccountModalBox = (props) => {
  const [file, setFile] = useState(null);
  const [name, setName] = useState("");
  const [domain, setDomain] = useState("");
  // const { auth } = use Auth();
  const user = useSelector((state) => state.dataSlice.user);
  const handleFile = (file) => {
    setFile(file);
  };
  useEffect(() => {
    if (props.data?.id) {
      setName(props.data.accountName);
      setDomain(props?.data?.domain || "");
      setFile(props.data.attachment);
    } else {
      setFile(null);
      setName("");
      setDomain("");
    }
  }, [props.data]);
  const [createAccount, { isLoading }] = useCreatAccountMutation();
  const [updateAccount] = useUpdateAccountMutation();
  const handleSubmit = (event) => {
    event.preventDefault();

    if (name.length === 0) {
      alert("No Name");
      return;
    }
    const currentDate = new Date();

    let formData = new FormData();
    formData.append("accountName", name);
    formData.append("domain", domain);
    formData.append("attachment", file);
    if (props.data.id) {
      updateAccount({ id: props.data.id, data: formData, formData: true })
        .unwrap()
        .then(() => {
          setName("");
          setFile(null);
          props.fetchAccounts(user.id);
          props.onHide();
        })
        .catch((err) => console.error(err));
    } else {
      formData.append("APR", "");
      formData.append("lastMeeting", currentDate.toISOString());
      createAccount({ id: user.id, formData })
        .unwrap()
        .then(() => {
          setName("");
          setFile(null);
          props.fetchAccounts(user.id);
          props.onHide();
        })
        .catch((err) => console.error(err));
    }
  };

  return (
    <Modal
      className="frameworkModal"
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.data?.id ? "Update Account" : "Add a New Account"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className="frameworkPopup" onSubmit={(e) => handleSubmit(e)}>
          <Row>
            <Col sm={12}>
              <Form.Group controlId="exampleForm.ControlInput1">
                <Form.Label>Company Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Name Here"
                  required
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
              </Form.Group>
            </Col>
            <Col sm={12}>
              <Form.Group controlId="exampleForm.ControlInput1">
                <Form.Label>Domain</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Domain Here"
                  required
                  value={domain}
                  onChange={(e) => {
                    setDomain(e.target.value);
                  }}
                />
              </Form.Group>
            </Col>
            <Col sm={12}>
              <FileUploader handleFile={handleFile} />
              {file && file.name ? <p>Uploaded file: {file.name}</p> : null}
            </Col>
          </Row>

          <Modal.Footer>
            <button
              type="reset"
              onClick={() => {
                props.onHide();
                setName("");
                setFile(null);
              }}
              className="cancelBtn"
            >
              Close
            </button>
            <button type="submit" disabled={isLoading} className="doneBtn">
              {isLoading ? "Creating..." : "Done"}
            </button>
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

function MyEditPopup(props) {
  const handleDone = () => {
    props.onHide();
  };
  const handleCancel = () => {
    props.onHide();
  };

  const [File, setFile] = useState();
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  return (
    <Modal
      className="frameworkModal"
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Update Account
        </Modal.Title>
      </Modal.Header>
      <Form className="frameworkPopup">
        <Modal.Body>
          <Row>
            <Col sm={12}>
              <Form.Group controlId="exampleForm.ControlInput1">
                <Form.Label>Company Name</Form.Label>
                <Form.Control type="text" placeholder="Name Here" />
              </Form.Group>
            </Col>
            <Col sm={12}>
              {/* <FileUploader handleFile={handleFile}/>
      {fileName ? <p>Uploaded file: {fileName}</p> : null} */}

              <label className="input-label-custom w-100 rounded-pill ">
                <div className="px-3 py-2 d-flex align-items-center justify-content-between">
                  <p className="mb-0">
                    <img
                      src={uploadImg}
                      alt="Upload company logo"
                      className="me-2"
                    />
                    Upload Company Logo
                  </p>
                  <input
                    type="file"
                    className="d-none"
                    onChange={handleFileChange}
                  />

                  <div>
                    <span className="text-decoration-underline">Upload</span>
                  </div>
                </div>
              </label>
              {File && File.name}
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <button type="button" className="cancelBtn" onClick={handleCancel}>
            Close
          </button>
          <button type="button" className="doneBtn" onClick={handleDone}>
            Done
          </button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
function MyDeletePopup(props) {
  const handleDone = () => {
    props.deleteAccount(props?.id);
    props.onHide();
  };
  const handleCancel = () => {
    props.onHide();
  };
  return (
    <Modal
      className="frameworkModal"
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Are you sure you want to delete this?
        </Modal.Title>
      </Modal.Header>
      <Form className="frameworkPopup">
        <Modal.Footer>
          <button type="button" className="cancelBtn" onClick={handleCancel}>
            Close
          </button>
          <button type="button" className="doneBtn" onClick={handleDone}>
            Done
          </button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default AccountsPage;
