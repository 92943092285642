import React from "react";
import { Container, Row } from "react-bootstrap";
import Commonbluesection from "../Loginpage/Commonbluesection";
import Accountregistrationsteps from "./Accountregistrationsteps";

const Accountregistration = () => {
  return (
    <section className="loginpagebox">
      <Container fluid>
        <Row>
          <Commonbluesection />
          <Accountregistrationsteps />
        </Row>
      </Container>
    </section>
  );
};

export default Accountregistration;
