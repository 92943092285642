import { isString } from "formik";
import React, { useEffect, useState } from "react";
import { Col, Container, Dropdown, Modal, Row } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import Form from "react-bootstrap/Form";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { IoIosAddCircleOutline } from "react-icons/io";
import { RxDragHandleDots2 } from "react-icons/rx";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import dnd from "../../img/dnd.png";
import frameworkImg from "../../img/framework.png";
import {
  useCreateTemplateMutation,
  useDeleteTemplateMutation,
  useUpdateTemplateMutation,
} from "../../redux-setup/apiSlice";
import { decodeJWTToken } from "../../utils/jwt-token";
import { getAuthToken } from "../../utils/localstorage-config";

const Frameworkacccordian = ({ uniqueName, templates = {} }) => {
  const [titleData, setTitleData] = useState([
    { id: "0", name: "Evaluation Overview & Background" },
    { id: "1", name: "Object Statement" },
    { id: "2", name: "Problems and Needs" },
    { id: "3", name: "Desired State and Projected Outcome" },
  ]);
  const token = getAuthToken();
  const jwtData = decodeJWTToken(token) ?? {};
  const [modalShow, setModalShow] = useState(false);
  const [modalDeleteShow, setModalDeleteShow] = useState(false);
  const [showModal1, setShowModal1] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [rightSideItems, setRightSideItems] = useState([]);
  const [createTemplate, { isLoading }] = useCreateTemplateMutation();
  const [updateTemplate, { isLoading: updating }] = useUpdateTemplateMutation();
  const [deleteFramwork, { isLoading: deleting }] = useDeleteTemplateMutation();
  const [data, setData] = useState({
    title: "",
    type: "",
    description: "",
  });
  const [isUpdating, setIsUpdating] = useState(false);

  const handleCreate = () => {
    const { title, type, description, titles } = data;
    createTemplate({
      id: jwtData?.userId,
      data: { title, type, description, titles },
    })
      .unwrap()
      .then(() => {
        handleCloseModal1();
        toast.success("Framework created");
      })
      .catch((err) => console.error(err));
  };
  const handleUpdate = () => {
    const { title, type, description, titles } = data;

    updateTemplate({ id: data.id, data: { title, type, description, titles } })
      .unwrap()
      .then(() => {
        handleCloseModal1();
        toast.success("Framework Updated");
      })
      .catch((err) => {
        console.error(err);
        toast.error("Error updating ");
      });
  };
  const handleDrop = (side = "right", item) => {
    let filteredTitleData;
    let temp;
    if (side === "right") {
      filteredTitleData = titleData.filter((it) => it.id !== item.id);
      setTitleData([...filteredTitleData]);
      item["id"] = rightSideItems.length;
      temp = [...rightSideItems, item];
      setRightSideItems(temp);
      setData({
        ...data,
        titles: temp.map((it) => ({
          name: it.name,
        })),
      });
    } else {
      filteredTitleData = rightSideItems.filter((it) => it.id !== item.id);
      setRightSideItems([...filteredTitleData]);
      temp = [...titleData, item];
      setTitleData(temp);
      setData({
        ...data,
        titles: filteredTitleData.map((it) => ({
          name: it.name,
        })),
      });
    }
  };
  const createLeftItems = () => {
    let titles = [];
    templates.frameWork.forEach((it) => {
      it.titles.map((title) => {
        if (!titles.includes(title.name) && title.name) {
          titles.push(title.name);
        }
      });
    });
    titleData.map((it) => titles.push(it.name));
    titles = [...new Set(titles)];
    titles = titles.map((it, i) => {
      return { name: it, id: i };
    });
    setTitleData(titles);
  };
  useEffect(() => {
    if (rightSideItems.length) {
      const filteredTitleData = titleData.filter((titleItem) => {
        return !rightSideItems.some(
          (rightItem) => rightItem.name === titleItem.name
        );
      });
      setTitleData(filteredTitleData);
    }
  }, [rightSideItems]);
  const handleCloseModal1 = () => {
    setShowModal1(false);
    setRightSideItems([]);
  };
  const handleOpenModal2 = (e) => {
    e.preventDefault();
    setShowModal2(true);
  };
  const handleCloseModal2 = () => {
    setShowModal2(false);
  };
  const moveItem = (side = "right", fromIndex, toIndex) => {
    let updatedTitleData = [];
    let [movedItem] = [];
    if (side === "left") {
      updatedTitleData = [...titleData];
      [movedItem] = updatedTitleData.splice(fromIndex, 1);
      updatedTitleData.splice(toIndex, 0, movedItem);
      updatedTitleData.forEach((item, index) => {
        item["id"] = index.toString();
      });
      setTitleData([...updatedTitleData]);
    } else {
      updatedTitleData = [...rightSideItems];
      [movedItem] = updatedTitleData.splice(fromIndex, 1);
      updatedTitleData.splice(toIndex, 0, movedItem);
      updatedTitleData.forEach((item, index) => {
        item["id"] = index.toString();
      });
      setRightSideItems([...updatedTitleData]);
    }
  };
  const handleDelete = () => {
    if (typeof modalDeleteShow === "number") {
      deleteFramwork(modalDeleteShow)
        .unwrap()
        .then(() => {
          setModalDeleteShow(false);
        })
        .catch((err) => {
          console.error(err);
          setModalDeleteShow(false);
        });
    }
  };

  return (
    <>
      <DndProvider backend={HTML5Backend}>
        <Accordion defaultActiveKey={["0"]}>
          <div className={`accordianBox ${uniqueName}`}>
            <div className="details">
              <div className="imgDetails">
                <img src={frameworkImg} alt="accountBankImg" />
                <h3>Frameworks</h3>
              </div>
              {/* === */}
              <NavLink to={"?view=frameworks&template=new"}>
                <button
                  className="popupbtn"
                  // onClick={() => {
                  //   createLeftItems();
                  //   setShowModal1(true);
                  //   setIsUpdating(false);
                  // }}
                >
                  Add Framework <span>+</span>
                </button>
              </NavLink>
              {/* ========================================= */}
            </div>

            {/* First-modal-start */}
            <Modal
              className="frameworkModal frameBox"
              show={showModal1}
              onHide={handleCloseModal1}
              size="xl"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                  {isUpdating ? "Update" : "Create New"} framework
                  <button
                    onClick={isUpdating ? handleUpdate : handleCreate}
                    disabled={isLoading}
                    className="doneBtn"
                  >
                    {isUpdating
                      ? updating
                        ? "Updating..."
                        : "Update"
                      : isLoading
                      ? "Creating..."
                      : "Create"}
                  </button>
                </Modal.Title>
              </Modal.Header>

              <Form className="frameworkPopup">
                <Modal.Body>
                  <Row>
                    <Col sm={6}>
                      <Form.Group>
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                          onChange={(e) =>
                            setData({ ...data, title: e.target.value })
                          }
                          defaultValue={data.title}
                          type="text"
                          placeholder="Type Here"
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={6}>
                      <Form.Group>
                        <Form.Label>Short Description</Form.Label>
                        <Form.Control
                          defaultValue={data.description}
                          onChange={(e) =>
                            setData({ ...data, description: e.target.value })
                          }
                          type="text"
                          placeholder="Type Here"
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    {/* left-side-html */}
                    <Col xl={4} lg={12}>
                      <div className="frameworkTitlebox">
                        <div className="titleBox">
                          <h5>Titles</h5>
                          <button
                            className="doneBtn"
                            onClick={handleOpenModal2}
                          >
                            Add Title
                          </button>
                        </div>

                        <div className="searchBox">
                          <input type="text" placeholder="Search" />
                        </div>

                        <div className="addedFrameworks"></div>
                        {/* ========== */}
                        <Container>
                          <Row>
                            <Col
                              style={{
                                maxHeight: "50vh",
                                paddingRight: "8px",
                              }}
                              className="custom-scroll"
                              sm={12}
                            >
                              <Accordion defaultActiveKey={["-1"]}>
                                {/* 1 */}
                                {titleData.map((item, index) => (
                                  <LeftSideItem
                                    key={item.id}
                                    item={item}
                                    index={index}
                                    moveItem={moveItem}
                                    onDrop={handleDrop}
                                  />
                                ))}
                              </Accordion>
                            </Col>
                          </Row>
                        </Container>
                      </div>
                    </Col>
                    {/* right-side-html */}
                    <Col xl={8} lg={12}>
                      {/* <div className="frameworkDragBox"> */}
                      <div className="frameworkTitlebox selectedFramworks">
                        <div className="titleBox">
                          <h5>Framework</h5>
                        </div>
                        {/* <RightSide
                          items={rightSideItems}
                          onDrop={handleDrop}
                          handleDelete={handleDelete}
                        /> */}
                        <Container
                          style={{
                            height: "100%",
                          }}
                        >
                          <Row
                            style={{
                              height: "100%",
                              width: "100%",
                            }}
                          >
                            <Col
                              style={{
                                maxHeight: "50vh",
                                paddingRight: "8px",
                              }}
                              className="custom-scroll"
                              sm={12}
                            >
                              {/* {titleData.map((item, index) => ( */}
                              {rightSideItems?.length ? (
                                <Accordion defaultActiveKey={["-1"]}>
                                  {rightSideItems.map((item, index) => (
                                    <RightSide
                                      key={item.id}
                                      item={item}
                                      items={rightSideItems}
                                      index={index}
                                      onDrop={handleDrop}
                                      handleDelete={handleDelete}
                                      moveItem={moveItem}
                                    />
                                  ))}
                                </Accordion>
                              ) : (
                                <DragandDropBox onDrop={handleDrop} />
                              )}
                            </Col>
                          </Row>
                        </Container>
                      </div>
                    </Col>
                  </Row>
                </Modal.Body>
              </Form>

              <AddTitlePopup
                show={showModal2}
                handleClose={handleCloseModal2}
                titleData={titleData}
                setTitleData={setTitleData}
              />
            </Modal>
            {/* First-modal-end */}

            {/* ================================================= */}
            <Accordion.Item eventKey="0">
              <Accordion.Header></Accordion.Header>
              <Accordion.Body>
                <Row>
                  {templates.frameWork?.map((data) => {
                    const { id, title: frameworkname, headlines = [] } = data;
                    return (
                      <Col key={id} md={2}>
                        <NavLink to={`?view=frameworks&template=${id}`}>
                          <div className="accountBox">
                            <div className="companyName">
                              <p className="name">{frameworkname}</p>
                            </div>

                            <Dropdown
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                              }}
                            >
                              <Dropdown.Toggle
                                variant="transparent"
                                id="dropdown-basic"
                              >
                                <i className="fa-solid fa-ellipsis-vertical"></i>
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                {/* <Dropdown.Item>
                                  <button
                                    className="editDeletebtn"
                                    onClick={() => handleOpenModal1(data)}
                                    // onClick={() => setModalShow(true)}
                                  >
                                    <i className="fa-solid fa-pen"></i> Edit
                                  </button>
                                </Dropdown.Item> */}
                                <Dropdown.Item>
                                  <button
                                    className="editDeletebtn"
                                    onClick={() => setModalDeleteShow(id)}
                                  >
                                    <i className="fa-solid fa-trash-can"></i>{" "}
                                    Delete
                                  </button>
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>

                            <ul className="frameworkList">
                              {headlines.map((data, i) => (
                                <li key={i}>
                                  {isString(data)
                                    ? data
                                    : data?.headline?.name ??
                                      data?.headline?.title}
                                </li>
                              ))}
                            </ul>
                          </div>
                        </NavLink>
                      </Col>
                    );
                  })}

                  <Col md={2}>
                    <NavLink to={"?view=frameworks&template=new"}>
                      <button
                        className="addAaccountBtn"
                        // onClick={() => setModalShow(true)}
                        // onClick={() => {
                        //   createLeftItems();
                        //   setShowModal1(true);
                        //   setIsUpdating(false);
                        // }}
                      >
                        <span>+</span>
                        Add a New Framework
                      </button>
                    </NavLink>
                  </Col>
                </Row>
              </Accordion.Body>
            </Accordion.Item>
          </div>
        </Accordion>
        <MyDeletePopup
          deleting={deleting}
          show={modalDeleteShow !== false}
          confirm={handleDelete}
          onHide={() => setModalDeleteShow(false)}
        />
        <FrameworkModalBox
          handleCreate={handleCreate}
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
      </DndProvider>
    </>
  );
};

// ============Popup-start===============
function FrameworkModalBox(props) {
  const [data, setData] = useState({
    title: "",
    description: "",
    type: "",
  });

  return (
    <Modal
      className="frameworkModal frameBox"
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Create New framework
          <button onClick={props.handleCreate} className="doneBtn">
            Create
          </button>
        </Modal.Title>
      </Modal.Header>

      <Form className="frameworkPopup">
        <Modal.Body>
          <Row>
            <Col sm={6}>
              <Form.Group>
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  onChange={(e) => setData({ ...data, title: e.target.value })}
                  placeholder="Type Here"
                />
              </Form.Group>
            </Col>
            <Col sm={6}>
              <Form.Group>
                <Form.Label>Short Description</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Type Here"
                  onChange={(e) =>
                    setData({ ...data, description: e.target.value })
                  }
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={4}>
              <div className="frameworkTitlebox">
                <div className="titleBox">
                  <h5>Titles</h5>
                  {/* <button className="doneBtn">Add Title</button> */}
                  <button form="#" className="doneBtn">
                    {" "}
                    Add Title
                  </button>
                </div>
              </div>
            </Col>
            <Col sm={8}>
              <h6 className="text-center">Framework</h6>
            </Col>
          </Row>
        </Modal.Body>
      </Form>
    </Modal>
  );
}
function AddTitlePopup({ show, handleClose, titleData, setTitleData }) {
  const [newTitle, setNewTitle] = useState("");

  const handleChange = (e) => {
    e.preventDefault();
    setNewTitle(e?.target?.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newTitleData = [
      ...titleData,
      { id: titleData.length, name: newTitle },
    ];
    setTitleData(newTitleData);
    handleClose();
  };

  return (
    <Modal
      className="frameworkModal"
      show={show}
      onHide={() => handleClose()}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Add a New Title
        </Modal.Title>
      </Modal.Header>

      <Form className="frameworkPopup" onSubmit={handleSubmit}>
        <Modal.Body>
          <Row>
            <Col sm={12}>
              <Form.Group>
                <Form.Label>Title Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Name Here"
                  name="title"
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <button type="reset" className="cancelBtn" onClick={handleClose}>
            Cancel
          </button>
          <button type="submit" className="doneBtn" onClick={handleSubmit}>
            Done
          </button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
function MyDeletePopup(props) {
  return (
    <Modal
      className="frameworkModal"
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Are you sure you want to delete this?
        </Modal.Title>
      </Modal.Header>
      <div className="frameworkPopup">
        <Modal.Footer>
          <button type="reset" onClick={props.onHide} className="cancelBtn">
            Close
          </button>
          <button
            onClick={props.confirm}
            disabled={props.deleting}
            className="doneBtn"
          >
            {props.deleting ? "Deleting" : "Delete"}
          </button>
        </Modal.Footer>
      </div>
    </Modal>
  );
}
// ============Popup-End===============

// ==========function-start============
const ItemTypes = {
  ACCORDION_ITEM: "accordion-item",
};

const LeftSideItem = ({ item, handleDelete, index, moveItem, onDrop }) => {
  const [, drag] = useDrag({
    type: ItemTypes.ACCORDION_ITEM,
    item: {
      id: item.id,
      name: item.name,
      content: item.content,
      source: "left",
    },
    options: {
      dropEffect: "move",
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: ItemTypes.ACCORDION_ITEM,
    drop: (draggedItem) => {
      if (draggedItem.source === "left") {
        if (draggedItem.id !== index) {
          moveItem("left", draggedItem.id, index);
          draggedItem.id = index;
        }
      } else onDrop("left", draggedItem);
    },
  });

  const [modalDeleteShow, setModalDeleteShow] = useState(false);
  const [showModal2, setShowModal2] = useState(false);

  const handleOpenModal2 = (e) => {
    e.preventDefault();
    setShowModal2(true);
  };
  const handleCloseModal2 = () => {
    // e.preventDefault();
    setShowModal2(false);
  };

  return (
    <>
      {/* <div ref={drag}> */}
      <div ref={(node) => drag(drop(node))}>
        <Accordion.Item eventKey={item.id}>
          <div className="addedFrameworksDetails">
            <div>
              <RxDragHandleDots2 style={{ cursor: "grab" }} />
              {/* <p>{item.id}</p> */}
              <p>{item.name}</p>
            </div>
            <Dropdown>
              <Dropdown.Toggle variant="transparent" id="dropdown-basic">
                <i className="fa-solid fa-ellipsis-vertical"></i>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item>
                  <button className="editDeletebtn" onClick={handleOpenModal2}>
                    <i className="fa-solid fa-pen"></i> Edit
                  </button>
                </Dropdown.Item>
                <Dropdown.Item>
                  <button
                    className="editDeletebtn"
                    onClick={() => setModalDeleteShow(true)}
                  >
                    <i className="fa-solid fa-trash-can"></i> Delete
                  </button>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <Accordion.Header></Accordion.Header>
          <Accordion.Body>
            <ul className="titleCommand">
              <li>Text</li>
              <li>Text</li>
            </ul>
            <button form="#" className="titleAddBtn">
              Add more <IoIosAddCircleOutline />
            </button>
          </Accordion.Body>
        </Accordion.Item>
      </div>

      <AddTitlePopup show={showModal2} handleClose={handleCloseModal2} />

      <MyDeletePopup
        show={modalDeleteShow}
        confirm={handleDelete}
        onHide={() => setModalDeleteShow(false)}
      />
    </>
  );
};
const RightSide = ({ item, onDrop, index, moveItem }) => {
  const [, drag] = useDrag({
    type: ItemTypes.ACCORDION_ITEM,
    item: {
      id: item.id,
      name: item.name,
      content: item.content,
      source: "right",
    },
    options: {
      dropEffect: "move",
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  // const [itemDropped, setItemDropped] = useState(false);
  // useEffect(() => {
  //   if (items.length) {
  //     setItemDropped(true);
  //   }
  // }, []);
  const [, drop] = useDrop({
    accept: ItemTypes.ACCORDION_ITEM,
    drop: (draggedItem) => {
      if (draggedItem.source === "right") {
        if (draggedItem.id !== index) {
          moveItem("right", draggedItem.id, index);
          draggedItem.id = index;
        }
      } else {
        onDrop("right", draggedItem);
        // setItemDropped(true);
      }
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });

  // const [modalDeleteShow, setModalDeleteShow] = useState(false);
  // const [showModal2, setShowModal2] = useState(false);

  const handleOpenModal2 = (e) => {
    e.preventDefault();
    // setShowModal2(true);
  };

  return (
    <>
      <div ref={(node) => drag(drop(node))}>
        <Accordion.Item eventKey={item.id}>
          <div className="addedFrameworksDetails">
            <div>
              <RxDragHandleDots2 />
              <p>{item?.name ?? item?.title}</p>
            </div>
            <Dropdown>
              <Dropdown.Toggle variant="transparent" id="dropdown-basic">
                <i className="fa-solid fa-pen"></i>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item>
                  <button className="editDeletebtn" onClick={handleOpenModal2}>
                    <i className="fa-solid fa-pen"></i> Edit
                  </button>
                </Dropdown.Item>
                <Dropdown.Item>
                  <button
                    className="editDeletebtn"
                    // onClick={() => setModalDeleteShow(true)}
                  >
                    <i className="fa-solid fa-trash-can"></i> Delete
                  </button>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <Accordion.Header></Accordion.Header>
          <Accordion.Body>
            <ul className="titleCommand">
              <li>Text</li>
              <li>Text</li>
            </ul>
            <button form="#" className="titleAddBtn">
              Add more <IoIosAddCircleOutline />
            </button>
          </Accordion.Body>
        </Accordion.Item>
      </div>
    </>
  );
};
// ==========function-end============

// =======drag-and-drop-UI-start=======
// Create a component called Content
const DragandDropBox = ({ onDrop }) => {
  // const [itemDropped, setItemDropped] = useState(false);
  // useEffect(() => {
  //   if (items.length) {
  //     setItemDropped(true);
  //   }
  // }, []);
  const [, drop] = useDrop({
    accept: ItemTypes.ACCORDION_ITEM,
    drop: (item) => {
      onDrop("right", item);
      // setItemDropped(true);
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });
  return (
    <div
      className="dragdropbox"
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        justifyItems: "center",
      }}
      ref={drop}
    >
      <div>
        <img src={dnd} alt="dnd" />
      </div>
      <h5>Add titles</h5>
      <p>Please drag and drop titles to create framework</p>
    </div>
  );
};
// =======drag-and-drop-UI-start=======

export default Frameworkacccordian;
