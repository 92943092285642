import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import parse from "html-react-parser";
import React, { useEffect, useRef, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { GrFormEdit } from "react-icons/gr";
import { toast } from "react-toastify";
import circleAdd from "../../img/circle-add.svg";
import deletePeople from "../../img/delete_people.svg";
import editPeople from "../../img/edit_people.svg";
import {
  useCreatePersonMutation,
  useUpdateDealMutation,
} from "../../redux-setup/apiSlice";
// ProfileImage component
const ProfileImage = ({
  first_name = "",
  last_name = "",
  email = "",
  updatePerson,
  isLoading,
  index,
  designation = "",
  onDelete,
  people,
}) => {
  const [modalDeleteShow, setModalDeleteShow] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [firstNameEdited, setFirstNameEdited] = useState(first_name);
  const [lastNameEdited, setLastNameEdited] = useState(last_name);
  const [emailEdited, setEmailEdited] = useState(email);
  const [editedDesignation, setEditedDesignation] = useState(designation);

  // =============================
  const handleSave = () => {
    updatePerson(index, {
      first_name: firstNameEdited,
      last_name: lastNameEdited,
      email: emailEdited,
      designation: editedDesignation,
    });
    setEditMode(false);
  };
  // =============================
  const handleCancel = () => {
    setEditMode(false);
    setFirstNameEdited(first_name);
    setEmailEdited(email);
    setEditedDesignation(designation);
  };
  // =============================
  const getRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };
  // =============================
  const backgroundColor = useRef(getRandomColor());

  useEffect(() => {
    setFirstNameEdited(first_name);
    setLastNameEdited(last_name);
    setEmailEdited(email);
    setEditedDesignation(designation);
  }, [first_name, last_name, email, designation]);
  return (
    <>
      <div className="d-flex align-items-center me-4 mb-3">
        <div
          style={{
            width: "50px",
            height: "50px",
            borderRadius: "50%",
            display: "flex",
            fontFamily: "Figtree",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "24px",
            fontWeight: "300",
            marginRight: "10px",
            backgroundColor: backgroundColor.current,
            color: "#000000",
          }}
        >
          {`${first_name ? first_name?.charAt(0)?.toUpperCase() : ""}${
            last_name ? last_name?.charAt(0).toUpperCase() : ""
          }`}
        </div>

        <div>
          {editMode ? (
            <div className="editCancel d-flex flex-column">
              <input
                type="text"
                value={firstNameEdited}
                onChange={(e) => setFirstNameEdited(e.target.value)}
                className="profile-name"
              />
              <input
                type="text"
                value={lastNameEdited}
                onChange={(e) => setLastNameEdited(e.target.value)}
                className="profile-name"
              />
              <input
                type="text"
                value={emailEdited}
                onChange={(e) => setEmailEdited(e.target.value)}
                className="profile-email"
              />
              <input
                type="text"
                value={editedDesignation}
                onChange={(e) => setEditedDesignation(e.target.value)}
                className="mt-1 profile-designation"
              />
              <div className="editCancel-btn mt-2">
                <button className="save-button px-3 py-1 " onClick={handleSave}>
                  Save
                </button>
                <button
                  className="cancel-button mx-2 px-3"
                  onClick={handleCancel}
                >
                  Cancel
                </button>
              </div>
            </div>
          ) : (
            <>
              <h5 className="m-0 profile-name">
                {first_name ?? firstNameEdited}
              </h5>
              <p className="m-0 profile-designation">
                {designation ?? editedDesignation}
              </p>
            </>
          )}

          {people && !editMode && (
            <div>
              <img
                src={deletePeople}
                alt={deletePeople}
                onClick={() => setModalDeleteShow(true)}
                style={{ cursor: "pointer" }}
                className="me-2 noman"
              />
              <img
                src={editPeople}
                alt={editPeople}
                style={{ cursor: "pointer" }}
                onClick={() => setEditMode(true)}
                className="m-0"
              />
            </div>
          )}
        </div>
      </div>

      <MyDeletePopup
        isLoading={isLoading}
        show={modalDeleteShow}
        confirm={() => onDelete(index)}
        onHide={() => setModalDeleteShow(false)}
      />
    </>
  );
};
// DeletePopup function

function MyDeletePopup(props) {
  const { isLoading, onHide } = props;
  useEffect(() => {
    if (!isLoading) {
      onHide();
    }
  }, [isLoading]);
  return (
    <Modal
      className="frameworkModal"
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Are you sure you want to delete this?
        </Modal.Title>
      </Modal.Header>
      <Form className="frameworkPopup" onSubmit={(e) => e.preventDefault()}>
        <Modal.Footer>
          <button form="#" onClick={props.onHide} className="cancelBtn">
            Close
          </button>
          <button form="#" onClick={props.confirm} className="doneBtn">
            {props.isLoading ? "Deleting" : "Delete"}
          </button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
const AddMorePeople = (props) => {
  const { isLoading, createPerson, deal, onHide } = props;
  let defaultData = {
    first_name: "",
    last_name: "",
    email: "",
    designation: "",
    id: 1,
  };
  const [data, setData] = useState(defaultData);
  const handleSubmit = (e) => {
    e.preventDefault();
    // let people =
    //   Array.isArray(deal?.people) && deal.people.length > 0
    //     ? [...deal.people, { ...data, id: deal.people?.length + 1 }]
    //     : [data];
    toast.promise(
      createPerson({
        first_name: data?.first_name || "",
        last_name: data?.last_name || "",
        email: data.email,
        designation: data.designation || "",
        dealId: deal.id,
      })
        .unwrap()
        .then(() => {
          setData(defaultData);
          onHide();
        }),
      {
        pending: "Adding Person",
        success: "Person Added Successfully",
        error: "Adding Person Failed",
      }
    );
    // updateDeal({ id: deal.id, data: { people } })
    //   .unwrap()
    //   .then((res) => {
    //     setData(defaultData);
    //     onHide();
    //   })
    //   .catch((err) => console.error(err));
  };
  return (
    <Modal
      className="frameworkModal"
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.data?.id ? "Update Person" : "Add a New Person"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className="frameworkPopup" onSubmit={(e) => handleSubmit(e)}>
          <Row>
            <Col sm={12}>
              <Form.Group controlId="exampleForm.ControlInput1">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="First Name Here"
                  value={data.first_name}
                  onChange={(e) => {
                    setData({ ...data, first_name: e.target.value });
                  }}
                />
              </Form.Group>
            </Col>
            <Col sm={12}>
              <Form.Group controlId="exampleForm.ControlInput1">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Last Name Here"
                  value={data.last_name}
                  onChange={(e) => {
                    setData({ ...data, last_name: e.target.value });
                  }}
                />
              </Form.Group>
            </Col>
            <Col sm={12}>
              <Form.Group controlId="exampleForm.ControlInput1">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Email Here"
                  required
                  value={data.email}
                  onChange={(e) => {
                    setData({ ...data, email: e.target.value });
                  }}
                />
              </Form.Group>
            </Col>
            <Col sm={12}>
              <Form.Group controlId="exampleForm.ControlInput1">
                <Form.Label>Title</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Designation Here"
                  value={data.designation}
                  onChange={(e) => {
                    setData({ ...data, designation: e.target.value });
                  }}
                />
              </Form.Group>
            </Col>
            {/* <Col sm={12}>
              <FileUploader handleFile={handleFile} />
              {file && file.name ? <p>Uploaded file: {file.name}</p> : null}
            </Col> */}
          </Row>

          <Modal.Footer>
            <button
              type="reset"
              onClick={() => {
                props.onHide();
                setData({ name: "", designation: "" });
              }}
              className="cancelBtn"
            >
              Close
            </button>
            <button type="submit" disabled={isLoading} className="doneBtn">
              {isLoading ? "Adding..." : "Add"}
            </button>
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
const OverviewTab = ({ selectedDeal = {} }) => {
  const [updateDeal, { isLoading }] = useUpdateDealMutation();
  const [createPerson] = useCreatePersonMutation();
  const { id, People: peoples = [], dealBackground = "" } = selectedDeal || {};
  const [modalShow, setModalShow] = useState(false);
  const [showEditor, setShowEditor] = useState(false);
  const [editedText, setEditedText] = useState(dealBackground || "");
  const handleSaveChanges = () =>
    updateDealData({ dealBackground: editedText });
  const handleCancelChanges = () => {
    setEditedText(dealBackground ? dealBackground : "");
    setShowEditor(false);
  };
  useEffect(() => {
    setEditedText(dealBackground || "");
  }, [dealBackground]);

  const handleToggleEdit = () => {
    setShowEditor(!showEditor);
  };
  const [people, setPeople] = useState(false);
  const deleteUser = (id) => {
    let copy = [...peoples];
    copy.splice(id, 1);
    updateDealData({ people: copy });
  };
  const updatePerson = (i, data) => {
    let copy = [...peoples];
    copy[i] = { ...copy[i], ...data };
    updateDealData({ people: copy });
  };
  const updateDealData = (data) => {
    toast.promise(
      updateDeal({ id, data })
        .unwrap()
        .then(() => {
          data.dealBackground && setShowEditor(false);
        }),
      {
        pending: "Updating",
        success: "Updated Successfully",
        error: "Update Deal failed",
      }
    );
  };

  return (
    <>
      <div className="tabs-wrapper timeline-tab-content">
        <div className="overview-tab p-3">
          <div className="d-flex justify-content-between">
            <h5 className="overview-tab-title">People</h5>

            {people ? (
              <div className="d-flex align-items-center">
                <button
                  className="cancel-button me-2 px-3"
                  onClick={() => {
                    setPeople(!people);
                  }}
                >
                  cancel
                </button>
                <button
                  className="save-button px-3 py-1 "
                  onClick={() => {
                    setPeople(!people);
                  }}
                >
                  save
                </button>
              </div>
            ) : (
              <GrFormEdit
                size="30"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setPeople(!people);
                }}
              />
            )}
          </div>

          <div className="d-flex flex-wrap align-items-start ">
            <div className="people-boxes d-flex flex-wrap">
              {peoples?.map((user, index) => (
                <ProfileImage
                  index={index}
                  key={index}
                  updatePerson={updatePerson}
                  {...user}
                  isLoading={isLoading}
                  onDelete={deleteUser}
                  people={people}
                />
              ))}
            </div>
          </div>

          <div
            className="people-add d-flex align-items-center"
            onClick={() => {
              setModalShow(true);
              // alert("div clicked");
            }}
          >
            <h6>Add more</h6>
            <img src={circleAdd} alt="" />
          </div>
        </div>
      </div>
      <AddMorePeople
        show={modalShow}
        isLoading={isLoading}
        deal={selectedDeal}
        updateDeal={updateDeal}
        createPerson={createPerson}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

export default OverviewTab;
