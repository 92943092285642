import React from "react";
import greenTick from "../../img/greentick.png";

import { Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import Otpentercode from "../Resetpasswordpage/Otpentercode";

const Youraccountverified = () => {
  return (
    <Col lg={6}>
      <div className="loginFieldBox otp">
        <Otpentercode
          heading="Your is account is verified"
          imgSrc={greenTick}
          paragraph="Your is account is verified continue to login"
        />
        <NavLink className="loginButton" to="/login">
          Login now
        </NavLink>
      </div>
    </Col>
  );
};

export default Youraccountverified;
