import React from "react";

const Introdetails = (props) => {
  const { heading, paragraph } = props;
  return (
    <>
      <h3>{heading}</h3>
      <p>{paragraph}</p>
    </>
  );
};

export default Introdetails;
