import { useRef } from "react";
import { getRandomColor } from "../../utils/CommonFuntions";

const BusinesCasePDF = ({ targetRef }) => {
  const backgroundColor = useRef(getRandomColor());

  return (
    <>
      {/* PDF Page */}
      <div className="pdf-report" ref={targetRef}>
        {/* header section */}
        <section className="header">
          <h2>Acme Inc.</h2>
          <div className="info">
            <p>Report Title:</p>
            <p>
              <strong>Deal Name will come here</strong>
            </p>
            <p>Create Date:</p>
            <p>
              <strong>12/08/2024</strong>
            </p>
            <p>Prepared by:</p>
            <p>
              <strong>Username</strong>
            </p>
          </div>
        </section>
        {/* executive summary */}
        <section className="gap-2">
          <h4>Executive Summary</h4>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
            culpa qui officia deserunt mollit anim id est laborum.
          </p>
        </section>
        {/* company overview */}
        <section className="gap-2">
          <h4>Company Overview</h4>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
            culpa qui officia deserunt mollit anim id est laborum.
          </p>
        </section>
        {/* People + Teams Involved */}
        <section className="gap-2">
          <h4>People + Teams Involved</h4>
          <div className="people-involved-rows">
            <div className="labels">
              <div>
                <div
                  className="pfp"
                  style={{
                    backgroundColor: backgroundColor.current,
                    color: "#fff",
                  }}
                >
                  JB
                </div>
              </div>
              <div className="name-role">
                <p>James Bator</p>
                <p>C.E.O</p>
              </div>
              <div
                className="department"
                style={{
                  backgroundColor: backgroundColor.current,
                }}
              >
                <p style={{ color: "#fff" }}>Operations</p>
              </div>
            </div>
            <div className="content">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat
              </p>
            </div>
          </div>
          <div className="people-involved-rows">
            <div className="labels">
              <div>
                <div
                  className="pfp"
                  style={{
                    backgroundColor: backgroundColor.current,
                    color: "#fff",
                  }}
                >
                  JB
                </div>
              </div>
              <div className="name-role">
                <p>James Bator</p>
                <p>C.E.O</p>
              </div>
              <div
                className="department"
                style={{
                  backgroundColor: backgroundColor.current,
                }}
              >
                <p style={{ color: "#fff" }}>Operations</p>
              </div>
            </div>
            <div className="content">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat
              </p>
            </div>
          </div>
          <div className="people-involved-rows">
            <div className="labels">
              <div>
                <div
                  className="pfp"
                  style={{
                    backgroundColor: backgroundColor.current,
                    color: "#fff",
                  }}
                >
                  JB
                </div>
              </div>
              <div className="name-role">
                <p>James Bator</p>
                <p>C.E.O</p>
              </div>
              <div
                className="department"
                style={{
                  backgroundColor: backgroundColor.current,
                }}
              >
                <p style={{ color: "#fff" }}>Operations</p>
              </div>
            </div>
            <div className="content">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat
              </p>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default BusinesCasePDF;
