import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import Accountregistration from "./components/Accountregistrationpage/Accountregistration";
import Profilesetting from "./components/FrameworkHomepage/Profilesetting";
import Header from "./components/Homepage/Header";
import Login from "./components/Loginpage/Login";
import Opendealspage from "./components/Opendealspage/Opendealspage";
import Resetpassword from "./components/Resetpasswordpage/Resetpassword";
import RequireAuth from "./components/requireauth";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Home from "./pages/Home";

import FrameworkHomepageNew from "./components/FrameworkHomepage/FrameworkHomepageNew";
import GoogleAuth from "./components/GoogleAuth";
import ZoomAuth from "./components/ZoomAuth";
function App() {
  return (
    <>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/resetpassword" element={<Resetpassword />} />
        <Route path="/accountregistration" element={<Accountregistration />} />
        <Route element={<RequireAuth />}>
          <Route
            path="/"
            element={<Navigate to="/frameworkHomepage" replace />}
          />
          <Route path="/zoom-auth" element={<ZoomAuth />} />
          <Route path="/google-auth" element={<GoogleAuth />} />
          <Route path="/frameworkHomepage" element={<FrameworkHomepageNew />} />
          {/* <Route path="/frameworkHomepage" element={<Frameworkhomepage />} /> */}
          {/* <Route path="/frameworkHomepageNew" element={<FrameworkHomepageNew />} /> */}
          <Route path="/opendeals" element={<Opendealspage />} />
          <Route path="/profilesetting" element={<Profilesetting />} />
        </Route>

        {/* <Route path="/testing" element={<Opendealspage />} /> */}

        <Route
          path="/*"
          element={
            <>
              <Header />
              <Routes>
                <Route path="/home" element={<Home />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/about" element={<About />} />
                <Route path="/*" element={<Navigate to="/home" replace />} />
              </Routes>
            </>
          }
        />
        <Route path="/*" element={<Navigate to="/home" replace />} />
      </Routes>
    </>
  );
}

export default App;
