import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Registeraccount from "./Registeraccount";
import Verifyaccount from "./Verifyaccount";
import Youraccountverified from "./Youraccountverified";

const Accountregistrationsteps = () => {
  const navigate = useNavigate();
  
  const [showComp, setShowComp] = useState(0);
  const email = useRef();

  switch (showComp) {
    case 1:
      return (
        <Verifyaccount email={email} showComponent2={() => setShowComp(2)} />
      );
    case 2:
      return <Youraccountverified />;
    default:
      return (
        <Registeraccount
          email={email}
          showComponent2={() => navigate("/login")}
        />
      );
  }
};

export default Accountregistrationsteps;
