import React from "react";

const Plansdetailbox = (props) => {
  const { price, planName, planDetail, btnText, saveMoney, listDetails } =
    props.data;

  return (
    <div className="DetailBbox">
      <h3>{planName}</h3>
      <p>{planDetail}</p>
      <p className="price">
        <sup>$</sup> {price}
      </p>
      <h6>{saveMoney}</h6>
      <div className="planPoints">
        <ul>
          {listDetails.map((data) => {
            return (
              <li>
                <i className="fas fa-check"></i> {data}
              </li>
            );
          })}
        </ul>
        <button>{btnText}</button>
      </div>
    </div>
  );
};

export default Plansdetailbox;
