import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Icon } from "react-icons-kit";
import { eye, eyeOff } from "react-icons-kit/feather";
import { IoIosArrowBack } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import envelope from "../../img/Envelope.svg";
import userImage from "../../img/User.svg";
import camera from "../../img/camera.png";
import editfield from "../../img/editFields.svg";
import profileIcon from "../../img/profile-icon.jpeg";
import { useUserUpdateMutation } from "../../redux-setup/apiSlice";
import { setUser } from "../../redux-setup/dataSlice";
import Frameworkheader from "./Frameworkheader";

const Profilesetting = () => {
  const [userUpdate] = useUserUpdateMutation();
  const dispatch = useDispatch();
  const [profileNameEdit, setProfileNameEdit] = useState(true);
  const [profileLastNameEdit, setProfileLastNameEdit] = useState(true);
  const [emailEdit, setEmailEdit] = useState(true);
  const [passwordEdit, setPasswordEdit] = useState(true);
  const [errors, setErrors] = useState({});
  const user = useSelector((state) => state.dataSlice.user);
  const [profileImage, setProfileImage] = useState(profileIcon);
  const [profileImageFile, setProfileImageFile] = useState("");
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setProfileImageFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfileImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const [password, setPassword] = useState("");
  const [userData, setUserData] = useState(user);
  //password fields data
  useEffect(() => {
    if (user.id) {
      setUserData(user);
      setProfileImage(user.profilePicture);
    }
  }, [user]);
  const [type, setType] = useState(["password", "password"]);
  const handleToggle = (i) => {
    let copy = [...type];
    if (type[i] === "password") {
      copy[i] = "text";
    } else {
      copy[i] = "password";
    }
    setType(copy);
  };
  const cancel = () => {
    !profileLastNameEdit && setProfileLastNameEdit(true);
    !emailEdit && setEmailEdit(true);
    if (!passwordEdit) {
      setPasswordEdit(true);
      setPassword("");
    }
    !profileNameEdit && setProfileNameEdit(true);
    profileImageFile && setProfileImageFile("");
    (errors.newPassword || errors.confirmPassword) && setErrors({});
  };
  const saveData = () => {
    // convert api payload with formData instead of json: awaiting for updated api
    let { email, id, firstName, lastName, password } = userData;

    let formData = new FormData();
    firstName && formData.append("firstName", firstName);
    lastName && formData.append("lastName", lastName);
    password && formData.append("password", password);
    email && formData.append("email", email);
    profileImageFile && formData.append("profilePicture", profileImageFile);

    userUpdate({ data: formData, id })
      .unwrap()
      .then((res) => {
        cancel();
        dispatch(setUser(res));
      })
      .catch((err) => console.error(err));

    // setProfileNameEdit(true);
    // setProfileLastNameEdit(true);
    // setEmailEdit(true);
    // setPasswordEdit(true);
  };
  const handleButtons = () => {
    return !(
      profileLastNameEdit &&
      profileNameEdit &&
      passwordEdit &&
      emailEdit &&
      profileImageFile === ""
    );
  };
  const passwordReg = new RegExp(
    /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+])(?=.{8,})/
  );
  const handleBlur = (e) => {
    let { name, value } = e.target;
    if (name === "confirmPassword") {
      setErrors({
        ...errors,
        confirmPassword:
          userData.password !== password ? "Password must match" : "",
      });
    }
    if (name === "newPassword") {
      setErrors({
        ...errors,
        newPassword:
          value.length < 8
            ? "Password must be at least 8 digit"
            : passwordReg.test(value)
            ? ""
            : "Password must contain a capital letter, a special symbol, and a numeric digit",
      });
    }
  };
  return (
    <>
      <div className="homepageFramework">
        <Frameworkheader />
      </div>

      <div className="OpenDealBox profileSetting">
        <Container fluid>
          <Row>
            <Col md={12}>
              <NavLink to="/frameworkHomepage" className="backTohome">
                <IoIosArrowBack /> Back to Homepage
              </NavLink>
            </Col>
            <Col md={12}>
              <div className="profileBox mt-3">
                <h5>Profile settings</h5>
                {handleButtons() && (
                  <div className="profileBtn">
                    <button
                      onClick={() => {
                        cancel();
                        setUserData(user);
                      }}
                      className="cancel-button"
                    >
                      cancel
                    </button>
                    <button
                      disabled={
                        !passwordEdit &&
                        (errors.confirmPassword || errors.newPassword)
                      }
                      onClick={saveData}
                      className="save-button ms-2 disabled:opacity-50 "
                    >
                      Save
                    </button>
                  </div>
                )}
              </div>
            </Col>

            <Col md={12}>
              <div className="d-flex align-items-center mt-3">
                <label className="upload-picture rounded-circle">
                  <div
                    style={{
                      position: "relative",
                      textAlign: "center",
                      cursor: "pointer",
                    }}
                  >
                    <img src={profileImage} alt="" className="rounded-circle" />

                    {/* Add a <p> tag with the desired text */}
                    {/* <p
                      style={{
                        position: "absolute",
                        top: "55%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        margin: 0,
                        color: "#fff",
                        fontWeight: "bold",
                      }}
                      className="change-picture-text"
                    >
                      Change Picture
                    </p> */}

                    <img
                      alt="camera"
                      src={camera}
                      style={{
                        position: "absolute",
                        top: "43%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        margin: 0,
                        fontWeight: "bold",
                        width: "20px",
                        height: "20px",
                      }}
                      className="change-picture-camera"
                    />

                    {/* Input type file remains unchanged */}
                    <input
                      type="file"
                      className="d-none"
                      onChange={handleFileChange}
                    />
                  </div>
                </label>

                <div className="ms-3">
                  <h5 className="profile-name-img m-0 mb-1">
                    {user?.firstName || user?.lastName
                      ? `${user?.firstName} ${user?.lastName}`
                      : "N/A"}
                  </h5>
                  <p className="profile-subtitle-img m-0">{user.email}</p>
                </div>
              </div>
            </Col>

            <Col md={5}>
              <div className="loginFieldBox">
                <form>
                  <div className="fieldsBox">
                    {/* 1 */}
                    <div>
                      <label>First Name</label>
                      <div className="fieldBox">
                        <div className="field">
                          <img src={userImage} alt={userImage} />
                          <input
                            placeholder="Enter first Name"
                            name="text"
                            style={{ padding: "13px 30px 13px 50px" }}
                            readOnly={profileNameEdit}
                            value={userData?.firstName || ""}
                            onChange={(e) =>
                              setUserData({
                                ...userData,
                                firstName: e.target.value,
                              })
                            }
                          />
                        </div>
                        <img
                          className="ProfileEdit"
                          src={editfield}
                          alt={editfield}
                          style={{ cursor: "pointer" }}
                          onClick={() => setProfileNameEdit(!profileNameEdit)}
                        />
                      </div>
                    </div>
                    <div>
                      <label>Last Name</label>
                      <div className="fieldBox">
                        <div className="field">
                          <img src={userImage} alt={userImage} />
                          <input
                            placeholder="Enter last name"
                            name="text"
                            style={{ padding: "13px 30px 13px 50px" }}
                            readOnly={profileLastNameEdit}
                            value={userData?.lastName || ""}
                            onChange={(e) =>
                              setUserData({
                                ...userData,
                                lastName: e.target.value,
                              })
                            }
                          />
                        </div>
                        <img
                          className="ProfileEdit"
                          src={editfield}
                          alt={editfield}
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            setProfileLastNameEdit(!profileLastNameEdit)
                          }
                        />
                      </div>
                    </div>
                    {/* 2 */}
                    <div>
                      <label>Email</label>
                      <div className="fieldBox">
                        <div className="field">
                          <img src={envelope} alt={envelope} />
                          <input
                            placeholder="Enter you email address..."
                            name="email"
                            style={{ padding: "13px 30px 13px 50px" }}
                            readOnly={emailEdit}
                            value={userData?.email}
                            onChange={(e) =>
                              setUserData({
                                ...userData,
                                email: e.target.value,
                              })
                            }
                          />
                        </div>
                        <img
                          className="emailEdit"
                          src={editfield}
                          alt={editfield}
                          style={{ cursor: "pointer" }}
                          onClick={() => setEmailEdit(!emailEdit)}
                        />
                      </div>
                    </div>
                    <div>
                      <label>New Password</label>
                      <div className="fieldBox">
                        <div className="field">
                          <div className="passwordField">
                            <input
                              type={type[0]}
                              placeholder="Password"
                              name="newPassword"
                              readOnly={passwordEdit}
                              value={password}
                              onBlur={handleBlur}
                              onChange={(e) => setPassword(e.target.value)}
                            />
                            <span
                              onClick={() => handleToggle(0)}
                              style={{ cursor: "pointer" }}
                            >
                              <Icon
                                className="absolute mr-10"
                                icon={type[0] === "text" ? eye : eyeOff}
                                size={25}
                              />
                            </span>
                          </div>
                        </div>
                        <img
                          className="passwordEdit"
                          src={editfield}
                          alt={editfield}
                          style={{ cursor: "pointer" }}
                          onClick={() => setPasswordEdit(!passwordEdit)}
                        />
                      </div>
                      {errors.newPassword && passwordEdit ? (
                        <p className="form-error">*{errors.newPassword}</p>
                      ) : null}
                    </div>

                    {/* 4 */}
                    {passwordEdit ? null : (
                      <div>
                        <label>Confirm Password</label>
                        <div className="fieldBox">
                          <div className="field">
                            <div className="passwordField">
                              <input
                                type={type[1]}
                                value={userData.password}
                                onBlur={handleBlur}
                                name="confirmPassword"
                                autoComplete="off"
                                onChange={(e) =>
                                  setUserData({
                                    ...userData,
                                    password: e.target.value,
                                  })
                                }
                                placeholder="Password"
                              />
                              <span
                                onClick={() => handleToggle(1)}
                                style={{ cursor: "pointer" }}
                              >
                                <Icon
                                  className="absolute mr-10"
                                  icon={type[1] === "text" ? eye : eyeOff}
                                  size={25}
                                />
                              </span>
                            </div>
                          </div>
                          <img
                            className="opacity-0 "
                            src={editfield}
                            alt={editfield}
                          />
                        </div>
                        {errors.confirmPassword && passwordEdit ? (
                          <p className="form-error">
                            *{errors.confirmPassword}
                          </p>
                        ) : null}
                      </div>
                    )}

                    {/* 4 */}
                  </div>
                </form>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Profilesetting;
