import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { CiChat1 } from "react-icons/ci";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { IoReorderThreeOutline } from "react-icons/io5";
import { NavLink, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import chatImg from "../../img/chat-img.png";
import {
  useCreateDealMutation,
  useGetAccountDealsQuery,
} from "../../redux-setup/apiSlice";
import Frameworkheader from "../FrameworkHomepage/Frameworkheader";
import MultiSelect from "../common/MultiSelect";
import BusinessCaseBuilderTab from "./BusinessCaseBuilderTab";
import OverviewTab from "./OverviewTab";
import PitchDeckBuilder from "./PitchDeckBuilder";
import RightsidebarOpendeal from "./RightsidebarOpendeal";
import TimelineTab from "./TimelineTab";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
function DealModalBox(props) {
  const [dealData, setDealData] = useState({
    dealName: "",
    ARR: "",
    // stage: "Active",
    status: [],
  });
  const handleChange = (e) => {
    let { name, value } = e.target;
    setDealData({ ...dealData, [name]: value });
  };
  const [createDeal, { isLoading }] = useCreateDealMutation();
  const handleSubmit = (e) => {
    e.preventDefault();
    const { dealName, ARR, status } = dealData;
    if (dealName.length <= 0) {
      alert("Name not Selected");
      return;
    }
    if (ARR.length <= 0) {
      alert("ARR not Entered");
      return;
    }
    if (status.length <= 0) {
      alert("Status cannot be empty");
      return;
    }

    createDeal({ id: props.account, data: dealData })
      .unwrap()
      .then(() => {
        toast.success("Deal created successfully");
        props.onHide();
      })
      .catch((err) => {
        console.error(err);
        toast.error("Cannot create a deal. Please try again");
      });
  };
  const statusArr = [
    "Renewal",
    "Active",
    "Closed Lost",
    "Expansion",
    "Closed Won",
  ];
  return (
    <Modal
      className="frameworkModal"
      show={props.show}
      onHide={props.onHide}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Add a New Deal
        </Modal.Title>
      </Modal.Header>

      <Form className="frameworkPopup" onSubmit={handleSubmit}>
        <Modal.Body>
          <Row>
            <Col sm={6}>
              <Form.Group>
                <Form.Label>Header Name</Form.Label>
                <Form.Control
                  type="text"
                  onChange={handleChange}
                  name="dealName"
                  placeholder="Name Here"
                />
              </Form.Group>
            </Col>
            <Col sm={6}>
              <Form.Group>
                <Form.Label>Deal ARR</Form.Label>
                <Form.Control
                  name={"ARR"}
                  onChange={handleChange}
                  type="number"
                  min={0}
                  placeholder="Enter ARR"
                />
              </Form.Group>
            </Col>
            {/* <Col sm={6}>
              <Form.Group>
                <Form.Label>Stage</Form.Label>
                <Form.Select
                  name="stage"
                  aria-label="Default select example"
                  onChange={handleChange}
                >
                  {statusArr.map((it) => (
                    <option key={it} value={it}>
                      {it}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col> */}
          </Row>
          <Row>
            <Col sm={12}>
              <Form.Group>
                <Form.Label>Status</Form.Label>
                <MultiSelect
                  list={statusArr}
                  value={dealData.status}
                  onChange={(e) =>
                    handleChange({ target: { name: "status", value: e } })
                  }
                />
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <button type="reset" className="cancelBtn" onClick={props.onHide}>
            Cancel
          </button>
          <button disabled={isLoading} type="submit" className="doneBtn">
            {isLoading ? "Creating..." : "Done"}
          </button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
const Opendealspage = () => {
  const [isRightSidebarOpen, setIsRightSidebarOpen] = useState(false);
  const [isLeftSidebarOpen, setIsLeftSidebarOpen] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  let account = searchParams.get("account");
  let currentTab = searchParams.get("tab") || 1;
  const [modalShow, setModalShow] = useState(false);
  const { data: state = {} } = useGetAccountDealsQuery(account, {
    skip: !account,
  });
  const [selected, setSelected] = useState({});
  // const {
  //   data: selectedDeal = {},
  //   isLoading,
  //   isFetching,
  // } = useGetSingleDealQuery(selected?.id, {
  //   skip: !selected.id,
  // });
  let dealLoading = false; // isLoading || isFetching;
  useEffect(() => {
    if (state.deal?.length) {
      if (selected?.id) {
        setSelected(state.deal.find((it) => it.id === selected.id));
      } else {
        setSelected(state.deal[0]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);
  // page-scrolling-top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleRightSidebarClick = () => {
    setIsRightSidebarOpen((prev) => !prev);
    setIsLeftSidebarOpen(false);
  };

  const handleLeftSidebarClick = () => {
    setIsLeftSidebarOpen((prev) => !prev);
    setIsRightSidebarOpen(false);
  };

  const tabsToggler = (id) => setSearchParams({ account, tab: id });

  return (
    <>
      <div className="homepageFramework">
        <Frameworkheader />
      </div>

      <DndProvider backend={HTML5Backend}>
        <div className="OpenDealBox">
          <Container fluid>
            <Row>
              <Col md={12}>
                <NavLink to="/frameworkHomepage" className="backTohome">
                  <IoIosArrowBack /> Back to Homepage
                </NavLink>
              </Col>
            </Row>

            <Row>
              <Col>
                <div className="openbuttonbox d-flex justify-content-lg-between align-items-lg-center align-items-start flex-lg-row flex-column">
                  <div className="openbtn">
                    <button
                      className="bluebtn"
                      onClick={handleLeftSidebarClick}
                      disabled={isRightSidebarOpen}
                    >
                      {isLeftSidebarOpen ? (
                        <IoIosArrowBack />
                      ) : (
                        <IoReorderThreeOutline />
                      )}
                      {isLeftSidebarOpen ? "Collapse Deals" : "Open Deals"}
                    </button>
                  </div>

                  {/* tab-html-start */}
                  <div className="tab-names-component">
                    <div className="tab-names-component">
                      <Button
                        variant=""
                        onClick={() => tabsToggler(1)}
                        className={currentTab == 1 ? "active-tab" : ""}
                      >
                        Timeline
                        {/* Overview */}
                      </Button>
                      <Button
                        variant=""
                        onClick={() => tabsToggler(3)}
                        className={currentTab == 3 ? "active-tab" : ""}
                      >
                        Business Case Builder
                      </Button>
                      <Button
                        variant=""
                        onClick={() => tabsToggler(4)}
                        className={currentTab == 4 ? "active-tab" : ""}
                      >
                        Pitch Deck Builder
                      </Button>
                    </div>
                    {/* tab-html-end */}
                    {/* <div> */}
                    {/* <button
                    className="bluebtn"
                    onClick={() => setModalShow(true)}
                  >
                    Add a New Deal <span>+</span>
                  </button> */}
                    <button
                      className="bluebtn chat"
                      onClick={handleRightSidebarClick}
                      // disabled={isLeftSidebarOpen}
                    >
                      <CiChat1 />
                      {isRightSidebarOpen ? "Close Chat" : "Open Chat"}
                      {isRightSidebarOpen ? <IoIosArrowForward /> : ""}
                    </button>
                    {/* </div> */}
                  </div>
                </div>
              </Col>
            </Row>

            <Row>
              {/* =======Left-side======= */}
              <div className="layout">
                {isLeftSidebarOpen && (
                  <div className="sidenav">
                    <RightsidebarOpendeal
                      setAddDeal={setModalShow}
                      setSelected={setSelected}
                      selected={selected}
                      deals={state}
                    />
                    {/* <h2>Left Sideba r</h2> */}
                  </div>
                )}

                {/* =======Main-content======= */}
                <div
                  className={
                    isRightSidebarOpen || isLeftSidebarOpen
                      ? "middleside sidenavopen"
                      : "middleside"
                  }
                >
                  <div
                    className={
                      currentTab == 1
                        ? "display-content-tabs"
                        : "hide-content-tabs"
                    }
                  >
                    <OverviewTab
                      dealLoading={dealLoading}
                      selectedDeal={selected}
                      data={state}
                    />
                    <TimelineTab
                      data={state}
                      dealLoading={dealLoading}
                      deal={selected}
                    />
                  </div>
                  {/* <div
                className={
                  currentTab == 2 ? "display-content-tabs" : "hide-content-tabs"
                }
              ></div> */}

                  <div
                    className={
                      currentTab == 3
                        ? "display-content-tabs"
                        : "hide-content-tabs"
                    }
                  >
                    <BusinessCaseBuilderTab deal={selected} />
                  </div>

                  <div
                    className={
                      currentTab == 4
                        ? "display-content-tabs"
                        : "hide-content-tabs"
                    }
                  >
                    <PitchDeckBuilder />
                  </div>

                  {/* <DynamicTable/> */}
                </div>

                {/* =======Right-side======= */}
                {isRightSidebarOpen && (
                  <div className="sidenav mt-3">
                    <img
                      src={chatImg}
                      alt={chatImg}
                      style={{ width: "100%" }}
                    />
                  </div>
                )}
              </div>
            </Row>
          </Container>
        </div>
      </DndProvider>

      <DealModalBox
        show={modalShow}
        account={account}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

export default Opendealspage;
