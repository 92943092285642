import React, { useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import logo from "../img/logo.png";
import zoomimg from "../img/zoomimg.png";
import { useZoomAuthMutation } from "../redux-setup/apiSlice";

const ZoomAuth = () => {
  const [searchParams] = useSearchParams();
  const user = useSelector((state) => state.dataSlice.user);
  let account = searchParams.get("code");
  const [zoomAuth, { isLoading, isError }] = useZoomAuthMutation();
  const navigate = useNavigate();
  useEffect(() => {
    if (user?.id) {
      zoomAuth({ code: account, type: "zoom", userId: user.id })
        .unwrap()
        .catch((err) => console.error(err));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);
  return (
    <div
      style={{
        height: "100dvh",
      }}
      className="d-flex flex-column justify-content-center align-items-center"
    >
      <div
        style={{
          background: "linear-gradient(264.55deg, #1c2c65 0%, #017cff 100%)",
        }}
        className="w-100 fixed-top  align-self-start  justify-content-evenly  d-flex align-items-center "
      >
        <img src={logo} alt="logo" />
        <img src={zoomimg} alt="zoomimg" />
      </div>
      {isLoading ? (
        <Spinner />
      ) : isError ? (
        <>Error Authenticating Zoom Please try again</>
      ) : (
        <>
          <h1>Zoom integration successfull</h1>
          <p>Redirecting you to homepage . . .</p>
        </>
      )}
      {!isLoading && (
        <button
          className="updateDealBtn mt-3 "
          onClick={() => navigate("/frameworkHomepage")}
        >
          Homepage
        </button>
      )}
    </div>
  );
};

export default ZoomAuth;
