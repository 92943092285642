import { useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import googleMeetimg from "../../img/googlemeetimg.png";
import logo from "../../img/logo.png";
import zoomimg from "../../img/zoomimg.png";
import {
  useLazyConnectZoomQuery,
  useLazyGoogleRedirectQuery,
} from "../../redux-setup/apiSlice";
function MeetingModalBox(props = {}) {
  let { zoomIntegration = false, googleIntegration = false } = props;
  const [zoomConnect, { isZoomLoading }] = useLazyConnectZoomQuery();
  const [googleConnect, { isGoogleLoading }] = useLazyGoogleRedirectQuery();
  const connectZoom = () => {
    zoomConnect()
      .unwrap()
      .then((res) => {
        let myWindow = window.open(res.RedirectURL, "width=600,height=500");
        let interval = setInterval(() => {
          if (myWindow?.closed) {
            clearInterval(interval);
            props.onHide();
          }
        }, 500);
        // let win=window.open(res.RedirectURL,500,500)
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const connectGoogle = () => {
    googleConnect()
      .unwrap()
      .then((res) => {
        let myWindow = window.open(res.url, "width=600,height=500");
        let interval = setInterval(() => {
          if (myWindow?.closed) {
            clearInterval(interval);
            props.onHide();
          }
        }, 500);
        // let win=window.open(res.RedirectURL,500,500)
      })
      .catch((err) => console.error(err));
  };
  return (
    <Modal
      className="frameworkModal"
      {...props}
      onHide={props.onHide}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Connect</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Row className="mt-4">
          <Col className="d-flex">
            <div className="d-flex w-100 align-items-center ">
              <img src={zoomimg} alt="zoomimg" />
              <div className="ms-4">Connect Zoom</div>
            </div>
            <Modal.Footer className="mt-0">
              <button
                disabled={isZoomLoading || zoomIntegration}
                onClick={() => connectZoom()}
                className="doneBtn"
                style={{
                  background: zoomIntegration ? "green" : "",
                }}
              >
                {zoomIntegration
                  ? "Connected"
                  : isZoomLoading
                  ? "Connecting..."
                  : "Connect"}
              </button>
            </Modal.Footer>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col className="d-flex">
            <div className="d-flex w-100 align-items-center ">
              <img src={googleMeetimg} alt="googleMeetimg" />
              <div className="ms-4">Connect Google</div>
            </div>
            <Modal.Footer className="mt-0">
              <button
                disabled={isGoogleLoading || googleIntegration}
                onClick={() => connectGoogle()}
                className="doneBtn"
                style={{
                  background: googleIntegration ? "green" : "",
                }}
              >
                {googleIntegration
                  ? "Connected"
                  : isZoomLoading
                  ? "Connecting..."
                  : "Connect"}
              </button>
            </Modal.Footer>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}
function Frameworkheader() {
  const [integrationModal, SetIntegrationModal] = useState(false);
  const user = useSelector((state) => state.dataSlice.user);

  const zoomIntegration =
    Array.isArray(user?.integration) &&
    user?.integration?.some((it) => it.type === "zoom");
  const googleIntegration =
    Array.isArray(user?.integration) &&
    user?.integration?.some((it) => it.type === "google");
  const [zoomConnect, { isZoomLoading }] = useLazyConnectZoomQuery();
  const [googleConnect, { isGoogleLoading }] = useLazyGoogleRedirectQuery();
  const connectZoom = () => {
    zoomConnect()
      .unwrap()
      .then((res) => {
        let myWindow = window.open(res.RedirectURL, "width=600,height=500");
        let interval = setInterval(() => {
          if (myWindow?.closed) {
            clearInterval(interval);
            SetIntegrationModal(false);
          }
        }, 500);
        // let win=window.open(res.RedirectURL,500,500)
      })
      .catch((err) => console.error(err));
  };
  const connectGoogle = () => {
    googleConnect()
      .unwrap()
      .then((res) => {
        let myWindow = window.open(res.url, "width=600,height=500");
        let interval = setInterval(() => {
          if (myWindow?.closed) {
            clearInterval(interval);
            SetIntegrationModal(false);
          }
        }, 500);
        // let win=window.open(res.RedirectURL,500,500)
      })
      .catch((err) => console.error(err));
  };
  return (
    <>
      <MeetingModalBox
        user={user}
        show={integrationModal}
        onHide={() => SetIntegrationModal(false)}
        zoomIntegration={zoomIntegration}
        googleIntegration={googleIntegration}
      />
      <Navbar expand="sm" className="frameworkHeader">
        <Container fluid>
          <Navbar.Brand>
            <NavLink to="/frameworkHomepage">
              {" "}
              <img src={logo} />
            </NavLink>
            {/* <img src={logo}/> */}
          </Navbar.Brand>
          {/* <Navbar.Toggle aria-controls="basic-navbar-nav" /> */}
          <Nav>
            <div
              className={` ${
                !zoomIntegration
                  ? "integrationDetails disconnectedIntegraion"
                  : "integrationDetails"
              }`}
              onClick={() => {
                if (!zoomIntegration) {
                  connectZoom();
                }
              }}
              title="Click to connect Zoom!"
            >
              <img src={zoomimg} alt="zoomimg" />
              <div className="content">
                <p className="title">Zoom</p>
                <p
                  className="status"
                  style={{
                    color: isZoomLoading
                      ? "#fff"
                      : zoomIntegration
                      ? "#4ADE80"
                      : "#F87171",
                  }}
                >
                  {zoomIntegration
                    ? "Connected"
                    : isZoomLoading
                    ? "Connecting..."
                    : "Disconnected"}
                </p>
              </div>
            </div>

            <div
              className={` ${
                !googleIntegration
                  ? "integrationDetails disconnectedIntegraion"
                  : "integrationDetails"
              }`}
              onClick={() => {
                if (!googleIntegration) {
                  connectGoogle();
                }
              }}
              title="Click to connect Google Meet!"
            >
              <img src={googleMeetimg} alt="googleMeetimg" />
              <div className="content">
                <p className="title">Google</p>
                <p
                  className="status"
                  style={{
                    color: isGoogleLoading
                      ? "#fff"
                      : googleIntegration
                      ? "#4ADE80"
                      : "#F87171",
                  }}
                >
                  {googleIntegration
                    ? "Connected"
                    : isGoogleLoading
                    ? "Connecting..."
                    : "Disconnected"}
                </p>
              </div>
            </div>
            {/* <NavDropdown
              className="logout"
              title={
                <div className="userDetails">
                  <img
                    className="rounded-circle"
                    style={{
                      width: "48px",
                      height: "48px",
                    }}
                    src={
                      user?.profilePicture ? user.profilePicture : profileIcon
                    }
                    alt={user?.firstName}
                  />
                  <div>
                    <p>
                      {user?.firstName || user?.lastName
                        ? user.firstName + " " + user.lastName
                        : "N/A"}
                      <br></br> <span>{user?.email || "N/A"}</span>
                    </p>
                  </div>
                  <IoIosArrowDown />
                </div>
              }
            >
              <NavDropdown.Item href="#">
                <NavLink to="/profilesetting" style={{ color: "#000" }}>
                  {" "}
                  <img src={Setting} alt={Setting} />
                  Profile Setting
                </NavLink>
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => SetIntegrationModal(true)}>
                <img src={Setting} alt={Setting} />
                Integrations
              </NavDropdown.Item>
              <NavDropdown.Item onClick={logout} style={{ color: "red" }}>
                <img src={signOut} alt={signOut} /> Logout
              </NavDropdown.Item>
            </NavDropdown> */}
          </Nav>
        </Container>
      </Navbar>
    </>
  );
}

export default Frameworkheader;
