import React, { useState } from "react";
import googleSlidesIcon from "../../img/pitch-deck/tool-g-slides.png";
import powerPointIcon from "../../img/pitch-deck/tool-powerpoint.png";
import gammaIcon from "../../img/pitch-deck/tool-gamma.png";
import slideBeansIcon from "../../img/pitch-deck/tool-slide-beans.png";
import { Col, Form, Modal, Row } from "react-bootstrap";

const PitchDeckBuilder = () => {
  const [showSetupScreen, setShowSetupScreen] = useState(true);
  return (
    <>
      <div className="pitch-deck-builder">
        {showSetupScreen && <SetupScreen />}
      </div>
    </>
  );
};

const SetupScreen = () => {
  const [showWizardModal, setShowWizardModal] = useState({
    show: false,
    tool: "",
  });
  const toolsData = [
    {
      id: 1,
      title: "Google Slides",
      description: "Google Slides Description",
      icon: googleSlidesIcon,
      alt: "some-alt-text",
      color: "#F5BA15",
    },
    {
      id: 2,
      title: "PowerPoint",
      description: "PowerPoint Description",
      icon: powerPointIcon,
      alt: "some-alt-text",
      color: "#d35230",
    },
    {
      id: 3,
      title: "Gamma",
      description: "Gamma Description",
      icon: gammaIcon,
      alt: "some-alt-text",
      color: "#9f0cf7",
    },
    {
      id: 4,
      title: "Slidebeans",
      description: "Slidebeans Description",
      icon: slideBeansIcon,
      alt: "some-alt-text",
      color: "#fa4371",
    },
  ];
  return (
    <>
      <div className="setup-screen">
        <div className="header">
          <h3>Create a new Pitch Deck</h3>
          <p>Please select, how you want to create your Pitch Deck!</p>
        </div>
        <div className="tools">
          {toolsData.map((tool, toolIndex) => (
            <div
              className="card"
              key={toolIndex}
              style={{
                borderColor: tool.color,
              }}
              onClick={() =>
                setShowWizardModal({ show: true, tool: tool.title })
              }
            >
              <img src={tool.icon} alt={tool.alt} />
              <div className="content">
                <h5
                  style={{
                    color: tool.color,
                  }}
                >
                  {tool.title}
                </h5>
                <p>{tool.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      {showWizardModal.show && (
        <WizardModal
          tool={showWizardModal.tool}
          show={showWizardModal}
          setShow={setShowWizardModal}
        />
      )}
    </>
  );
};

const WizardModal = ({ tool, show, setShow }) => {
  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      className="modal-center"
      centered
    >
      <Form className="pitchDeckPopup">
        <Modal.Header closeButton>
          <Modal.Title>Create Pitch Deck with {tool}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <p>
            This wizard will guide you through the process of creating your
            Pitch Deck using {tool}.
          </p> */}
          <Form.Group className="pitchDeckFormFields">
            <Form.Label>Slides Configuration: </Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter number of slides"
              name="numberOfSlides"
              onChange={(e) => {}}
            />
            <Form.Label>Slide Styles: </Form.Label>
            <Form.Group className="d-flex gap-2">
              <Form.Select
                defaultValue={""}
                onChange={(e) => {}}
                className="form-select"
                aria-label="Select Layout"
              >
                <option value={""}>Select Layout</option>
                <option value="title">Layout 1</option>
                <option value="date">Layout 2</option>
              </Form.Select>
              <Form.Select
                defaultValue={""}
                onChange={(e) => {}}
                className="form-select"
                aria-label="Select Theme"
              >
                <option value={""}>Select Theme</option>
                <option value="title">Theme 1</option>
                <option value="date">Theme 2</option>
              </Form.Select>
              <Form.Select
                defaultValue={""}
                onChange={(e) => {}}
                className="form-select"
                aria-label="Select Theme"
              >
                <option value={""}>Slide Animation</option>
                <option value="title">Animation 1</option>
                <option value="date">Animation 2</option>
              </Form.Select>
            </Form.Group>
            <Form.Label>Text Styles: </Form.Label>
            <Form.Group className="text-styles">
              <Form.Select
                defaultValue={""}
                onChange={(e) => {}}
                className="form-select"
                aria-label="Select Theme"
              >
                <option value={""}>Font Size</option>
                {Array.from({ length: 72 - 12 + 1 }, (_, i) => 12 + i).map(
                  (size, idx) => (
                    <option value={size} key={idx}>{`${size} px`}</option>
                  )
                )}
              </Form.Select>
              <Form.Select
                defaultValue={""}
                onChange={(e) => {}}
                className="form-select"
                aria-label="Select Theme"
              >
                <option value={""}>Font Style</option>
                <option value="title">Theme 1</option>
                <option value="date">Theme 2</option>
              </Form.Select>
              <Form.Select
                defaultValue={""}
                onChange={(e) => {}}
                className="form-select"
                aria-label="Select Theme"
              >
                <option value={""}>Font Family</option>
                <option value="title">Theme 1</option>
                <option value="date">Theme 2</option>
              </Form.Select>
            </Form.Group>
            <Form.Label>Add Images: </Form.Label>
            <div className="dotted-border rounded-pill d-flex align-items-center">
              Drop here to upload
            </div>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <button onClick={() => setShow(false)} className="btn-secondary">
            Close
          </button>
          <button onClick={() => setShow(false)} className="btn-primary">
            Create Pitch Deck
          </button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default PitchDeckBuilder;
