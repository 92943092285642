import React, { useState } from "react";
import { Col, Form, Modal, Row, Tab, Tabs } from "react-bootstrap";
import { GrSettingsOption } from "react-icons/gr";
import { useSelector } from "react-redux";
import downloadDoc from "../../img/downloadDoc.svg";
import generateTooltip from "../../img/generateTooltip.svg";
import genratebtn from "../../img/generatebtn.svg";
import shareImg from "../../img/shareimg.svg";
import {
  useGetAccountPricingQuery,
  useGetEvidenceDataQuery,
  useGetUserTemplatesByDealIdQuery,
} from "../../redux-setup/apiSlice";
import { DynamicTooltipbox } from "../Resetpasswordpage/Tooltipbox";
import BusinessCase from "./BusinessCase";
import ChartGraphTab from "./ChartGraphTab";
import Evidence from "./Evidence";
import ProductPricingtab from "./ProductPricingtab";
import BusinesCasePDF from "./BusinesCasePDF";
import { useDispatch } from "react-redux";
import { setBCBActiveTab } from "../../redux-setup/dataSlice";
import { usePDF } from "react-to-pdf";

const SetupModal = (props) => {
  return (
    <Modal
      className="frameworkModal share"
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">Setup</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className="">
          <Row>
            {/* == */}
            <Form>
              <Col md={12}>
                <Row>
                  {/* ===== */}
                  <Col md={12} className="mb-3">
                    <Form.Label>Purpose</Form.Label>
                    <Form.Select aria-label="Default select example w-100">
                      <option>Evaluation Overview</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </Form.Select>
                  </Col>

                  <Col md={12}>
                    <Form.Group className="mb-3">
                      <Form.Label className="d-flex gap-2 align-items-center ">
                        Context
                        {/* <img src={toolTip} alt="toolTip" /> */}
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        placeholder="Type Here"
                      />
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        Requirement
                        {/* <img src={toolTip} alt="toolTip" /> */}
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        placeholder="Type Here"
                      />
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        Focus Point
                        {/* <img src={toolTip} alt="toolTip" /> */}
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        placeholder="Type Here"
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
            </Form>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="reset"
          onClick={() => {
            props.onHide();
          }}
          className="cancelBtn"
        >
          Close
        </button>
        <button type="submit" className="doneBtn">
          Done
        </button>
      </Modal.Footer>
    </Modal>
  );
};

const BusinessCaseBuilderTab = ({ deal }) => {
  const user = useSelector((state) => state.dataSlice.user);
  const dispatch = useDispatch();
  const [selectedFrameWork, setSelectedFrameWork] = useState("");
  const { data: templates = {} } = useGetUserTemplatesByDealIdQuery(
    {
      userID: user?.id,
      dealID: deal?.id,
    },
    { skip: !user?.id || !deal?.id }
  );
  const { data: pricingData } = useGetAccountPricingQuery(deal?.id, {
    skip: !deal?.id,
  });
  const { data: evData } = useGetEvidenceDataQuery(deal?.id, {
    skip: !selectedFrameWork || !deal?.id,
  });
  const [activeTab, setActiveTab] = useState("Evidence"); // Track the active tab
  const handleTabSelect = (selectedTab) => {
    setActiveTab(selectedTab);
    dispatch(setBCBActiveTab(selectedTab));
    setShowBcbPDF(false);
  };
  const [setupModal, setSetupModal] = React.useState(false);
  const handleSetup = () => {
    setSetupModal(!setupModal);
  };

  const [showBcbPDF, setShowBcbPDF] = useState(false);
  const [pdfCreatedAt, setPDFCreatedAt] = useState(null);

  const handlePDFGeneration = () => {
    if (showBcbPDF) {
      toPDF();
    } else {
      const currentDate = new Date();
      const formattedDate = `${("0" + (currentDate.getMonth() + 1)).slice(
        -2
      )}/${("0" + currentDate.getDate()).slice(
        -2
      )}/${currentDate.getFullYear()}`;

      setPDFCreatedAt(formattedDate);
      setShowBcbPDF(true);
    }
  };

  // //////// Setup for converting to PDF
  const { toPDF, targetRef } = usePDF({ filename: "page.pdf" });
  return (
    <>
      <div className="businessCaseBuilder">
        {activeTab !== "Business Case" ? ( // Conditionally render buttons
          <button className="generateBtn align-items-center ">
            <DynamicTooltipbox
              label={
                <>
                  <img src={genratebtn} alt={genratebtn} />
                  Generate Quick Business Case
                </>
              }
              infoCircle={generateTooltip}
              infoText={"Coming soon"}
            />
          </button>
        ) : (
          <>
            <div className="downloadShareBtn">
              {showBcbPDF && (
                <button
                  className="downloadDoc"
                  onClick={() => setShowBcbPDF(false)}
                >
                  Go Back
                </button>
              )}
              <button
                className={`${!selectedFrameWork ? "disabled" : "downloadDoc"}`}
                onClick={() => handlePDFGeneration()}
                disabled={!selectedFrameWork}
              >
                {/* <button className="downloadDoc" onClick={() => toPDF()}> */}
                {showBcbPDF ? "Download Doc" : "Generate PDF"}{" "}
                <img src={downloadDoc} alt={downloadDoc} />{" "}
                {/* <DynamicTooltipbox
                  infoText={"Coming soon"}
                  label={
                    <>
                      Download Doc <img src={downloadDoc} alt={downloadDoc} />{" "}
                    </>
                  }
                /> */}
              </button>
              <button
                className="share"
                // onClick={() => setModalShow(true)}
              >
                <DynamicTooltipbox
                  infoText={"Coming soon"}
                  label={
                    <>
                      Share <img src={shareImg} alt={shareImg} />
                    </>
                  }
                />
              </button>
            </div>
          </>
        )}

        <Tabs
          defaultActiveKey="Evidence"
          id="uncontrolled-tab-example"
          onSelect={handleTabSelect}
        >
          <Tab eventKey="Evidence" title="Evidence">
            <Evidence
              templates={templates}
              evData={evData}
              selectedFrameWork={selectedFrameWork}
              setSelectedFrameWork={setSelectedFrameWork}
            />
          </Tab>
          <Tab eventKey="Products & Pricing" title="Products & Pricing">
            <ProductPricingtab deal={deal} data={pricingData} />
          </Tab>
          <Tab eventKey="Charts & Graphs" title="Charts & Graphs">
            <ChartGraphTab data={pricingData} />
          </Tab>
          <Tab eventKey="Business Case" title="Business Case">
            {showBcbPDF ? (
              <BusinesCasePDF
                createdAt={pdfCreatedAt}
                PDF
                deal={deal}
                targetRef={targetRef}
                user={user}
                templates={templates}
                evData={evData}
                selectedFrameWork={selectedFrameWork}
                setSelectedFrameWork={setSelectedFrameWork}
              />
            ) : (
              <BusinessCase
                templates={templates}
                evData={evData}
                selectedFrameWork={selectedFrameWork}
                setSelectedFrameWork={setSelectedFrameWork}
              />
            )}
          </Tab>
        </Tabs>
      </div>

      <SetupModal show={setupModal} onHide={() => setSetupModal(false)} />
    </>
  );
};

export default BusinessCaseBuilderTab;
