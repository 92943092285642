import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useSearchParams } from "react-router-dom";
import profileIcon from "../../img/profile-icon.jpeg";
import { setUser } from "../../redux-setup/dataSlice";

const SideNav = ({ isSideNavCollapsed, setIsSideNavCollapsed }) => {
  const user = useSelector((state) => state.dataSlice.user);
  const [searchParams] = useSearchParams();
  const view = searchParams.get("view") || "dashboard";
  const [currentTab, setCurrentTab] = useState(1);

  const handleItemClick = (id) => {
    setCurrentTab(id);
  };

  const dispatch = useDispatch();

  const logout = () => {
    localStorage.clear();
    dispatch(setUser({}));
    window.location.href = "/login";
  };

  useEffect(() => {
    switch (view) {
      case "dashboard":
        setCurrentTab(1);
        break;
      case "frameworks":
        setCurrentTab(4);
        break;

      default:
        break;
    }
  }, [view]);
  return (
    <div className={`mainSideNav ${isSideNavCollapsed ? "collapsed" : ""}`}>
      <div className="card">
        <ul className="navItems">
          <li
            onClick={() => handleItemClick(1)}
            className={currentTab === 1 ? "active-tab" : ""}
          >
            <NavLink to="?view=dashboard">
              <svg
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_4078_6987)">
                  <path
                    d="M11.6667 3.83337H16.6667V8.83337H11.6667V3.83337Z"
                    stroke={currentTab === 1 ? "#ffffff" : "#475569"}
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M3.33331 12.1666H8.33331V17.1666H3.33331V12.1666Z"
                    stroke={currentTab === 1 ? "#ffffff" : "#475569"}
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M11.6667 14.6666C11.6667 15.3297 11.9301 15.9656 12.3989 16.4344C12.8678 16.9032 13.5036 17.1666 14.1667 17.1666C14.8297 17.1666 15.4656 16.9032 15.9345 16.4344C16.4033 15.9656 16.6667 15.3297 16.6667 14.6666C16.6667 14.0036 16.4033 13.3677 15.9345 12.8989C15.4656 12.43 14.8297 12.1666 14.1667 12.1666C13.5036 12.1666 12.8678 12.43 12.3989 12.8989C11.9301 13.3677 11.6667 14.0036 11.6667 14.6666Z"
                    stroke={currentTab === 1 ? "#ffffff" : "#475569"}
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M3.33331 6.33337C3.33331 6.66168 3.39798 6.98677 3.52361 7.29008C3.64925 7.5934 3.8334 7.86899 4.06555 8.10114C4.29769 8.33329 4.57329 8.51744 4.8766 8.64307C5.17992 8.76871 5.50501 8.83337 5.83331 8.83337C6.16162 8.83337 6.48671 8.76871 6.79002 8.64307C7.09334 8.51744 7.36893 8.33329 7.60108 8.10114C7.83323 7.86899 8.01738 7.5934 8.14301 7.29008C8.26865 6.98677 8.33331 6.66168 8.33331 6.33337C8.33331 6.00507 8.26865 5.67998 8.14301 5.37667C8.01738 5.07335 7.83323 4.79775 7.60108 4.56561C7.36893 4.33346 7.09334 4.14931 6.79002 4.02367C6.48671 3.89804 6.16162 3.83337 5.83331 3.83337C5.50501 3.83337 5.17992 3.89804 4.8766 4.02367C4.57329 4.14931 4.29769 4.33346 4.06555 4.56561C3.8334 4.79775 3.64925 5.07335 3.52361 5.37667C3.39798 5.67998 3.33331 6.00507 3.33331 6.33337Z"
                    stroke={currentTab === 1 ? "#ffffff" : "#475569"}
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_4078_6987">
                    <rect
                      width="20"
                      height="20"
                      fill={currentTab === 1 ? "#ffffff" : "#475569"}
                      transform="translate(0 0.5)"
                    />
                  </clipPath>
                </defs>
              </svg>
              {/* <div style={{ color: "#fff !important" }}>
                <img src={sidenavDash} alt="" />
              </div> */}
              <p>Dashboard</p>
            </NavLink>
          </li>
          <li
            onClick={() => handleItemClick(2)}
            className={currentTab === 2 ? "active-tab" : ""}
          >
            <NavLink to="?view=accounts">
              <svg
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_4078_6995)">
                  <path
                    d="M2.5 18H17.5"
                    stroke={currentTab === 2 ? "#ffffff" : "#475569"}
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M2.5 8.83331H17.5"
                    stroke={currentTab === 2 ? "#ffffff" : "#475569"}
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M4.16669 5.5L10 3L15.8334 5.5"
                    stroke={currentTab === 2 ? "#ffffff" : "#475569"}
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M3.33331 8.83331V18"
                    stroke={currentTab === 2 ? "#ffffff" : "#475569"}
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M16.6667 8.83331V18"
                    stroke={currentTab === 2 ? "#ffffff" : "#475569"}
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M6.66669 12.1667V14.6667"
                    stroke={currentTab === 2 ? "#ffffff" : "#475569"}
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10 12.1667V14.6667"
                    stroke={currentTab === 2 ? "#ffffff" : "#475569"}
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M13.3333 12.1667V14.6667"
                    stroke={currentTab === 2 ? "#ffffff" : "#475569"}
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_4078_6995">
                    <rect
                      width="20"
                      height="20"
                      fill="white"
                      transform="translate(0 0.5)"
                    />
                  </clipPath>
                </defs>
              </svg>

              <p>Accounts</p>
            </NavLink>
          </li>
          <li
            onClick={() => handleItemClick(3)}
            className={currentTab === 3 ? "active-tab" : ""}
          >
            <NavLink to="?view=pitchdecks">
              <svg
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_4078_7007)">
                  <path
                    d="M7.5 10.5V7.16669"
                    stroke={currentTab === 3 ? "#ffffff" : "#475569"}
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M12.5 10.5V8.83331"
                    stroke={currentTab === 3 ? "#ffffff" : "#475569"}
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10 10.5V9.66669"
                    stroke={currentTab === 3 ? "#ffffff" : "#475569"}
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M2.5 3.83331H17.5"
                    stroke={currentTab === 3 ? "#ffffff" : "#475569"}
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M3.33331 3.83331V12.1666C3.33331 12.6087 3.50891 13.0326 3.82147 13.3452C4.13403 13.6577 4.55795 13.8333 4.99998 13.8333H15C15.442 13.8333 15.8659 13.6577 16.1785 13.3452C16.4911 13.0326 16.6666 12.6087 16.6666 12.1666V3.83331"
                    stroke={currentTab === 3 ? "#ffffff" : "#475569"}
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10 13.8333V17.1666"
                    stroke={currentTab === 3 ? "#ffffff" : "#475569"}
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M7.5 17.1667H12.5"
                    stroke={currentTab === 3 ? "#ffffff" : "#475569"}
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_4078_7007">
                    <rect
                      width="20"
                      height="20"
                      fill="white"
                      transform="translate(0 0.5)"
                    />
                  </clipPath>
                </defs>
              </svg>

              <p>Pitch Decks</p>
            </NavLink>
          </li>
          <li
            onClick={() => handleItemClick(4)}
            className={currentTab === 4 ? "active-tab" : ""}
          >
            <NavLink to="?view=frameworks">
              <svg
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_4078_7018)">
                  <path
                    d="M15.8334 7.39002C16.0867 7.5363 16.2971 7.74669 16.4434 8.00004C16.5897 8.2534 16.6667 8.5408 16.6667 8.83335V15.5C16.6667 15.942 16.4911 16.366 16.1785 16.6785C15.866 16.9911 15.442 17.1667 15 17.1667H8.33335C7.89133 17.1667 7.4674 16.9911 7.15484 16.6785C6.84228 16.366 6.66669 15.942 6.66669 15.5V8.83335C6.66669 8.39133 6.84228 7.9674 7.15484 7.65484C7.4674 7.34228 7.89133 7.16669 8.33335 7.16669H10.8334"
                    stroke={currentTab === 4 ? "#ffffff" : "#475569"}
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M4.16665 13.6116C3.91304 13.4652 3.70249 13.2546 3.5562 13.0009C3.4099 12.7472 3.33303 12.4595 3.33331 12.1666V5.49998C3.33331 5.05795 3.50891 4.63403 3.82147 4.32147C4.13403 4.00891 4.55795 3.83331 4.99998 3.83331H11.6666C12.1087 3.83331 12.5326 4.00891 12.8452 4.32147C13.1577 4.63403 13.3333 5.05795 13.3333 5.49998V12.1666C13.3333 12.6087 13.1577 13.0326 12.8452 13.3452C12.5326 13.6577 12.1087 13.8333 11.6666 13.8333H9.16665"
                    stroke={currentTab === 4 ? "#ffffff" : "#475569"}
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_4078_7018">
                    <rect
                      width="20"
                      height="20"
                      fill="white"
                      transform="translate(0 0.5)"
                    />
                  </clipPath>
                </defs>
              </svg>

              <p>Frameworks</p>
            </NavLink>
          </li>
        </ul>
      </div>
      <div className="card">
        <div className="userDetails">
          <img
            className="rounded-circle"
            style={{
              width: isSideNavCollapsed ? "34px" : "48px",
              height: isSideNavCollapsed ? "34px" : "48px",
            }}
            src={user?.profilePicture ? user.profilePicture : profileIcon}
            alt={user?.firstName}
          />
          <div className="userDetailsContent">
            <p className="name">
              {user?.firstName || user?.lastName
                ? user.firstName + " " + user.lastName
                : "N/A"}
              <br></br>{" "}
              <span className="role">
                {user?.firstName || user?.lastName
                  ? user.firstName + " " + user.lastName
                  : "N/A"}
              </span>
              {/* <br></br> <span>{user?.email || "N/A"}</span> */}
            </p>
          </div>
        </div>
        <ul className="navItems">
          <li
            onClick={() => handleItemClick(5)}
            className={currentTab === 5 ? "active-tab" : ""}
          >
            <NavLink to="/profilesetting">
              <svg
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_4078_6918)">
                  <path
                    d="M3.33331 8.83335C3.33331 9.27538 3.50891 9.6993 3.82147 10.0119C4.13403 10.3244 4.55795 10.5 4.99998 10.5C5.44201 10.5 5.86593 10.3244 6.17849 10.0119C6.49105 9.6993 6.66665 9.27538 6.66665 8.83335C6.66665 8.39133 6.49105 7.9674 6.17849 7.65484C5.86593 7.34228 5.44201 7.16669 4.99998 7.16669C4.55795 7.16669 4.13403 7.34228 3.82147 7.65484C3.50891 7.9674 3.33331 8.39133 3.33331 8.83335Z"
                    stroke={currentTab === 5 ? "#ffffff" : "#475569"}
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M5 3.83331V7.16665"
                    stroke={currentTab === 5 ? "#ffffff" : "#475569"}
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M5 10.5V17.1667"
                    stroke={currentTab === 5 ? "#ffffff" : "#475569"}
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M8.33331 13.8334C8.33331 14.2754 8.50891 14.6993 8.82147 15.0119C9.13403 15.3244 9.55795 15.5 9.99998 15.5C10.442 15.5 10.8659 15.3244 11.1785 15.0119C11.4911 14.6993 11.6666 14.2754 11.6666 13.8334C11.6666 13.3913 11.4911 12.9674 11.1785 12.6548C10.8659 12.3423 10.442 12.1667 9.99998 12.1667C9.55795 12.1667 9.13403 12.3423 8.82147 12.6548C8.50891 12.9674 8.33331 13.3913 8.33331 13.8334Z"
                    stroke={currentTab === 5 ? "#ffffff" : "#475569"}
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10 3.83331V12.1666"
                    stroke={currentTab === 5 ? "#ffffff" : "#475569"}
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10 15.5V17.1667"
                    stroke={currentTab === 5 ? "#ffffff" : "#475569"}
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M13.3333 6.33335C13.3333 6.77538 13.5089 7.1993 13.8215 7.51186C14.134 7.82442 14.558 8.00002 15 8.00002C15.442 8.00002 15.8659 7.82442 16.1785 7.51186C16.4911 7.1993 16.6666 6.77538 16.6666 6.33335C16.6666 5.89133 16.4911 5.4674 16.1785 5.15484C15.8659 4.84228 15.442 4.66669 15 4.66669C14.558 4.66669 14.134 4.84228 13.8215 5.15484C13.5089 5.4674 13.3333 5.89133 13.3333 6.33335Z"
                    stroke={currentTab === 5 ? "#ffffff" : "#475569"}
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M15 3.83331V4.66665"
                    stroke={currentTab === 5 ? "#ffffff" : "#475569"}
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M15 8V17.1667"
                    stroke={currentTab === 5 ? "#ffffff" : "#475569"}
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_4078_6918">
                    <rect
                      width="20"
                      height="20"
                      fill="white"
                      transform="translate(0 0.5)"
                    />
                  </clipPath>
                </defs>
              </svg>

              <p>Settings</p>
            </NavLink>
          </li>
          <li onClick={logout} className={currentTab === 6 ? "active-tab" : ""}>
            <NavLink to="#">
              <svg
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_4078_7645)">
                  <path
                    d="M11.6667 7.16665V5.49998C11.6667 5.05795 11.4911 4.63403 11.1785 4.32147C10.866 4.00891 10.442 3.83331 10 3.83331H4.16667C3.72464 3.83331 3.30072 4.00891 2.98816 4.32147C2.67559 4.63403 2.5 5.05795 2.5 5.49998V15.5C2.5 15.942 2.67559 16.3659 2.98816 16.6785C3.30072 16.991 3.72464 17.1666 4.16667 17.1666H10C10.442 17.1666 10.866 16.991 11.1785 16.6785C11.4911 16.3659 11.6667 15.942 11.6667 15.5V13.8333"
                    stroke={currentTab === 6 ? "#ffffff" : "#475569"}
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M7.5 10.5H17.5L15 8"
                    stroke={currentTab === 6 ? "#ffffff" : "#475569"}
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M15 13L17.5 10.5"
                    stroke={currentTab === 6 ? "#ffffff" : "#475569"}
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_4078_7645">
                    <rect
                      width="20"
                      height="20"
                      fill="white"
                      transform="translate(0 0.5)"
                    />
                  </clipPath>
                </defs>
              </svg>

              <p>Logout</p>
            </NavLink>
          </li>
        </ul>
      </div>
      <button className="card">
        <p
          style={{ fontSize: "12px" }}
          onClick={() => setIsSideNavCollapsed((prev) => !prev)}
        >
          {isSideNavCollapsed ? "Expand" : "Collapse"}
        </p>
      </button>
    </div>
  );
};

export default SideNav;
