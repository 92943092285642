import React from "react";
import { Dropdown } from "react-bootstrap";
import { RxCross2 } from "react-icons/rx";

const MultiSelect = ({ list = [], onChange = () => {}, value = [] }) => {
  const handleChange = (e) => {
    if (value.includes(e)) {
      let filtered = value.filter((it) => it !== e);
      onChange(filtered);
    } else {
      onChange([...value, e]);
    }
  };
  const handleRemove = (item) => {
    let filtered = value.filter((it) => it !== item);
    onChange(filtered);
  };
  return (
    <div
      style={{
        border: "1px solid #cbd5e1",
        background: "#f8fafc",
        borderRadius: "20px",
        fontSize: "13px",
        marginBottom: "15px",
      }}
      className="multiSelect w-100"
    >
      <Dropdown>
        <Dropdown.Toggle variant="transparent" id="dropdown-basic">
          {value.length
            ? value.map((it) => (
                <div
                  key={it}
                  className={`multiselect status-${it.replace(" ", "-")}`}
                >
                  {it}
                  {value.length > 1 && (
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        handleRemove(it);
                      }}
                      className="crossStatusBtn"
                    >
                      <RxCross2 />
                    </button>
                  )}
                </div>
              ))
            : "Select"}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {list.map((item) => (
            <Dropdown.Item onClick={() => handleChange(item)}>
              {item}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default MultiSelect;
