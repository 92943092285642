import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import DatePicker from "react-datepicker";
import { formatDate } from "../../utils/CommonFuntions";
export function MyDeletePopup(props) {
  const handleDone = () => {
    props.confirm();
  };
  const handleCancel = () => {
    props.onHide();
  };
  return (
    <Modal
      className="frameworkModal"
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Are you sure you want to delete this?
        </Modal.Title>
      </Modal.Header>
      <Form className="frameworkPopup">
        <Modal.Footer>
          <button type="button" className="cancelBtn" onClick={handleCancel}>
            Close
          </button>
          <button
            type="button"
            disabled={props.isLoading}
            className="doneBtn"
            onClick={handleDone}
          >
            {props.isLoading ? "Deleting" : "Delete"}
          </button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
export function Details(props) {
  const { data } = props;
  useEffect(() => {
    setUpdated({
      ...data,
      meetingStartDateTime: data.meetingStartDateTime
        ? new Date(data.meetingStartDateTime)
        : "",
    });
  }, [data]);
  const [updated, setUpdated] = useState(data);
  return (
    <Modal
      className="frameworkModal"
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {data.type === "editDate" ? "Update Date" : data.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {data.type === "editDate" ? (
          <Col sm={12} className="mt-2">
            <Form.Group className="meetingDatepicker ">
              <Form.Label>Update Date/Time</Form.Label>
              <DatePicker
                className="form-control w-100 "
                selected={updated.meetingStartDateTime || ""}
                onChange={(e) => {
                  setUpdated({ ...updated, meetingStartDateTime: e });
                }}
                showTimeSelect
                dateFormat="Pp"
                timeFormat="HH:mm"
              />
            </Form.Group>
          </Col>
        ) : (
          <div className="mt-3 text-center ">
            <div className="">{data.description}</div>
            <div className="mt-3">
              {formatDate(data.meetingStartDateTime, "lll")}
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        {data.type === "editDate" ? (
          <button onClick={props.onHide} type="button" className="doneBtn">
            <div>Update Date/Time</div>
          </button>
        ) : (
          <button type="button" className="doneBtn">
            <a
              style={{ all: "unset" }}
              href={data.meetingStartLink}
              target="_blank"
            >
              Join Meeting
            </a>
          </button>
        )}
      </Modal.Footer>
    </Modal>
  );
}
