import { useFormik } from "formik";
import React, { useState } from "react";
import { Col } from "react-bootstrap";
import { Icon } from "react-icons-kit";
import { eye } from "react-icons-kit/feather/eye";
import { eyeOff } from "react-icons-kit/feather/eyeOff";
import { passwordSchema } from "../../schemas";
import Introdetails from "../Resetpasswordpage/Introdetails";
import Questiondetails from "../Resetpasswordpage/Questiondetails";
import Passwordstrength from "./Passwordstrength";

const Setuppassword = ({ showComponent4 }) => {
  const initialValues = {
    password: "",
    confirmpassword: "",
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: passwordSchema,
      onSubmit: (values, action) => {
        showComponent4();
        action.resetForm();
      },
    });

  return (
    <Col lg={6}>
      <div className="loginFieldBox">
        <Introdetails
          heading="Setup your password"
          paragraph="Password must contain a Capital Letter, a Special Symbol & a Numeric Digit."
        />
        <form onSubmit={handleSubmit}>
          <div className="fieldsBox">
            <Passwordfields
              label="Password"
              names="password"
              value={values.password}
              handleChange={handleChange}
              handleBlur={handleBlur}
              error={errors.password}
              touched={touched.password}
            />
            <Passwordfields
              label="Confirm Password"
              names="confirmpassword"
              value={values.confirmpassword}
              handleChange={handleChange}
              handleBlur={handleBlur}
              error={errors.confirmpassword}
              touched={touched.confirmpassword}
            />
          </div>

          <Passwordstrength />
          <button className="loginButton" type="submit">
            Continue
          </button>
        </form>

        {/* <Logincommonbutton name="Continue"/> */}
        <Questiondetails
          text="Already have an account?"
          linkText="Login"
          link="/login"
        />
      </div>
    </Col>
  );
};

const Passwordfields = (props) => {
  const { names, value, handleBlur, handleChange, error, touched } = props;

  const { label } = props;
  // const [password, setPassword] = useState("");
  const [type, setType] = useState("password");
  const [icon, setIcon] = useState(eyeOff);

  const handleToggle = () => {
    if (type === "password") {
      setIcon(eye);
      setType("text");
    } else {
      setIcon(eyeOff);
      setType("password");
    }
  };

  return (
    <div>
      <label>{label}</label>
      <div className="passwordField">
        <input
          type={type}
          // name="password"
          placeholder="Password"
          name={names}
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
          autoComplete="current-password"
        />
        <span onClick={handleToggle}>
          <Icon className="absolute mr-10" icon={icon} size={25} />
        </span>
        {error && touched ? <p className="form-error">*{error}</p> : null}
      </div>
    </div>
  );
};

export default Setuppassword;
