import React from "react";

const Otpentercode = (props) => {
  const { heading, subpara, imgSrc, paragraph, infPara, editimage } = props;
  return (
    <>
      <h3>{heading}</h3>
      <p>{subpara}</p>
      <img src={imgSrc} alt="enterCode" />
      <p>{paragraph}</p>
      <p className="infPara">
        {infPara} {editimage && <img src={editimage} alt="editImage" />}
      </p>
    </>
  );
};

export default Otpentercode;
