import { useEffect, useRef, useState } from "react";
import { getRandomColor } from "../../utils/CommonFuntions";

const BusinesCasePDF = ({
  targetRef,
  deal,
  user,
  templates = {},
  evData = {},
  createdAt = "N/A",
  selectedFrameWork,
  setSelectedFrameWork,
}) => {
  const { frameWork = [] } = templates;
  const [selectedFrameWorkData, setSelectedFrameWorkData] = useState({});
  const [evidenceData, setEvidenceData] = useState(evData || {});
  useEffect(() => {
    if (selectedFrameWork) {
      setEvidenceData({ ...evData });
      const tempFrameWork = frameWork.find(
        (fw) => parseInt(fw.id) === parseInt(selectedFrameWork)
      );
      if (tempFrameWork) setSelectedFrameWorkData({ ...tempFrameWork });
    } else {
      setEvidenceData({});
      setSelectedFrameWorkData({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [evData, selectedFrameWork]);
  const displayedItems = selectedFrameWorkData.headlines
    ? selectedFrameWorkData.headlines
        .map((it) => {
          const item = it.headline.title;
          const value = evidenceData?.userContent?.[item] || {
            data: [
              {
                content: [],
                title: "",
              },
            ],
            removedFromBusinessCase: false,
          };
          return { item, value };
        })
        .filter(({ value }) => !value.removedFromBusinessCase)
        .map(({ item, value }) => [item, value])
    : [];
  const backgroundColor = getRandomColor();
  const getPersonDetails = (mode = "full", item) => {
    const name = item?.title?.toString()?.split("-")?.[0] || "";

    // Get initals
    const tempInitials = name?.split(" ");
    let initials = "";
    if (tempInitials?.length) {
      const temp = tempInitials.map((word) => {
        let init = word?.slice(0, 1).toUpperCase();
        return init;
      });
      initials = temp.join("");
    }

    // Get designation
    const designation = item?.title?.toString()?.split("-")?.[1] || "";

    // Get evidence
    const evidence = item?.summary || "No evidence points added";
    // const evidence = item?.content?.[0]?.evidence || "";
    switch (mode) {
      case "initals":
        return initials;
      case "full":
        return name;
      case "designation":
        return designation;
      case "evidence":
        return evidence;
      default:
        break;
    }
  };

  const getData = (originalData) => {
    return Array.isArray(originalData) ? originalData : originalData?.data;
  };

  return (
    <div
      style={{
        height: "100%",
        maxHeight: "calc(100vh - 400px)",
      }}
      className="custom-scroll"
    >
      {/* PDF Page */}
      <div className="pdf-report" ref={targetRef}>
        {/* header section */}
        <section className="header">
          <div className="info">
            <p>Report Title:</p>
            <p>
              <strong>{deal?.dealName || ""}</strong>
            </p>
            <p>Create Date:</p>
            <p>
              <strong>{createdAt}</strong>
            </p>
            <p>Prepared by:</p>
            <p>
              <strong>
                {user?.firstName || user?.lastName
                  ? `${user?.firstName || ""} ${user?.lastName || ""}`.trim()
                  : "N/A"}
              </strong>
            </p>
          </div>
        </section>
        {displayedItems && displayedItems?.length
          ? displayedItems.map(([item, value], itemKey) => {
              const filteredData = getData(value)?.filter((valueItem) =>
                valueItem?.content?.every(
                  (contentItem) =>
                    contentItem.evidence && contentItem.usedInBusinessCase
                )
              );
              return filteredData && filteredData.length > 0 ? (
                <>
                  <section className="gap-6">
                    <h3>{value?.titleInBusinessCase ?? item ?? ""}</h3>
                    {item === "People Involved" ? (
                      (Array.isArray(value) ? value : value?.data) &&
                      (Array.isArray(value) ? value : value?.data).length > 0 &&
                      (Array.isArray(value) ? value : value?.data).map(
                        (valueItem, valueItemIndex) => (
                          <div
                            className="people-involved-rows"
                            key={valueItemIndex}
                          >
                            <div className="labels">
                              <div>
                                <div
                                  className="pfp"
                                  style={{
                                    backgroundColor: getRandomColor(),
                                    color: "#fff",
                                  }}
                                >
                                  {/* JB */}
                                  {getPersonDetails("initals", valueItem || "")}
                                </div>
                              </div>
                              <div className="name-role">
                                <p>
                                  {getPersonDetails("full", valueItem || "")}
                                </p>
                                <p className="role">
                                  {getPersonDetails(
                                    "designation",
                                    valueItem || ""
                                  )}
                                </p>
                              </div>
                            </div>
                            <div className="content">
                              <p>
                                {getPersonDetails("evidence", valueItem || "")}
                              </p>
                            </div>
                          </div>
                        )
                      )
                    ) : (
                      <ol>
                        {getData(value) &&
                          getData(value).length > 0 &&
                          getData(value)
                            .filter((valueItem) =>
                              valueItem?.content?.every(
                                (contentItem) =>
                                  contentItem.evidence &&
                                  contentItem.usedInBusinessCase
                              )
                            )
                            .map((valueItem, itemIndex) => (
                              <li key={itemIndex}>
                                <h4>{valueItem?.title ?? ""}</h4>
                                <p>
                                  {valueItem?.content?.some(
                                    (contentItem) =>
                                      contentItem.usedInBusinessCase
                                  )
                                    ? valueItem.summary
                                    : "No evidence points added"}
                                </p>
                              </li>
                            ))}
                      </ol>
                    )}
                  </section>
                </>
              ) : null;
            })
          : null}
        {/* executive summary */}
      </div>
    </div>
  );
};

export default BusinesCasePDF;
