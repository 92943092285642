import { useState } from "react";

export const usePagination = (
  totalEntries,
  entriesPerPage = 10,
  maxPagesToShow = 5
) => {
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(totalEntries / entriesPerPage);

  const getPages = () => {
    let startPage, endPage;
    if (totalPages <= maxPagesToShow) {
      // less than maxPagesToShow total pages so show all pages
      startPage = 1;
      endPage = totalPages;
    } else {
      // more than maxPagesToShow total pages so calculate start and end pages
      if (currentPage <= Math.floor(maxPagesToShow / 2)) {
        startPage = 1;
        endPage = maxPagesToShow;
      } else if (currentPage + Math.floor(maxPagesToShow / 2) >= totalPages) {
        startPage = totalPages - maxPagesToShow + 1;
        endPage = totalPages;
      } else {
        startPage = currentPage - Math.floor(maxPagesToShow / 2);
        endPage = currentPage + Math.floor(maxPagesToShow / 2);
      }
    }

    return Array.from(Array(endPage + 1 - startPage).keys()).map(
      (i) => startPage + i
    );
  };

  const goToNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const goToPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const goToFirstPage = () => {
    setCurrentPage(1);
  };
  const goToLastPage = () => {
    setCurrentPage(totalPages);
  };

  return {
    currentPage,
    totalPages,
    pages: getPages(),
    goToNextPage,
    goToPreviousPage,
    goToFirstPage,
    goToLastPage,
    setPage: setCurrentPage,
  };
};
