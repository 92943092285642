import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useGoogleVerifyMutation } from "../redux-setup/apiSlice";
import { Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import googleMeetimg from "../img/googlemeetimg.png";
const GoogleAuth = () => {
  const [searchParams] = useSearchParams();
  const user = useSelector((state) => state.dataSlice.user);
  let verificationCode = searchParams.get("code");
  const [googleAuth, { isLoading, isError }] = useGoogleVerifyMutation();
  const navigate = useNavigate();
  useEffect(() => {
    if (user?.id) {
      googleAuth({ code: verificationCode, type: "google", userId: user.id })
        .unwrap()
        .then(() => {
          if (!isError && !isLoading) {
            setTimeout(() => navigate("/frameworkHomepage"), 2000);
          }
        })
        .catch((err) => console.error(err));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);
  return (
    <div
      style={{
        height: "100dvh",
      }}
      className="d-flex flex-column justify-content-center align-items-center"
    >
      <div
        style={{
          background: "linear-gradient(264.55deg, #1c2c65 0%, #017cff 100%)",
        }}
        className="w-100 fixed-top  align-self-start  justify-content-evenly  d-flex align-items-center "
      >
        <img src={googleMeetimg} alt="googlemeetimg" />
      </div>
      {isLoading ? (
        <Spinner />
      ) : isError ? (
        <>Error Authenticating Google Please try again</>
      ) : (
        <>
          <h1>Google integration successfull</h1>
          <p>Redirecting you to homepage . . .</p>
        </>
      )}
      {!isLoading && (
        <button
          className="updateDealBtn mt-3 "
          onClick={() => navigate("/frameworkHomepage")}
        >
          Homepage
        </button>
      )}
    </div>
  );
};

export default GoogleAuth;
