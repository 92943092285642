import React from "react";
import { NavLink } from "react-router-dom";

const Questiondetails = (props) => {
  const { text, linkText, link } = props;
  return (
    <p className="registerPara">
      {text} <NavLink to={link}>{linkText}</NavLink>
    </p>
  );
};

export default Questiondetails;
