import React, { useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { useGetUserQuery } from "../redux-setup/apiSlice";
import { setUser } from "../redux-setup/dataSlice";
import { SECURED_USER_COPITCH } from "../utils/contants";
import { decodeJWTToken } from "../utils/jwt-token";
import { getAuthToken, removeItem } from "../utils/localstorage-config";
const RequireAuth = () => {
  const token = getAuthToken();
  const jwtData = decodeJWTToken(token);
  const navigate = useNavigate();
  const user = useSelector((state) => state.dataSlice.user);
  const { data: userData, isLoading } = useGetUserQuery(jwtData?.userId, {
    skip: !jwtData?.userId || user?.id,
  });
  const dispatch = useDispatch();
  useEffect(() => {
    if (token && userData?.id) {
      dispatch(setUser(userData));
    } else if (!jwtData) {
      removeItem(SECURED_USER_COPITCH);
      navigate("/login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);
  return (
    <React.Fragment>
      {isLoading ? (
        <div
          style={{
            width: "100vw",
            height: "100vh",
          }}
          className="d-flex justify-content-center align-items-center "
        >
          <Spinner
            style={{
              width: 50,
              height: 50,
            }}
          />
        </div>
      ) : user?.id || userData?.id ? (
        <Outlet />
      ) : (
        <Navigate to="/home" replace />
      )}
    </React.Fragment>
  );
};

export default RequireAuth;
