import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { PROXY } from "../utils/contants";

export const authSlice = createApi({
  reducerPath: "authSlice",
  baseQuery: fetchBaseQuery({
    baseUrl: PROXY,
  }),
  endpoints: (builder) => ({
    register: builder.mutation({
      query: (data) => ({
        url: `/auth/register`,
        method: "POST",
        body: data,
        formData: true,
      }),
    }),
    login: builder.mutation({
      query: (data) => ({ url: "/auth/login", method: "POST", body: data }),
    }),
  }),
});
export const { useRegisterMutation, useLoginMutation } = authSlice;
