import React from "react";
import informationCircle from "../../img/information-circle.png";

const Tooltipbox = () => {
  return (
    <p className="infPara">
      How it works?
      <img src={informationCircle} alt="informationCircle" />
      <div className="infoText">
        coPitch.ai helps your team transcribe, summarize, search, and analyze
      </div>
    </p>
  );
};

export default Tooltipbox;

export const DynamicTooltipbox = ({
  label,
  infoCircle = false,
  infoText = "",
  style = {},
}) => {
  return (
    <span
      style={{ whiteSpace: "nowrap", ...style }}
      className="tooltipTitle d-flex gap-2 align-items-center position-relative "
    >
      {label}
      <span className="infoText">{infoText}</span>
      {infoCircle && <img src={informationCircle} alt="informationCircle" />}
    </span>
  );
};
