import { configureStore } from "@reduxjs/toolkit";
import { apiSlice } from "../redux-setup/apiSlice";
import { authSlice } from "../redux-setup/authSlice";
import dataSlice from "../redux-setup/dataSlice";
const store = configureStore({
  reducer: {
    dataSlice,
    [authSlice.reducerPath]: authSlice.reducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false })
      .concat(authSlice.middleware)
      .concat(apiSlice.middleware),
});
export default store;
