import React from "react";
import { NavLink } from "react-router-dom";

import { Form } from "react-bootstrap";

const Rememberforgotbox = () => {
  return (
    <div className="rememberForgotBox">
      <Form.Check type="checkbox" id="custom-checkbox" label="Remember me" />
      <NavLink to="/resetpassword">Forgot your password?</NavLink>
    </div>
  );
};

export default Rememberforgotbox;
