import React, { useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { GoPencil } from "react-icons/go";
import { IoIosAddCircleOutline } from "react-icons/io";
import { PiDotsThreeOutlineVerticalThin } from "react-icons/pi";
import { toast } from "react-toastify";
import {
  useCreateAccountPricingMutation,
  useUpdateAccountPricingMutation,
} from "../../redux-setup/apiSlice";

const ProductPricingtab = ({ data = {}, deal = {} }) => {
  const [savePricing] = useCreateAccountPricingMutation();
  const [updatePricing] = useUpdateAccountPricingMutation();
  useEffect(() => {
    try {
      if (data?.productPrice[0]?.rowColumn?.colums?.length)
        setRowCol(data.productPrice[0].rowColumn);
    } catch (error) {
      console.error(error);
    }
  }, [data]);
  const saveData = () => {
    toast.promise(
      data?.productPrice?.length
        ? updatePricing({
            id: data?.productPrice[0].id,
            data: { rowColumn: rowCol },
          })
            .unwrap()
            .then(() => {
              editColumnValues(false);
            })
        : savePricing({
            // accountId: account.id,
            dealId: deal?.id,
            rowColumn: rowCol,
          }),
      {
        pending: data?.productPrice?.length
          ? "Updating Pricing Table"
          : "Creating Pricing Table",
        success: `Pricing Table ${
          data?.productPrice?.length ? "Updated" : "Created"
        } Successfully👌`,
        error: `Cannot ${
          data?.productPrice?.length ? "Update" : "Create"
        } the table`,
      }
    );
  };
  const [rowCol, setRowCol] = useState({
    colums: ["Type", "Accrues", "Unit Price", "QTY", "Total", "Discount"],
    rows: [
      {
        Type: "copitch",
        Accrues: "annual",
        "Unit Price": 1,
        QTY: 1,
        Total: 0,
        Discount: "0%",
      },
    ],
  });
  let keys = rowCol?.colums || [];
  // (Array.isArray(rowCol) && rowCol?.length && Object.keys(rowCol[0])) || [];
  const [editableColumns, setEditableColumns] = useState(
    false
    // Array(keys.length).fill(false)
  );
  const [editableHeaders, setEditableHeaders] = useState(
    Array(keys.length).fill(false)
  );
  const [headerEditState, setHeaderEditState] = useState({
    isEditing: false,
    editedHeader: "",
  });

  const addRow = () => {
    if (keys.length) {
      let row = {};
      keys.forEach((it) => (row[it] = "-"));
      setRowCol({ ...rowCol, rows: [...rowCol.rows, row] });
    } else {
      toast.error("Add a column first");
    }
  };

  const addColumn = () => {
    if (rowCol?.rows?.length) {
      if ("-" in rowCol.colums) {
        toast.error(
          "An empty column name already exists Please update that and then add a new column."
        );
        return;
      }

      setRowCol({ ...rowCol, colums: [...rowCol.colums, "-"] });
    } else {
      setRowCol({ colums: ["-"], rows: [] });
    }
  };

  const editColumnValues = (colIndex) => {
    setEditableColumns(colIndex);
    // setEditableColumns((prevState) => {
    //   const newEditableColumns = [...prevState];
    //   newEditableColumns[colIndex] = !newEditableColumns[colIndex];
    //   return newEditableColumns;
    // });
  };

  const editHeaderValues = (colIndex) => {
    setHeaderEditState({
      isEditing: true,
      editedHeader: keys[colIndex],
    });
    setEditableHeaders((prevState) => {
      const newEditableHeaders = [...prevState];
      newEditableHeaders[colIndex] = true;
      return newEditableHeaders;
    });
  };
  const saveHeaderEdit = (colIndex) => {
    setHeaderEditState({
      isEditing: false,
      editedHeader: "",
    });
    let temp = structuredClone(rowCol);
    temp.rows?.map((it) => {
      // using for loop to prevent order changes
      for (let key in it) {
        if (key === keys[colIndex]) {
          it[headerEditState.editedHeader] = it[keys[colIndex]];
          delete it[key];
          break;
        }
      }
      return it;
    });
    temp.colums[colIndex] = headerEditState.editedHeader;
    setRowCol(temp);
    setEditableHeaders((prevState) => {
      const newEditableHeaders = [...prevState];
      newEditableHeaders[colIndex] = false;
      return newEditableHeaders;
    });
  };

  const cancelHeaderEdit = (colIndex) => {
    setHeaderEditState({
      isEditing: false,
      editedHeader: "",
    });

    setEditableHeaders((prevState) => {
      const newEditableHeaders = [...prevState];
      newEditableHeaders[colIndex] = false;
      return newEditableHeaders;
    });
  };

  const handleHeaderEdit = (colIndex, event) => {
    setHeaderEditState({
      ...headerEditState,
      editedHeader: event.target.value,
    });
  };

  const handleCellEdit = (rowIndex, colIndex, event) => {
    if (editableColumns) {
      let newTableData = structuredClone(rowCol.rows);
      newTableData[rowIndex][keys[colIndex]] = event.target.value;
      setRowCol({ ...rowCol, rows: newTableData });
    }
  };

  const deleteColumn = (colIndex) => {
    let copy = structuredClone(rowCol);
    let temp = copy.rows?.map((it) => {
      delete it[keys[colIndex]];
      return it;
    });
    copy.colums.splice(colIndex, 1);
    setRowCol({ ...copy, rows: temp });
  };
  const deleteRow = (ind) => {
    let temp = [...rowCol.rows];
    temp.splice(ind, 1);
    setRowCol({ ...rowCol, rows: temp });
  };
  const handleDisable = () => {
    try {
      return (
        JSON.stringify(rowCol) !==
        JSON.stringify(data?.productPrice[0]?.rowColumn)
      );
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="productAndPricing">
      <span className="d-flex justify-content-between  align-align-items-center ">
        <h5>Costs</h5>
        <span className="mb-2">
          {!editableColumns && (
            <span
              className="mx-4"
              style={{
                cursor: "pointer",
              }}
              onClick={() => editColumnValues(true)}
            >
              <GoPencil />
            </span>
          )}
          {(editableColumns || handleDisable()) && (
            <span className="profileBtn mb-2">
              <button
                onClick={() => {
                  editableColumns && editColumnValues(false);
                  data?.productPrice[0]?.rowColumn &&
                    setRowCol(data?.productPrice[0]?.rowColumn);
                }}
                className="cancel-button"
              >
                cancel
              </button>
              <button
                onClick={saveData}
                className="save-button ms-2 disabled:opacity-50 "
              >
                Save
              </button>
            </span>
          )}
        </span>
      </span>
      <div style={{ overflowX: "auto" }}>
        <table>
          <thead>
            <tr>
              {keys.map((header, colIndex) => (
                <th key={colIndex}>
                  {editableHeaders[colIndex] ? (
                    <div>
                      <input
                        type="text"
                        value={headerEditState.editedHeader}
                        onChange={(event) => handleHeaderEdit(colIndex, event)}
                      />
                      <div className="mt-2">
                        <button
                          className="save-button me-2"
                          onClick={() => saveHeaderEdit(colIndex)}
                        >
                          Save
                        </button>
                        <button
                          className="save-button"
                          onClick={() => cancelHeaderEdit(colIndex)}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  ) : (
                    <>
                      {header}
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="transparent"
                          id="dropdown-basic"
                        >
                          <PiDotsThreeOutlineVerticalThin />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item>
                            <button
                              className="editDeletebtn"
                              onClick={() => editHeaderValues(colIndex)}
                            >
                              <GoPencil /> Edit Header Name
                            </button>
                          </Dropdown.Item>
                          <Dropdown.Item>
                            {/* <button
                              className="editDeletebtn"
                              onClick={() => editColumnValues(colIndex)}
                            >
                              <GoPencil /> Edit Column
                            </button> */}
                          </Dropdown.Item>
                          <Dropdown.Item>
                            <button
                              className="editDeletebtn"
                              onClick={() => deleteColumn(colIndex)}
                            >
                              <i className="fa-solid fa-trash-can"></i> Delete
                              Column
                            </button>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </>
                  )}
                </th>
              ))}
              <th style={{ minInlineSize: "15px" }}></th>
            </tr>
          </thead>
          <tbody>
            {rowCol.rows?.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {keys.map((cell, colIndex) => (
                  <td key={colIndex}>
                    {editableColumns && cell?.toLowerCase() !== "total" ? (
                      <>
                        <input
                          type={
                            ["Unit Price", "Quantity", "QTY"].includes(cell)
                              ? "number"
                              : "text"
                          }
                          value={row[cell]}
                          onChange={(event) =>
                            handleCellEdit(rowIndex, colIndex, event)
                          }
                        />
                        {/* <button
                                onClick={() => editColumnValues(colIndex)}
                                className="editDeletebtn cancel-button"
                              >
                                cancel
                              </button> */}
                        {/* {rowIndex == rowCol?.rows?.length - 1 && (
                            <div className="d-flex ">
                              <button
                                className="editDeletebtn save-button"
                                onClick={() => editColumnValues(colIndex)}
                              >
                                Save
                              </button>
                            </div>
                          )} */}
                      </>
                    ) : cell?.toLowerCase() === "total" ? (
                      row[keys[colIndex - 1]] * row[keys[colIndex - 2]] || 0
                    ) : (
                      row[cell]
                    )}
                  </td>
                ))}
                <td style={{ minInlineSize: "30px" }}>
                  <div className="d-flex align-items-center gap-4">
                    {/* {editableColumns[rowIndex] ? (
                      <button
                        className="editDeletebtn save-button"
                        onClick={() => editColumnValues(rowIndex)}
                      >
                        Save
                      </button>
                    ) : (
                      <span
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={() => editColumnValues(rowIndex)}
                      >
                        <GoPencil />
                      </span>
                    )} */}

                    <span
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => deleteRow(rowIndex)}
                    >
                      <i className="fa-solid fa-trash-can"></i>
                    </span>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="chartBtn">
          <button onClick={addRow}>
            Add Row <IoIosAddCircleOutline />
          </button>
          <button onClick={addColumn}>
            Add Column <IoIosAddCircleOutline />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProductPricingtab;
