import React from "react";
import { Container, Row } from "react-bootstrap";
import Commonbluesection from "../Loginpage/Commonbluesection";
import Resetpasswordfieldsection from "./Resetpasswordfieldsection";

const Resetpassword = () => {
  return (
    <section className="loginpagebox">
      <Container fluid>
        <Row>
          <Commonbluesection />
          <Resetpasswordfieldsection />
        </Row>
      </Container>
    </section>
  );
};

export default Resetpassword;
