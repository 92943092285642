import React, { Fragment, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
import accountBankImg from "../../img/accountBank.png";
import {
  useAccountDeleteMutation,
  useCreatAccountMutation,
  useUpdateAccountMutation,
} from "../../redux-setup/apiSlice";
import Accountcomponent from "./Accountcomponent";
import { FileUploader } from "./FileUploader";
export const PlaceholderCard = ({ count = 2 }) => {
  return (
    <>
      {Array.from({ length: count }).map((it, i) => (
        <Col key={i} md={2} className="placeholder-wave ">
          <div className="placeholder rounded-4 h-100 w-100 "></div>
        </Col>
      ))}
    </>
  );
};

const AccountModalBox = (props) => {
  const [file, setFile] = useState(null);
  const [name, setName] = useState("");
  const [domain, setDomain] = useState("");
  const user = useSelector((state) => state.dataSlice.user);
  const handleFile = (file) => {
    setFile(file);
  };
  useEffect(() => {
    if (props.data?.id) {
      setName(props.data.accountName);
      setDomain(props?.data?.domain || "");
      setFile(props.data.attachment);
    } else {
      setFile(null);
      setName("");
      setDomain("");
    }
  }, [props.data]);
  const [createAccount, { isLoading }] = useCreatAccountMutation();
  const [updateAccount] = useUpdateAccountMutation();
  const handleSubmit = (event) => {
    event.preventDefault();

    if (name.length === 0) {
      alert("No Name");
      return;
    }
    const currentDate = new Date();

    let formData = new FormData();
    formData.append("accountName", name);
    formData.append("domain", domain);
    formData.append("attachment", file);
    if (props.data.id) {
      updateAccount({ id: props.data.id, data: formData, formData: true })
        .unwrap()
        .then(() => {
          setName("");
          setFile(null);
          props.fetchAccounts(user.id);
          props.onHide();
        })
        .catch((err) => console.error(err));
    } else {
      formData.append("APR", "");
      formData.append("lastMeeting", currentDate.toISOString());
      createAccount({ id: user.id, formData })
        .unwrap()
        .then(() => {
          setName("");
          setFile(null);
          props.fetchAccounts(user.id);
          props.onHide();
        })
        .catch((err) => console.error(err));
    }
  };

  return (
    <Modal
      className="frameworkModal"
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.data?.id ? "Update Account" : "Add a New Account"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className="frameworkPopup" onSubmit={(e) => handleSubmit(e)}>
          <Row>
            <Col sm={12}>
              <Form.Group controlId="exampleForm.ControlInput1">
                <Form.Label>Company Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Name Here"
                  required
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
              </Form.Group>
            </Col>
            <Col sm={12}>
              <Form.Group controlId="exampleForm.ControlInput1">
                <Form.Label>Domain</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Domain Here"
                  required
                  value={domain}
                  onChange={(e) => {
                    setDomain(e.target.value);
                  }}
                />
              </Form.Group>
            </Col>
            <Col sm={12}>
              <FileUploader handleFile={handleFile} />
              {file && file.name ? <p>Uploaded file: {file.name}</p> : null}
            </Col>
          </Row>

          <Modal.Footer>
            <button
              type="reset"
              onClick={() => {
                props.onHide();
                setName("");
                setFile(null);
              }}
              className="cancelBtn"
            >
              Close
            </button>
            <button type="submit" disabled={isLoading} className="doneBtn">
              {isLoading ? "Creating..." : "Done"}
            </button>
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

const Accountaccordian = ({
  accounts = [],
  fetchAccounts,
  //  deleteAccount,
  loading,
}) => {
  const [modalShow, setModalShow] = React.useState(false);
  const [selectedAccount, setSelectedAccount] = useState({});
  const [accountDelete] = useAccountDeleteMutation();
  const deleteAccount = (id) => {
    accountDelete(id)
      .unwrap()
      .then()
      .catch((err) => console.error(err));
  };
  return (
    <>
      <Accordion defaultActiveKey={["0"]} alwaysOpen>
        {/* ======1======= */}
        <div className="accordianBox">
          <div className="details">
            <div className="imgDetails">
              <img src={accountBankImg} alt="accountBankImg" />
              <h3>Accounts</h3>
            </div>
            <button className="popupbtn" onClick={() => setModalShow(true)}>
              Add a New Account <span>+</span>
            </button>
          </div>

          {/* ======1======= */}
          <Accordion.Item eventKey="0">
            <Accordion.Header></Accordion.Header>
            <Accordion.Body>
              <Row>
                {loading ? (
                  <PlaceholderCard />
                ) : (
                  accounts.map((data, id) => {
                    return (
                      <Fragment key={id}>
                        <Accountcomponent
                          data={data}
                          setAccountEdit={(data) => {
                            setModalShow(true);
                            setSelectedAccount(data);
                          }}
                          deleteAccount={deleteAccount}
                        />
                      </Fragment>
                    );
                  })
                )}

                <Col md={2}>
                  <button
                    className="addAaccountBtn"
                    onClick={() => setModalShow(true)}
                  >
                    <span>+</span>
                    Add a New Account
                  </button>
                </Col>
              </Row>
            </Accordion.Body>
          </Accordion.Item>
        </div>
      </Accordion>

      <AccountModalBox
        show={modalShow}
        data={selectedAccount}
        fetchAccounts={fetchAccounts}
        onHide={() => {
          setModalShow(false);
          selectedAccount?.id && setSelectedAccount({});
        }}
      />
    </>
  );
};

export default Accountaccordian;
