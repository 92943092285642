import React, { useState } from "react";
import Entercode from "./Entercode";
import Passwordreset from "./Passwordreset";
import Resetpasswordbox from "./Resetpasswordbox";
import Setuppassword from "./Setuppassword";

const Resetpasswordfieldsection = () => {
  const [showComponent1, setShowComponent1] = useState(true);
  const [showComponent2, setShowComponent2] = useState(false);
  const [showComponent3, setShowComponent3] = useState(false);
  const [showComponent4, setShowComponent4] = useState(false);

  const handleShowComponent2 = () => {
    setShowComponent1(false);
    setShowComponent2(true);
  };
  const handleShowComponent3 = () => {
    setShowComponent2(false);
    setShowComponent3(true);
  };
  const handleShowComponent4 = () => {
    setShowComponent3(false);
    setShowComponent4(true);
  };

  return (
    <>
      {showComponent1 && (
        <Resetpasswordbox showComponent2={handleShowComponent2} />
      )}
      {showComponent2 && <Entercode showComponent3={handleShowComponent3} />}
      {showComponent3 && (
        <Setuppassword showComponent4={handleShowComponent4} />
      )}
      {showComponent4 && <Passwordreset />}
    </>
  );
};

export default Resetpasswordfieldsection;
