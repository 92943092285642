import React from "react";
import { Container } from "react-bootstrap";
import Automaticallybox from "./Automaticallybox";

const automaticallyRecorddata = [
  {
    id: "1",
    headingBlueText: "Automatically record and ",
    headingText: "transcribe meetings",
    pointDetails: [
      "Transcribe meetings across several video-conferencing apps, dialers, and audio files.",
      "Easily invite CoPitch.ai Notetaker to meetings on your calendar.",
      "Fireflies captures video + audio, and generates transcripts in minutes.",
      "Integrates with apps like Google Meet, Zoom, Teams Webex, Ringcentral, Aircall and other platforms.",
    ],
  },
  {
    id: "2",
    headingBlueText: "Find anything with AI-Powered ",
    headingText: "Search",
    pointDetails: [
      "Transcribe meetings across several video-conferencing apps, dialers, and audio files.",
      "Easily invite CoPitch.ai Notetaker to meetings on your calendar.",
      "Fireflies captures video + audio, and generates transcripts in minutes.",
      "Integrates with apps like Google Meet, Zoom, Teams Webex, Ringcentral, Aircall and other platforms.",
    ],
  },
  {
    id: "3",
    headingBlueText: "Analyze meetings using ",
    headingText: "conversation intelligence",
    pointDetails: [
      "Transcribe meetings across several video-conferencing apps, dialers, and audio files.",
      "Easily invite CoPitch.ai Notetaker to meetings on your calendar.",
      "Fireflies captures video + audio, and generates transcripts in minutes.",
      "Integrates with apps like Google Meet, Zoom, Teams Webex, Ringcentral, Aircall and other platforms.",
    ],
  },
  {
    id: "4",
    headingBlueText: "Find anything with AI-Powered ",
    headingText: "Search",
    pointDetails: [
      "Transcribe meetings across several video-conferencing apps, dialers, and audio files.",
      "Easily invite CoPitch.ai Notetaker to meetings on your calendar.",
      "Fireflies captures video + audio, and generates transcripts in minutes.",
      "Integrates with apps like Google Meet, Zoom, Teams Webex, Ringcentral, Aircall and other platforms.",
    ],
  },
];

const Automaticallyrecord = () => {
  return (
    <section className="automaticallyRecordSection">
      <Container fluid>
        {automaticallyRecorddata.map((data) => {
          return (
            <>
              <Automaticallybox
                data={data}
                className={data.id == 2 || data.id == 4 ? "marginLeft" : ""}
              />
            </>
          );
        })}
      </Container>
    </section>
  );
};

export default Automaticallyrecord;
