import React from "react";
import {
  Col,
  Row
} from "react-bootstrap";
import myimage from "../../img/automate-img.png";
import Button from "./Custombutton";

const Bannersection = () => {
  return (
    <>
      <section className="bannerBox commonPadding">
        <Row>
          <Col xl={6} lg={6}>
            <h1 className="heading">
              <span>Automate your</span> Meeting Notes and Proposal
            </h1>
          </Col>
          <Col xl={6} lg={12}>
            <div className="details">
              <p>
                coPitch.ai helps your team transcribe, summarize, search, and
                analyze voice conversations to create an engaging proposal that
                helps to win whatever you are looking for.
              </p>
              <div className="btn-Box">
                <Button color={"white"} text={"Request a Trial"} />
                <Button color={"blue"} text={"Get Started for FREE"} />
              </div>
            </div>
          </Col>
          <Col md={12}>
            <div className="img">
              <img src={myimage} alt="" />
            </div>
          </Col>
        </Row>
      </section>
    </>
  );
};

export default Bannersection;
