import { useEffect, useState } from "react";
import { Droppable } from "react-beautiful-dnd";
import moment from "moment";

export const formatDate = (date, format = "ll") => {
  let formated = moment(date).format(format);
  if (formated !== "Invalid date") {
    return formated;
  }
  return null;
};
// const formatDate = (dateString) => {
//   const options = {
//     day: "numeric",
//     month: "long",
//     year: "numeric",
//   };

//   const date = new Date(dateString);
//   const formattedDate = new Intl.DateTimeFormat("en-GB", options).format(date);

//   return formattedDate;
// };
export const StrictModeDroppable = ({ children, ...props }) => {
  const [enabled, setEnabled] = useState(false);
  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true));
    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);
  if (!enabled) {
    return null;
  }
  return <Droppable {...props}>{children}</Droppable>;
};

export const removeQuotationMarks = (string) => {
  if (string) {
    if (string.charAt(0) === '"' && string.charAt(string.length - 1) === '"') {
      string = string.slice(1, -1);
    }
    return string;
  } else return null;
};

export const getRandomColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};
