import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Automaticallyrecord from "../components/Homepage/Automaticallyrecord";
import Bannersection from "../components/Homepage/Bannersection";
import Chooseplan from "../components/Homepage/Chooseplan";
import Footer from "../components/Homepage/Footer";
import Letdownload from "../components/Homepage/Letdownload";
import Recordtranscribesection from "../components/Homepage/Recordtranscribesection";
import Salescriptsection from "../components/Homepage/Salescriptsection";

const Home = () => {
  const user = useSelector((state) => state.dataSlice.user);
  const navigate = useNavigate();
  useEffect(() => {
    if (user?.id) {
      navigate("/frameworkHomepage");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Bannersection />
      <Recordtranscribesection />
      <Automaticallyrecord />
      <Salescriptsection />
      <Chooseplan />
      <Letdownload />
      <Footer />
    </>
  );
};

export default Home;
