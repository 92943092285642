import React, { useState } from "react";
import { Col } from "react-bootstrap";
import { Icon } from "react-icons-kit";
import { eye } from "react-icons-kit/feather/eye";
import { eyeOff } from "react-icons-kit/feather/eyeOff";
import { useNavigate } from "react-router-dom";
import facebookImg from "../../img/facebookimg.png";
import googleImg from "../../img/googleimg.png";
import Introdetails from "../Resetpasswordpage/Introdetails";
import Questiondetails from "../Resetpasswordpage/Questiondetails";
import Rememberforgotbox from "../Resetpasswordpage/Rememberforgotbox";
import Tooltipbox from "../Resetpasswordpage/Tooltipbox";

import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { useLazyGetUserQuery } from "../../redux-setup/apiSlice";
import { useLoginMutation } from "../../redux-setup/authSlice";
import { setUser } from "../../redux-setup/dataSlice";
import { loginSchema } from "../../schemas";
import { SECURED_USER_COPITCH } from "../../utils/contants";
import { decodeJWTToken } from "../../utils/jwt-token";
import { setItem } from "../../utils/localstorage-config";
const Loginfieldsection = () => {
  const navigate = useNavigate();
  const [loginUser, { isLoading }] = useLoginMutation();
  const initialValues = {
    email: "",
    password: "",
  };
  const dispatch = useDispatch();
  const [getUser] = useLazyGetUserQuery();
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setErrors,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: loginSchema,
    onSubmit: (values) => {
      loginUser(values)
        .unwrap()
        .then((res) => {
          const token = `Bearer ${res.accessToken}`;
          const jwtData = decodeJWTToken(token);
          setItem(SECURED_USER_COPITCH, token);
          getUser(jwtData.userId)
            .unwrap()
            .then((user) => {
              dispatch(setUser(user));
              navigate("/frameworkhomepage");
            })
            .catch((err) => console.error(err));
        })
        .catch((err) => {
          setErrors({
            ...errors,
            login: err?.data?.message ? err.data.message : "Cannot Login",
          });
          console.error(err);
        });
    },
  });

  return (
    <Col lg={6}>
      <div className="loginFieldBox">
        <Introdetails
          heading="Welcome Back"
          paragraph="Please enter your login details"
        />
        <Tooltipbox />
        <div className="loginButtons">
          <button>
            <img src={googleImg} alt="Google" />
            Login with Google
          </button>
          <button>
            <img src={facebookImg} alt="Facebook" />
            Login with Facebook
          </button>
        </div>

        <form onSubmit={handleSubmit}>
          <div className="fieldsBox">
            <div>
              <label>Email</label>
              <input
                placeholder="Enter you email address..."
                name="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.email && touched.email ? (
                <p className="form-error">*{errors.email}</p>
              ) : null}
            </div>
            <PasswordfieldBox
              label="Password"
              value={values.password}
              handleChange={handleChange}
              handleBlur={handleBlur}
              error={errors}
              touched={touched}
            />
            {errors.login ? (
              <p className="form-error">*{errors.login}</p>
            ) : null}
            <button disabled={isLoading} className="loginButton" type="submit">
              {isLoading ? "Loging in . . ." : " Login"}
            </button>
          </div>
          <Rememberforgotbox />
        </form>

        <Questiondetails
          text="Don’t have an account?"
          linkText="Register Now"
          link="/accountregistration"
        />
      </div>
    </Col>
  );
};

const PasswordfieldBox = (props) => {
  const { values, handleBlur, handleChange, error, touched } = props;
  const { label } = props;
  // const [password, setPassword] = useState("");
  const [type, setType] = useState("password");
  const [icon, setIcon] = useState(eyeOff);

  const handleToggle = () => {
    if (type === "password") {
      setIcon(eye);
      setType("text");
    } else {
      setIcon(eyeOff);
      setType("password");
    }
  };

  return (
    <div>
      <label>{label}</label>
      <div className="passwordField">
        <input
          type={type}
          name="password"
          placeholder="Password"
          value={values}
          onChange={handleChange}
          onBlur={handleBlur}
          autoComplete="current-password"
        />
        <span onClick={handleToggle}>
          <Icon className="absolute mr-10" icon={icon} size={25} />
        </span>
      </div>
      {error.password && touched.password ? (
        <p className="form-error">*{error.password}</p>
      ) : null}
    </div>
  );
};

export default Loginfieldsection;
