import React, { useEffect, useState } from "react";
import { Container, Row, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { getAuthToken } from "../../utils/localstorage-config";
import Commonbluesection from "./Commonbluesection";
import Loginfieldsection from "./Loginfieldsection";

const Login = () => {
  // const { auth } = use Auth();
  const token = getAuthToken();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      navigate("/frameworkHomepage");
      setLoading(false);
    } else {
      setLoading(false);
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return loading ? (
    <div
      style={{
        width: "100vw",
        height: "100vh",
      }}
      className="d-flex justify-content-center align-items-center "
    >
      <Spinner
        style={{
          width: 50,
          height: 50,
        }}
      />
    </div>
  ) : (
    <section className="loginpagebox">
      <Container fluid>
        <Row>
          <Commonbluesection />
          <Loginfieldsection />
        </Row>
      </Container>
    </section>
  );
};

export default Login;
