import React from "react";
import { Col } from "react-bootstrap";
import Introdetails from "../Resetpasswordpage/Introdetails";
import Questiondetails from "../Resetpasswordpage/Questiondetails";

import { useFormik } from "formik";
import { emailSchema } from "../../schemas";

const Resetpasswordbox = ({ showComponent2 }) => {
  const initialValues = {
    email: "",
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: emailSchema,
      onSubmit: () => {
        showComponent2();
      },
    });

  return (
    <Col lg={6}>
      <div className="loginFieldBox">
        <Introdetails
          heading="Reset Password"
          paragraph="Please confirm your email to reset password"
        />
        <form onSubmit={handleSubmit}>
          <div className="fieldsBox">
            <div>
              <label>Email</label>
              <input
                type="text"
                placeholder="Enter you email address..."
                name="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.email && touched.email ? (
                <p className="form-error">*{errors.email}</p>
              ) : null}
            </div>

            <button className="loginButton" type="submit">
              Continue
            </button>
          </div>
        </form>

        <Questiondetails
          text="Already have an account?"
          linkText="Login"
          link="/login"
        />
      </div>
    </Col>
  );
};

export default Resetpasswordbox;
