import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Sliderbox from "./Sliderbox";
import Testing from "./Testing";

const Chooseplan = () => {
  const [showComponentA, setShowComponentA] = useState(true);
  const [isButton1Active, setIsButton1Active] = useState(true);
  const [isButton2Active, setIsButton2Active] = useState(false);

  const toggleToShowComponentA = (number) => {
    setShowComponentA(true);
    handleButtonClick(number);
  };

  const toggleToShowComponentB = (number) => {
    setShowComponentA(false);
    handleButtonClick(number);
  };

  const handleButtonClick = (buttonNumber) => {
    if (buttonNumber === 1) {
      setIsButton1Active(true);
      setIsButton2Active(false);
    } else if (buttonNumber === 2) {
      setIsButton1Active(false);
      setIsButton2Active(true);
    }
  };

  return (
    <section className="salescriptsection chooseplanSection">
      <Container fluid>
        <div className="salescriptbox chooseplan commonPadding">
          <Row>
            {/* === */}
            <Col md={12}>
              <div className="detail">
                <h3>Choose Plan That’s Right For You</h3>
                <p>
                  Choose plan that works best for you, feel free to contact us
                </p>
                <div className="bgChoose"></div>
              </div>

              <div className="btnGroupPlan">
                <button
                  className={isButton1Active ? "active" : ""}
                  onClick={() => toggleToShowComponentA(1)}
                >
                  Bill Monthly
                </button>
                <button
                  className={isButton2Active ? "active" : ""}
                  onClick={() => toggleToShowComponentB(2)}
                >
                  Bill yearly
                </button>
              </div>
              {showComponentA ? <Sliderbox /> : <Testing />}
              <div className="bgBlack"></div>
            </Col>
          </Row>
        </div>
      </Container>
    </section>
  );
};

export default Chooseplan;
