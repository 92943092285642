import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import uploadImg from "../../img/uploadimg.png";
import { formatDate } from "../../utils/CommonFuntions";

function MyEditPopup(props) {
  const handleDone = () => {
    props.onHide();
  };
  const handleCancel = () => {
    props.onHide();
  };

  const [File, setFile] = useState();
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  return (
    <Modal
      className="frameworkModal"
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Update Account
        </Modal.Title>
      </Modal.Header>
      <Form className="frameworkPopup">
        <Modal.Body>
          <Row>
            <Col sm={12}>
              <Form.Group controlId="exampleForm.ControlInput1">
                <Form.Label>Company Name</Form.Label>
                <Form.Control type="text" placeholder="Name Here" />
              </Form.Group>
            </Col>
            <Col sm={12}>
              {/* <FileUploader handleFile={handleFile}/>
      {fileName ? <p>Uploaded file: {fileName}</p> : null} */}

              <label className="input-label-custom w-100 rounded-pill ">
                <div className="px-3 py-2 d-flex align-items-center justify-content-between">
                  <p className="mb-0">
                    <img
                      src={uploadImg}
                      alt="Upload Company Logo"
                      className="me-2"
                    />
                    Upload Company Logo
                  </p>
                  <input
                    type="file"
                    className="d-none"
                    onChange={handleFileChange}
                  />

                  <div>
                    <span className="text-decoration-underline">Upload</span>
                  </div>
                </div>
              </label>
              {File && File.name}
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <button type="button" className="cancelBtn" onClick={handleCancel}>
            Close
          </button>
          <button type="button" className="doneBtn" onClick={handleDone}>
            Done
          </button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

function MyDeletePopup(props) {
  const handleDone = () => {
    props.deleteAccount(props?.id);
    props.onHide();
  };
  const handleCancel = () => {
    props.onHide();
  };
  return (
    <Modal
      className="frameworkModal"
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Are you sure you want to delete this?
        </Modal.Title>
      </Modal.Header>
      <Form className="frameworkPopup">
        <Modal.Footer>
          <button type="button" className="cancelBtn" onClick={handleCancel}>
            Close
          </button>
          <button type="button" className="doneBtn" onClick={handleDone}>
            Done
          </button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

const Accountcomponent = ({ data, deleteAccount, setAccountEdit }) => {
  const { id, accountName, lastMeeting, APR, attachment } = data;
  const [modalShow, setModalShow] = useState(false);
  const [modalDeleteShow, setModalDeleteShow] = useState(false);
  const navigate = useNavigate();

  return (
    <>
      <Col
        onClick={() => {
          // e.preventDefault();
          navigate(`/opendeals?account=${data.id}`);
        }}
        md={2}
      >
        <div key={id} className="accountBox">
          <div className="companyName">
            {!attachment ? (
              <div
                style={{
                  width: 25,
                  height: 25,
                  background: "#ccc",
                  fontWeight: "bold",
                  borderRadius: "50%",
                  marginRight: "10px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {accountName && accountName[0]?.toUpperCase()}
              </div>
            ) : (
              <img className="logo" src={attachment} alt={accountName} />
            )}
            <div>
              <p className="name">{accountName}</p>
            </div>
            {/* </Link> */}
          </div>

          <Dropdown onClick={(e) => e.stopPropagation()}>
            <Dropdown.Toggle variant="transparent" id="dropdown-basic">
              <i className="fa-solid fa-ellipsis-vertical"></i>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item>
                <button
                  className="editDeletebtn"
                  onClick={() => setAccountEdit(data)}
                >
                  <i className="fa-solid fa-pen"></i> Edit
                </button>
              </Dropdown.Item>

              <Dropdown.Item>
                <button
                  className="editDeletebtn"
                  onClick={() => setModalDeleteShow(true)}
                >
                  <i className="fa-solid fa-trash-can"></i> Delete
                </button>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <p className="lastmeeting">
            Last Meeting: <span>{formatDate(lastMeeting)}</span>
          </p>
          <p className="lastmeeting">
            ARR: <span>{APR}</span>
          </p>
        </div>
      </Col>

      <MyEditPopup
        show={modalShow}
        data={data}
        onHide={() => setModalShow(false)}
      />

      <MyDeletePopup
        show={modalDeleteShow}
        id={id}
        deleteAccount={deleteAccount}
        onHide={() => setModalDeleteShow(false)}
      />
    </>
  );
};

export default Accountcomponent;
