import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import * as yup from "yup";
import googleMeetimg from "../../img/googlemeetimg.png";
import uploadImg from "../../img/uploadimg.png";
import zoomimg from "../../img/zoomimg.png";
import {
  useCreateMeetingByDealIDMutation,
  useGetAccountDealsQuery,
  useUpdateMeetingMutation,
} from "../../redux-setup/apiSlice";

export function MeetingModalBox(props) {
  let schema = yup.object().shape({
    email: yup.string().email(),
  });
  const [data, setData] = useState({
    accountId: "",
    dealId: "",
    meetingName: "",
    meetingType: "",
    meetingStartDateTime: "",
    attendeesEmail: [],
  });
  useEffect(() => {
    if (props.data.id || props.data.meetingType) {
      setData({
        ...data,
        ...props.data,
        meetingStartDateTime: props.data?.meetingStartDateTime
          ? new Date(props.data?.meetingStartDateTime)
          : "",
        dealId: props.data.dealId || "",
      });
    } else {
      setFile();
      setData({
        accountId: "",
        dealId: "",
        meetingName: "",
        meetingType: "",
        meetingStartDateTime: "",
        attendeesEmail: [],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data, props.modalShow]);
  const { data: deals = {} } = useGetAccountDealsQuery(data.accountId, {
    skip: !data?.accountId,
  });
  const [createMeeting, { isLoading: creating }] =
    useCreateMeetingByDealIDMutation();
  const [updateMeeting, { isLoading: updating }] = useUpdateMeetingMutation();
  const isLoading = creating || updating;
  const handleDone = async (e) => {
    e.preventDefault();
    const {
      meetingName,
      accountId,
      meetingType,
      meetingStartDateTime,
      dealId,
    } = data;
    const { id: userId } = props.user;
    let formData = new FormData();
    if (props.data.id) {
      if (!meetingName || !accountId) {
        toast.error("Missing fields");
        return;
      }
      updateMeeting({
        id: props.data.id,
        data: {
          meetingName,
          accountId: parseInt(accountId, 10),
          dealId: dealId ? parseInt(dealId, 10) : "",
        },
      })
        .unwrap()
        .then(() => {
          handleCancel();
          toast.success("Meeting Updated Successfully");
        })
        .catch(() => {
          toast.error("Cannot Update Meeting ");
        });
      return;
    }
    formData.append("accountId", parseInt(accountId, 10));
    formData.append("userId", parseInt(userId, 10));
    formData.append("dealId", dealId ? parseInt(dealId, 10) : "");
    formData.append("attachment", File);
    if (props.uploadTranscript) {
      if (!File || !accountId) {
        toast.error("Missing fields");
        return;
      }
      const formatDateTime = (dateTime) => dateTime.toISOString();
      const formattedStartDateTime = formatDateTime(new Date());
      formData.append("meetingStartDateTime", formattedStartDateTime);
    } else {
      if (!meetingName || !accountId || !meetingType || !meetingStartDateTime) {
        toast.error("Missing fields");
        return;
      }
      const formatDateTime = (dateTime) => dateTime.toISOString();
      const formattedStartDateTime = formatDateTime(meetingStartDateTime);
      formData.append("meetingStartDateTime", formattedStartDateTime);
    }
    formData.append("status", "Inactive");
    let emails = JSON.stringify(data.attendeesEmail);
    formData.append("attendeesEmail", emails);
    formData.append("meetingType", meetingType);
    formData.append("meetingName", meetingName);
    createMeeting(formData)
      .unwrap()
      .then(() => {
        toast.success(
          props.uploadTranscript
            ? "Transcript Uploaded successfully"
            : "Meeting created successfully"
        );
        handleCancel();
      })
      .catch((err) => {
        console.error(err);
        toast.error(
          props.uploadTranscript
            ? "Transcript Upload failed"
            : "Cannot create a meeting at this moment. Please try again later."
        );
      });
  };
  const handleCancel = () => {
    props.onHide();
    setFile();
    setData({
      accountId: "",
      dealId: "",
      meetingName: "",
      meetingType: "",
      meetingStartDateTime: "",
      attendeesEmail: [],
    });
  };
  const [File, setFile] = useState();
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };
  const handleEmail = (e) => {
    let emails = e.split(",");
    let attendeesEmail = [];
    emails.forEach((it) => {
      schema
        .isValid({
          email: it,
        })
        .then((valid) => {
          if (valid) {
            attendeesEmail.push({
              email: it,
            });
          }
        });
    });
    props.user.email?.trim() &&
      attendeesEmail.unshift({ email: props.user.email });
    setData({
      ...data,
      attendeesEmail,
    });
  };
  const handleText = () => {
    if (props.data.id) {
      return isLoading ? "Updating..." : "Update";
    }
    return isLoading ? "Creating..." : "Create";
  };
  return (
    <Modal
      className="frameworkModal"
      {...props}
      onHide={handleCancel}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.title
            ? props.title
            : props.uploadTranscript
            ? "Upload Transcript"
            : "Add a new meeting"}
        </Modal.Title>
      </Modal.Header>
      <Form
        className="frameworkPopup"
        onSubmit={(e) => {
          handleDone(e);
        }}
      >
        <Modal.Body>
          <Row>
            {!props.uploadTranscript && (
              <>
                <Col sm={12}>
                  <Form.Group>
                    <Form.Label>Meeting Name</Form.Label>
                    <Form.Control
                      type="text"
                      defaultValue={data.meetingName}
                      placeholder="Type Here"
                      onChange={(e) => {
                        setData({ ...data, meetingName: e.target.value });
                        // setMeetingName(e.target.value);
                      }}
                    />
                  </Form.Group>
                </Col>
              </>
            )}
            {props.data.dealId && !props.data.id ? (
              <></>
            ) : (
              <>
                <Col sm={6}>
                  <Form.Group>
                    <Form.Label>Select Account</Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      value={data.accountId}
                      onChange={(e) =>
                        setData({
                          ...data,
                          accountId:
                            e.target.value === "Select" ? "" : e.target.value,
                        })
                      }

                      // setSelectedAcc(e.target.value)}
                    >
                      <option>Select</option>
                      {props.accounts?.map((acc) => (
                        <option key={acc.id} value={acc.id}>
                          {acc.accountName}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col sm={6}>
                  <Form.Group>
                    <Form.Label>Select Deal</Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      value={data.dealId}
                      onChange={(e) =>
                        setData({
                          ...data,
                          dealId:
                            e.target.value === "Select" ? "" : e.target.value,
                        })
                      }
                    >
                      <option>Select</option>
                      {deals.deal?.map((deal) => (
                        <option key={deal.id} value={deal.id}>
                          {deal.dealName}
                          {" $" + (deal.ARR ? deal.ARR : 0)}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
              </>
            )}
            {!props.uploadTranscript && !props.data.id && (
              <>
                <Col sm={12}>
                  <h6>Link Google Meet OR Zoom</h6>
                </Col>

                <Col sm={12}>
                  <div className="meetingBtnBox">
                    <button
                      className={`meetingbtns ${
                        data.meetingType === "zoom" ? "active-medium" : ""
                      }`}
                      type="button"
                      onClick={(e) => {
                        e.preventDefault();
                        setData({ ...data, meetingType: "zoom" });
                        // setMedium("zoom");
                      }}
                    >
                      Schedule meeting on Zoom{" "}
                      <img src={zoomimg} alt="zoomimg" />
                    </button>
                    <h6>OR</h6>
                    <button
                      className={`meetingbtns ${
                        data.meetingType === "googleMeet" ? "active-medium" : ""
                      }`}
                      type="button"
                      onClick={(e) => {
                        e.preventDefault();
                        setData({ ...data, meetingType: "googleMeet" });

                        // setMedium("Meet");
                      }}
                    >
                      Schedule meeting on Meet{" "}
                      <img src={googleMeetimg} alt="googleMeetimg" />
                    </button>
                  </div>
                </Col>
                {data?.meetingType && (
                  <>
                    <Col sm={12}>
                      <Form.Group className="meetingDatepicker">
                        <Form.Label>Select Date/Time</Form.Label>
                        <DatePicker
                          className="form-control w-100 "
                          selected={data.meetingStartDateTime || ""}
                          onChange={(e) => {
                            setData({ ...data, meetingStartDateTime: e });
                          }}
                          showTimeSelect
                          dateFormat="Pp"
                          timeFormat="HH:mm"
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={12}>
                      <Form.Group>
                        <Form.Label>Attendee Email:</Form.Label>
                        <Form.Control
                          type="text"
                          onChange={(e) => {
                            handleEmail(e.target.value);
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </>
                )}
              </>
            )}
            {props.uploadTranscript && (
              <>
                <Col sm={12}>
                  <label className="input-label-custom w-100 rounded-pill ">
                    <div className="px-3 py-2 d-flex align-items-center justify-content-between">
                      <p className="mb-0">
                        <img src={uploadImg} className="me-2" alt="Upload" />
                        {props.title || "Upload Transcript"}
                      </p>
                      <input
                        type="file"
                        className="d-none"
                        onChange={handleFileChange}
                      />

                      <div>
                        <span className="text-decoration-underline">
                          Upload
                        </span>
                      </div>
                    </div>
                  </label>
                  {File && File.name}
                </Col>
              </>
            )}
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <button type="reset" className="cancelBtn" onClick={handleCancel}>
            Close
          </button>
          <button disabled={isLoading} type="submit" className="doneBtn">
            {handleText()}
          </button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
