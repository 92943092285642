import React from "react";
import { Col, Container } from "react-bootstrap";
import audioImg from "../../img/audio.png";
import purplelayerimg from "../../img/layer.png";
import Button from "./Custombutton";

const Recordtranscribesection = () => {
  return (
    <section className="recordSection">
      <Container>
        <div className="row">
          <Col lg={12}>
            <h2>
              Rec
              <span>
                <img src={audioImg} className="audio" alt="Audio" />
              </span>
              rd, Transcribe, Take Notes & Get AI-generated proposals.
            </h2>
          </Col>
          <Col md={12}>
            <Button color={"blue"} text={"Get Started for Free"} />
          </Col>
          <Col md={12}>
            <img className="purplelayer" src={purplelayerimg} alt="" />
          </Col>
        </div>
      </Container>
    </section>
  );
};

export default Recordtranscribesection;
