import { isString } from "formik";
import React, { useEffect, useState } from "react";
import { Accordion, Col, Container, Form, Row } from "react-bootstrap";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { toast } from "react-toastify";
import {
  useUpdateTemplateMutation,
  useUpdateEvidenceScoreMutation,
} from "../../redux-setup/apiSlice";
const Evidence = ({
  templates = {},
  evData = {},
  selectedFrameWork = "",
  setSelectedFrameWork,
}) => {
  const { frameWork = [] } = templates;
  const [updateTemplate] = useUpdateTemplateMutation();
  const [updateEvidence] = useUpdateEvidenceScoreMutation();

  const [editAccordianIndex, setEditAccordianIndex] = useState(null);
  const [isSaveDisabled, setIsSaveDisabled] = useState(false);
  const [editedFrameworkName, setEditedFrameworkName] = useState("");
  const [selectedFrameWorkData, setSelectedFrameWorkData] = useState({});
  const [evidenceData, setEvidenceData] = useState(evData || {});

  useEffect(() => {
    if (selectedFrameWork) {
      setEvidenceData({ ...evData });
      const tempFrameWork = frameWork.find(
        (fw) => parseInt(fw.id) === parseInt(selectedFrameWork)
      );
      if (tempFrameWork) setSelectedFrameWorkData({ ...tempFrameWork });
    } else {
      setEvidenceData({});
      setSelectedFrameWorkData({});
    }
    // }, [selectedFrameWork]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [evData, selectedFrameWork]);
  const displayedItems = selectedFrameWorkData.headlines
    ? selectedFrameWorkData.headlines.map((it) => {
        const item = it.headline.title;
        const value = evidenceData?.userContent?.[item] || {
          content: [],
          title: "",
        };
        return [item, value];
      })
    : [];
  const handleEditClick = (index) => {
    setEditAccordianIndex(index);
    setEditedFrameworkName(
      isString(selectedFrameWorkData?.titles[index])
        ? selectedFrameWorkData?.titles[index]
        : selectedFrameWorkData?.titles[index].name ??
            selectedFrameWorkData?.titles[index].title
    );
  };
  const updateFramworks = (data) => {
    toast.promise(
      updateTemplate({ id: selectedFrameWorkData?.id, data })
        .unwrap()
        .then(() => {
          !selectedFrameWork && setSelectedFrameWork(selectedFrameWorkData?.id);
          editAccordianIndex >= 0 && setEditAccordianIndex(null);
        })
        .catch((err) => console.error(err)),
      {
        pending: "Updating",
        success: "Updated Successfully👌",
        error: "Cannot Update 🤯",
      }
    );
  };
  const handleSaveClick = (index) => {
    let copy = JSON.parse(JSON.stringify(selectedFrameWorkData?.titles));
    copy[index] =
      typeof copy[index] == "object"
        ? { ...copy[index], name: editedFrameworkName }
        : { subCategories: [], name: editedFrameworkName };
    // updateFramworks({ titles: copy });
  };
  const handleCancelClick = () => {
    setEditAccordianIndex(null);
  };
  const moveItem = (fromIndex, toIndex) => {
    const copy = [...selectedFrameWorkData.headlines]; // Shallow copy of the array
    const [movedItem] = copy.splice(fromIndex, 1);
    if (toIndex >= copy.length) {
      copy.push(movedItem);
    } else {
      copy.splice(toIndex, 0, movedItem);
    }
    selectedFrameWorkData.headlines = [...copy];
    let temp = structuredClone(selectedFrameWorkData);
    setSelectedFrameWorkData(temp);

    const { title, type, description, headlines } = temp;
    const titles = headlines.map((headline, index) => ({
      id: headline.headlineId,
      sortingId: index + 1,
    }));
    updateFramworks({
      title: title,
      description: description,
      type: type,
      titles: titles,
    });
  };

  const handleUpdateScore = () => {
    // console.log("Evidence ~ userData:", userData);
    const userContentData = { ...(evidenceData?.userContent || {}) };
    updateEvidence({
      id: evidenceData?.id,
      data: { userContent: userContentData },
    })
      .unwrap()
      .then(() => {
        toast.success("Evidence Data Updated Successfully");
      })
      .catch(() => {
        toast.error("Could not Update Evidence Data ");
      });
  };

  return (
    <>
      <DndProvider backend={HTML5Backend}>
        <div className="bcb__evidence">
          <div className="frameworkTitlebox">
            {/* ========== */}
            <Container>
              <Row>
                <Col sm={12} className="openbuttonbox">
                  <div className="frameworkSelection">
                    <Form.Label
                      style={{
                        fontWeight: 500,
                        fontSize: "14px",
                        lineHeight: "21px",
                        color: "#475569",
                      }}
                    >
                      Framework:{" "}
                    </Form.Label>
                    <Form.Select
                      defaultValue={selectedFrameWork || ""}
                      value={selectedFrameWork}
                      onChange={(e) =>
                        setSelectedFrameWork(
                          e.target.value === "Select" ? "" : e.target.value
                        )
                      }
                      aria-label="Select Framework"
                    >
                      <option>Select</option>;
                      {frameWork?.map((it, i) => (
                        <option key={i} value={it.id}>
                          {it.title}
                        </option>
                      ))}
                    </Form.Select>
                  </div>
                  <button
                    disabled={isSaveDisabled}
                    onClick={() => handleUpdateScore()}
                    style={{
                      borderRadius: "32px",
                      fontSize: "14px",
                      padding: "12px 24px",
                    }}
                    className={`${
                      isSaveDisabled ? "disabled" : ""
                    } save-button`}
                  >
                    Save
                  </button>
                </Col>
                <Col sm={12}>
                  <Accordion defaultActiveKey={0}>
                    {displayedItems.length > 0 ? (
                      displayedItems.map(([item, value], index) => (
                        <EvidenceData
                          key={index}
                          evidenceData={evidenceData}
                          setEvidenceData={setEvidenceData}
                          editAccordianIndex={editAccordianIndex}
                          setEditAccordianIndex={setEditAccordianIndex}
                          editedFrameworkName={editedFrameworkName}
                          setEditedFrameworkName={setEditedFrameworkName}
                          handleCancelClick={handleCancelClick}
                          handleSaveClick={handleSaveClick}
                          handleEditClick={handleEditClick}
                          moveItem={moveItem}
                          item={item}
                          index={index}
                          value={value}
                        />
                      ))
                    ) : (
                      <p style={{ marginBottom: 0 }}>No data to show</p>
                    )}
                  </Accordion>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </DndProvider>
    </>
  );
};

const ItemTypes = {
  ACCORDION_ITEM: "accordion-item",
};

const EvidenceData = ({
  moveItem,
  editAccordianIndex,
  editedFrameworkName,
  setEditedFrameworkName,
  index,
  item,
  value,
  setEvidenceData,
  evidenceData,
}) => {
  const [, drag] = useDrag({
    type: ItemTypes.ACCORDION_ITEM,
    item: { id: index, name: item.name },
    options: {
      dropEffect: "move",
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });
  const [, drop] = useDrop({
    accept: ItemTypes.ACCORDION_ITEM,
    drop: (draggedItem) => {
      if (draggedItem.id !== index) {
        moveItem(draggedItem.id, index);
        draggedItem.id = index;
      }
    },
  });
  return (
    <>
      <div ref={(node) => drag(drop(node))}>
        <Accordion.Item eventKey={index + 1}>
          <Accordion.Header>
            <div className="addedFrameworksDetails">
              {editAccordianIndex === index ? (
                <input
                  type="text"
                  value={editedFrameworkName}
                  onChange={(e) => setEditedFrameworkName(e.target.value)}
                />
              ) : (
                <>
                  <p className="nameFramework">{item}</p>
                </>
              )}
              <svg
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_4278_11353)">
                  <path
                    d="M18 9.5L21 12.5L18 15.5"
                    stroke="#017CFF"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M15 12.5H21"
                    stroke="#017CFF"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M6 9.5L3 12.5L6 15.5"
                    stroke="#017CFF"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M3 12.5H9"
                    stroke="#017CFF"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M9 18.5L12 21.5L15 18.5"
                    stroke="#017CFF"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M12 15.5V21.5"
                    stroke="#017CFF"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M15 6.5L12 3.5L9 6.5"
                    stroke="#017CFF"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M12 3.5V9.5"
                    stroke="#017CFF"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_4278_11353">
                    <rect
                      width="24"
                      height="24"
                      fill="white"
                      transform="translate(0 0.5)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </Accordion.Header>
          <Accordion.Body className="childAccordionsLevel1">
            <React.Fragment key={index}>
              <div className="childAccordionsLevel2">
                {(Array.isArray(value) ? value : value?.data) &&
                (Array.isArray(value) ? value : value?.data).length > 0 ? (
                  (Array.isArray(value) ? value : value?.data).map(
                    (item, itemIndex) => (
                      <React.Fragment key={itemIndex}>
                        <Accordion defaultActiveKey={0}>
                          <Accordion.Item>
                            <Accordion.Header>
                              <div className="evidenceSummaryTitleBar evidenceSummaryTitleBarParent">
                                <h5>{item.title || ""}</h5>
                              </div>
                            </Accordion.Header>
                            {/* <Accordion.Body>{item?.content || ""}</Accordion.Body> */}
                            <Accordion.Body>
                              {item?.content &&
                                item?.content.map(
                                  (contentItem, contentItemIndex) => (
                                    <React.Fragment key={contentItemIndex}>
                                      <ul className="evidenceSummaryTitleBar">
                                        <li>{contentItem.evidence || ""}</li>
                                        <div className="settings">
                                          <InclusionSelector
                                            item={contentItem}
                                            evidenceData={evidenceData}
                                            setEvidenceData={setEvidenceData}
                                          />
                                        </div>
                                      </ul>
                                    </React.Fragment>
                                  )
                                )}
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </React.Fragment>
                    )
                  )
                ) : (
                  <p style={{ marginBottom: 0 }}>No data to show</p>
                )}
              </div>
            </React.Fragment>
          </Accordion.Body>
        </Accordion.Item>
      </div>
    </>
  );
};

const InclusionSelector = ({ item, setEvidenceData, evidenceData }) => {
  const handleButtonClick = (newScore) => {
    let updatedEvidenceData = structuredClone(evidenceData);

    Object.keys(updatedEvidenceData.userContent).forEach((key) => {
      updatedEvidenceData.userContent[key] = updatedEvidenceData.userContent[
        key
      ].map((contentItem) => {
        const updatedContent = contentItem.content.map((contentObj) => {
          if (contentObj.evidence === item.evidence) {
            return { ...contentObj, score: newScore };
          }
          return contentObj;
        });
        return { ...contentItem, content: updatedContent };
      });
    });

    setEvidenceData(updatedEvidenceData);
  };

  const buttonsConfig = [
    {
      label: (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_4359_3313)">
            <path
              d="M12 4L4 12"
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M4 4L12 12"
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_4359_3313">
              <rect width="16" height="16" fill="white" />
            </clipPath>
          </defs>
        </svg>
      ),
      action: "exclude",
      score: 0,
      isActive: item.score === 0,
    },
    {
      label: (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_4359_3319)">
            <path
              d="M11.3327 4.66675L4.66602 11.3334"
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M10.666 11.3333H4.66602V5.33325"
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_4359_3319">
              <rect width="16" height="16" fill="white" />
            </clipPath>
          </defs>
        </svg>
      ),
      action: "maybe exclude",
      score: 1,
      isActive: item.score === 1,
    },
    {
      label: (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_4359_3324)">
            <path
              d="M11.3327 4.66675L4.66602 11.3334"
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M5.33398 4.66675H11.334V10.6667"
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_4359_3324">
              <rect width="16" height="16" fill="white" />
            </clipPath>
          </defs>
        </svg>
      ),
      action: "maybe include",
      score: 2,
      isActive: item.score === 2,
    },
    {
      label: (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_4359_3329)">
            <path
              d="M3.625 8L6.75 11.125L13 4.875"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_4359_3329">
              <rect
                width="15"
                height="15"
                fill="currentColor"
                transform="translate(0.5 0.5)"
              />
            </clipPath>
          </defs>
        </svg>
      ),
      action: "include",
      score: 3,
      isActive: item.score === 3,
    },
  ];

  return (
    <div className="inclusionSelector">
      {buttonsConfig.map((button, index) => (
        <button
          key={index}
          className={`
          ${button.isActive && "active-tick active"}
          ${
            item.score === 3 && item.score === button.score
              ? "active-tick"
              : item.score === 2 && item.score === button.score
              ? "active-up"
              : item.score === 1 && item.score === button.score
              ? "active-down"
              : item.score === 0 && item.score === button.score
              ? "active-cross"
              : "bg-red-500"
          }
          `}
          onClick={(e) => {
            e.stopPropagation();
            handleButtonClick(button.score);
          }}
        >
          {button.label}
        </button>
      ))}
    </div>
  );
};

export default Evidence;
