import React from "react";
import { Col } from "react-bootstrap";
import loginLogo from "../../img/login-logo.png";
import loginMeeting from "../../img/loginmeeting.png";

const Commonbluesection = () => {
  return (
    <Col lg={6}>
      <div className="blueAutomationSection">
        <div className="details">
          <img src={loginLogo} alt="login" />
          <h3>Automate your meeting notes and proposal</h3>
          <p>
            coPitch.ai helps your team transcribe, summarize, search, and
            analyze voice conversations to create an engaging proposal that
            helps to win whatever you are looking for.
          </p>
        </div>
        <div className="imgBox">
          <img src={loginMeeting} alt="Meeting login" />
        </div>
      </div>
    </Col>
  );
};

export default Commonbluesection;
