import { isString, useFormik } from "formik";
import React, { Fragment, useEffect, useState } from "react";
import { Col, Dropdown, Modal, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

import { useDispatch, useSelector } from "react-redux";
import {
  useCreateFrameworkTitleMutation,
  useCreateTemplateMutation,
  useDeleteFrameworkTitleMutation,
  useDeleteTemplateMutation,
  useGetFrameworkTitlesQuery,
  useUpdateFrameworkTitleMutation,
  useUpdateTemplateMutation,
} from "../../redux-setup/apiSlice";
import { setTitles } from "../../redux-setup/dataSlice";

import { decodeJWTToken } from "../../utils/jwt-token";
import { getAuthToken } from "../../utils/localstorage-config";

import dndIconWhite from "../../img/dndIconWhite.svg";
import framework24 from "../../img/framework-24.svg";
import framework32 from "../../img/framework-32.svg";
import framework64 from "../../img/framework-64.svg";
import EmptyState from "../common/EmptyState";

const FrameworksPage = ({ templates = {} }) => {
  const { frameWork = [] } = templates;

  const user = useSelector((state) => state.dataSlice.user);
  const dispatch = useDispatch();

  const [data, setData] = useState({
    title: "",
    description: "",
    type: "",
  });

  const { data: titlesList = [] } = useGetFrameworkTitlesQuery();

  // Initialize sortingId for each object in titlesList
  const titlesWithSortingId = titlesList.map((title, index) => ({
    ...title,
    sortingId: index, // Incremental value starting from 0
  }));
  if (titlesList && titlesList.length > 0)
    dispatch(setTitles(titlesWithSortingId));
  const [titlesData, setTitlesData] = useState(
    (titlesList && titlesList.length ? titlesWithSortingId : []) || []
  );
  const [mode, setMode] = useState("view");
  const [rightSideItems, setRightSideItems] = useState([]);
  const [isUpdating, setIsUpdating] = useState(false);
  const [deleteFramwork] = useDeleteTemplateMutation();
  const [modalDeleteShow, setModalDeleteShow] = useState(false);

  const token = getAuthToken();
  const jwtData = decodeJWTToken(token) ?? {};

  const handleDelete = () => {
    if (typeof modalDeleteShow === "number") {
      deleteFramwork(modalDeleteShow)
        .unwrap()
        .then(() => {
          setModalDeleteShow(false);
        })
        .catch((err) => {
          console.error(err);
          setModalDeleteShow(false);
        });
    }
  };

  const refreshLists = () => {
    // refreshing left items list
    if (titlesList && titlesList.length > 0) {
      if (rightSideItems.length) {
        const filteredTitleData = titlesWithSortingId.filter((titleItem) => {
          return !rightSideItems.some(
            (rightItem) => rightItem.headline.id === titleItem.id
          );
        });
        setTitlesData(filteredTitleData);
      } else setTitlesData(titlesWithSortingId);
    }
  };

  useEffect(() => {
    refreshLists();
  }, [titlesList, rightSideItems]);

  return (
    <>
      <DndProvider backend={HTML5Backend}>
        <div className="frameworksPage">
          {/* Create-new-framework */}
          {mode === "view" && templates && (
            <ManageFrameworks
              setMode={setMode}
              templates={templates}
              setRightSideItems={setRightSideItems}
              setIsUpdating={setIsUpdating}
              setData={setData}
              titlesData={titlesData}
              setTitlesData={setTitlesData}
              setModalDeleteShow={setModalDeleteShow}
              modalDeleteShow={modalDeleteShow}
              handleDelete={handleDelete}
              titlesList={titlesWithSortingId}
              refreshLists={refreshLists}
            />
          )}
          {mode === "create" && (
            <HandleFramework
              setMode={setMode}
              frameWork={frameWork}
              rightSideItems={rightSideItems}
              setRightSideItems={setRightSideItems}
              isUpdating={isUpdating}
              data={data}
              setData={setData}
              jwtData={jwtData}
              titlesData={titlesData}
              setTitlesData={setTitlesData}
              handleDelete={handleDelete}
              titlesList={titlesWithSortingId}
            />
          )}
          {/* First-modal-end */}
        </div>
      </DndProvider>
    </>
  );
};

// ============Popup-start===============

const ManageFrameworks = ({
  setMode,
  templates,
  setRightSideItems,
  setIsUpdating,
  setData,
  titlesData,
  setTitlesData,
  setModalDeleteShow,
  modalDeleteShow,
  handleDelete,
  titlesList,
  refreshLists,
}) => {
  const [display, setDisplay] = useState("list");
  const [searchParams, setSearchParams] = useSearchParams();
  const template = searchParams?.get("template") || "";
  const createLeftItems = () => {
    refreshLists();
    setMode("create");
  };

  const openFrameWorkDetails = (data) => {
    createLeftItems();
    setData(data);
    if (data && data?.headlines) {
      const rightSideItemsWithSortingIds = data.headlines.map(
        (title, index) => ({
          ...title,
          sortingId: index, // Incremental value starting from 0
        })
      );
      setRightSideItems(rightSideItemsWithSortingIds);
    }
    setIsUpdating(true);
  };

  useEffect(() => {
    if (template) {
      if (typeof template === "string" && template === "new") {
        createLeftItems();
      } else {
        const data = templates?.frameWork?.find((it) => it.id == template);
        data && openFrameWorkDetails(data);
      }
      searchParams.delete("template");
      setSearchParams(searchParams);
    }
  }, [template]);
  return (
    <>
      <div className="header">
        <div className="titleWithIcon">
          <img src={framework32} alt="accountBankImg" />
          <h3>Frameworks</h3>
        </div>
        {/* === */}
        <div className="headerButtons">
          <div className="displaySelector">
            <button
              className={`${display === "grid" ? "primary-button" : ""}`}
              onClick={() => setDisplay("grid")}
            >
              Grid View
            </button>
            <button
              className={`${display === "list" ? "primary-button" : ""}`}
              onClick={() => setDisplay("list")}
            >
              List View
            </button>
          </div>
          <button
            className="popupbtn primary-button"
            onClick={() => {
              setIsUpdating(false);
              createLeftItems();
            }}
          >
            Add Framework <span>+</span>
          </button>
        </div>

        {/* ========================================= */}
      </div>

      {/* ================================================= */}
      {display === "grid" ? (
        <Row>
          {templates.frameWork?.map((data) => {
            const { id, title: frameworkname, titles = [] } = data;
            return (
              <Fragment key={id}>
                <Col
                  onClick={() => {
                    openFrameWorkDetails(data);
                  }}
                  className="col-md-5-cols"
                >
                  <div
                    className="accountBox"
                    onClick={() => {
                      console.log("{templates.frameWork?.map ~ data:", data);
                    }}
                  >
                    <div className="companyName">
                      <p className="name">{frameworkname}</p>
                    </div>

                    <Dropdown onClick={(e) => e.stopPropagation()}>
                      <Dropdown.Toggle
                        variant="transparent"
                        id="dropdown-basic"
                      >
                        <i className="fa-solid fa-ellipsis-vertical"></i>
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item>
                          <button
                            className="editDeletebtn"
                            onClick={() => openFrameWorkDetails(data)}
                          >
                            <i className="fa-solid fa-pen"></i> Edit
                          </button>
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <button
                            className="editDeletebtn"
                            onClick={() => setModalDeleteShow(id)}
                          >
                            <i className="fa-solid fa-trash-can"></i> Delete
                          </button>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>

                    <ul className="frameworkList">
                      {titles.map((data, i) => (
                        <li key={i}>
                          {isString(data) ? data : data?.name ?? data.title}
                        </li>
                      ))}
                    </ul>
                  </div>
                </Col>
              </Fragment>
            );
          })}

          <Col className="col-md-5-cols">
            <button
              className="addAaccountBtn"
              onClick={() => {
                createLeftItems();
                setIsUpdating(false);
              }}
            >
              <span>+</span>
              Add a New Framework
            </button>
          </Col>
        </Row>
      ) : (
        // List View
        <div className="listView">
          {templates &&
          templates?.frameWork &&
          templates?.frameWork?.length > 0 ? (
            templates.frameWork?.map((data) => {
              const { id, title: frameworkname } = data;
              return (
                <Row
                  key={id}
                  style={{
                    cursor: "pointer",
                  }}
                  className="accountBox"
                  onClick={() => openFrameWorkDetails(data)}
                >
                  <>
                    <Col sm={3}>
                      <div className="list-column">
                        <img src={framework24} alt="frameworkIcon" />
                        <p className="title">{frameworkname}</p>
                      </div>
                    </Col>
                    <Col sm={3}>
                      <div className="list-column">
                        <p className="">Last Modified:</p>
                        <p className="bold">21th January, 2023</p>
                      </div>
                    </Col>
                    <Col sm={3}>
                      <div className="list-column">
                        <p className="">Total Titles:</p>
                        <p className="bold">20</p>
                      </div>
                    </Col>
                    <Col sm={3}>
                      <div className="list-column listViewButtons">
                        <button
                          className="edit"
                          onClick={(e) => {
                            e.stopPropagation();
                            openFrameWorkDetails(data);
                          }}
                        >
                          Edit
                        </button>
                        <button
                          className="delete"
                          onClick={(e) => {
                            e.stopPropagation();
                            setModalDeleteShow(id);
                          }}
                        >
                          Delete
                        </button>
                        <button
                          className="viewDetails"
                          onClick={(e) => {
                            e.stopPropagation();
                            openFrameWorkDetails(data);
                          }}
                        >
                          View Details
                        </button>
                      </div>
                    </Col>
                  </>
                </Row>
              );
            })
          ) : (
            <EmptyState
              title="No frameworks added"
              subtitle="Added frameworks will show up here"
            />
          )}
        </div>
      )}
      <MyDeletePopup
        show={modalDeleteShow}
        confirm={handleDelete}
        onHide={() => setModalDeleteShow(false)}
      />
    </>
  );
};

const HandleFramework = ({
  setMode,
  frameWork,
  rightSideItems,
  setRightSideItems,
  isUpdating,
  data,
  setData,
  jwtData,
  titlesData,
  setTitlesData,
  handleDelete,
  titlesList,
}) => {
  const [showTitleModal, setShowTitleModal] = useState(false);
  const [editTitleSource, setEditTitleSource] = useState("left");
  const [createTemplate, { isLoading }] = useCreateTemplateMutation();
  const [updateTemplate, { isLoading: updating }] = useUpdateTemplateMutation();
  const emptyTitleData = {
    name: "",
    context: "",
    requirements: "",
    focus_point: "",
    output_structure: "",
  };
  const [titleData, setTitleData] = useState(emptyTitleData || {});

  const handleCreate = () => {
    const { title, type, description } = data;
    let titlesIds = [];
    rightSideItems.forEach((rightItem, rightItemIndex) => {
      const matchingTitle = titlesList.find(
        (title) => title.id === rightItem.id
      );
      if (matchingTitle) {
        titlesIds.push({ id: matchingTitle.id, sortingId: rightItemIndex + 1 });
      }
    });
    createTemplate({
      id: jwtData?.userId,
      data: { title, type, description, titles: titlesIds },
    })
      .unwrap()
      .then(() => {
        handleCloseModal1();
        toast.success("Framework created");
        setMode("view");
      })
      .catch((err) => console.error(err));
  };

  const refreshRightList = () => {
    // refreshing right items list
    if (frameWork?.length > 0) {
      let tempData = frameWork.find((it) => it.id === data.id);
      if (tempData && tempData?.headlines) {
        const rightSideItemsWithSortingIds = tempData.headlines.map(
          (title, index) => ({
            ...title,
            sortingId: index, // Incremental value starting from 0
          })
        );
        setRightSideItems(rightSideItemsWithSortingIds);
      }
    }
  };

  useEffect(() => {
    refreshRightList();
  }, [frameWork]);
  const handleUpdate = () => {
    const { title, type, description } = data;
    let titlesIds = [];
    rightSideItems.forEach((rightItem, rightItemIndex) => {
      const matchingTitle = titlesList.find(
        (title) => title.id === rightItem.headline.id
      );
      if (matchingTitle) {
        titlesIds.push({ id: matchingTitle.id, sortingId: rightItemIndex + 1 });
      }
    });
    updateTemplate({
      id: data.id,
      data: { title, type, description, titles: titlesIds },
    })
      .unwrap()
      .then(() => {
        if (editTitleSource === "left") {
          handleCloseModal1();
          toast.success("Framework Updated");
          setMode("view");
        }
      })
      .catch((err) => {
        console.error(err);
        toast.error("Error updating ");
      });
  };
  const handleDrop = (side = "right", item) => {
    if (side === item.source) {
      return;
    }
    let filteredTitleData;
    let temp;
    if (side === "right") {
      filteredTitleData = titlesData.filter(
        (it) => it.sortingId !== item.sortingId
      );
      setTitlesData([...filteredTitleData]);
      let tempItem = {
        id: item.id,
        headline: { ...item },
        sortingId: rightSideItems?.length || 0,
      };
      // tempItem["sortingId"] = rightSideItems?.length || 0;
      temp = [...rightSideItems, tempItem];
      setRightSideItems(temp);
      setData({
        ...data,
        titles: temp.map((it) => ({
          name: it.name,
        })),
      });
    } else {
      filteredTitleData = rightSideItems.filter(
        (it) => it.sortingId !== item.sortingId
      );
      setRightSideItems([...filteredTitleData]);
      let tempItem = { ...item };
      const matchingTitle = titlesList.find(
        (title) => title.id === tempItem.id
      );
      if (matchingTitle) {
        tempItem["sortingId"] = matchingTitle.sortingId;
      }
      temp = [...titlesData, tempItem];
      setTitlesData(temp);
      setData({
        ...data,
        titles: filteredTitleData.map((it) => ({
          name: it.name,
        })),
      });
    }
  };
  const handleCloseModal1 = () => {
    setRightSideItems([]);
    setTitlesData(titlesList);
    setData({
      title: "",
      description: "",
      type: "",
    });
  };
  const handleOpenModal2 = (e) => {
    e.preventDefault();
    setTitleData(emptyTitleData);
    setShowTitleModal(true);
  };

  const closeTitleModal = () => {
    setTitleData(emptyTitleData);
    setShowTitleModal(false);
  };
  const moveItem = (side = "right", fromIndex, toIndex) => {
    let updatedTitleData = [];
    let [movedItem] = [];
    if (side === "left") {
      updatedTitleData = [...titlesData];
      [movedItem] = updatedTitleData.splice(fromIndex, 1);
      updatedTitleData.splice(toIndex, 0, movedItem);
      updatedTitleData.forEach((item, index) => {
        const newItem = { ...item };
        newItem["sortingId"] = parseInt(index.toString());
        updatedTitleData[index] = newItem;
      });
      setTitlesData([...updatedTitleData]);
    } else {
      updatedTitleData = [...rightSideItems];
      [movedItem] = updatedTitleData.splice(fromIndex, 1);
      updatedTitleData.splice(toIndex, 0, movedItem);
      updatedTitleData.forEach((item, index) => {
        item["sortingId"] = parseInt(index.toString());
      });
      setRightSideItems([...updatedTitleData]);
    }
  };

  const [, drop] = useDrop({
    accept: ItemTypes.ACCORDION_ITEM,
    drop: (item) => {
      handleDrop("left", item);
      // setItemDropped(true);
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });

  const handleEditTitle = (item) => {
    setTitleData(emptyTitleData);
    const matchingTitle = titlesList.find((title) => title.name === item.title);
    setTitleData(matchingTitle);
    setShowTitleModal(true);
  };

  return (
    <>
      <div>
        <div className="header">
          <div className="titleWithIcon">
            <img src={framework32} alt="accountBankImg" />

            <h3>{isUpdating ? "Update" : "Create New"} Framework</h3>
          </div>
          <div className="headerButtons">
            <button
              onClick={() => {
                handleCloseModal1();
                setMode("view");
              }}
              disabled={isLoading}
              className="secondary-button"
            >
              Discard {isUpdating ? "Changes" : "Framework"}
            </button>
            <button
              onClick={isUpdating ? handleUpdate : handleCreate}
              disabled={isLoading}
              className="primary-button"
            >
              {isUpdating
                ? updating
                  ? "Updating..."
                  : "Update Framework"
                : isLoading
                ? "Saving..."
                : "Save Framework"}
            </button>
          </div>
        </div>
        <Form className="frameworkPopup">
          <Row className="inputsSection">
            <Col>
              <Form.Group>
                <Form.Label>Name</Form.Label>
                <Form.Control
                  onChange={(e) => setData({ ...data, title: e.target.value })}
                  defaultValue={data.title}
                  type="text"
                  placeholder="Type Here"
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Short Description</Form.Label>
                <Form.Control
                  defaultValue={data.description}
                  onChange={(e) =>
                    setData({ ...data, description: e.target.value })
                  }
                  type="text"
                  placeholder="Type Here"
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="titlesSection">
            {/* left-side-html */}
            <Col className="allTitles">
              <div className="frameworkTitlebox">
                <div className="titleBox">
                  <h5>Titles</h5>
                  <button className="blackBtn" onClick={handleOpenModal2}>
                    Add Title
                  </button>
                </div>

                <div className="searchBox">
                  <input type="text" placeholder="Search" />
                </div>

                <div className="addedFrameworks"></div>
                {/* ========== */}
                <div ref={drop} className="dragdropbox custom-scroll">
                  {/* 1 */}
                  {titlesData.map((item, index) => (
                    <LeftSideItem
                      key={item.id}
                      item={item}
                      index={index}
                      moveItem={moveItem}
                      onDrop={handleDrop}
                      handleDrop={handleDrop}
                      setShowTitleModal={setShowTitleModal}
                      setTitleData={setTitleData}
                      emptyTitleData={emptyTitleData}
                      titleData={titleData}
                      handleEditTitle={handleEditTitle}
                      setEditTitleSource={setEditTitleSource}
                    />
                  ))}
                </div>
              </div>
            </Col>
            {/* right-side-html */}
            <Col className="selectedTitles">
              <div className="frameworkTitlebox selectedFramworks">
                <div className="titleBox" style={{ paddingTop: "8px" }}>
                  <h5>Framework</h5>
                </div>

                <div className="custom-scroll framework-droparea" sm={12}>
                  <DragandDropBox
                    onDrop={handleDrop}
                    rightSideItems={rightSideItems}
                    handleDrop={handleDrop}
                    handleDelete={handleDelete}
                    moveItem={moveItem}
                    handleEditTitle={handleEditTitle}
                    setEditTitleSource={setEditTitleSource}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Form>

        <AddTitlePopup
          show={showTitleModal}
          handleClose={closeTitleModal}
          titleData={titleData}
          setTitleData={setTitleData}
          emptyTitleData={emptyTitleData}
          editTitleSource={editTitleSource}
          handleUpdate={handleUpdate}
        />
      </div>
    </>
  );
};
function AddTitlePopup({
  show,
  handleClose,
  titleData = {},
  setTitleData,
  emptyTitleData,
  editTitleSource,
  handleUpdate,
}) {
  const user = useSelector((state) => state.dataSlice.user);
  const [createTitle, { isLoading: updatingTitle }] =
    useCreateFrameworkTitleMutation();
  const [updateTitle, { isLoading: creatingTitle }] =
    useUpdateFrameworkTitleMutation();

  const formik = useFormik({
    initialValues: {
      name: "",
      required_instruction: "",
      example: "",
    },
    onSubmit: (values) => {
      if (
        Object.keys(titleData).length > 0 &&
        (titleData.sortingId || titleData.id)
      ) {
        updateTitle({
          id: titleData.id,
          formData: {
            name: titleData.name,
            requiredInstructions: titleData.required_instruction,
            examples: titleData.example,
          },
        })
          .unwrap()
          .then(() => {
            if (editTitleSource === "right") handleUpdate();
            setTitleData(emptyTitleData);
            //
          })
          .catch((err) => {
            console.error(err);
          });
      } else {
        createTitle({
          // name: values.name,
          // context: values.context,
          // requirements: values.requirements,
          // focus_point: values.focus_point,
          // output_structure: values.output_structure,
          name: titleData.name,
          requiredInstructions: titleData.required_instruction,
          examples: titleData.example,
          userId: user.id,
        })
          .unwrap()
          .then(() => {
            setTitleData(emptyTitleData);

            //
          })
          .catch((err) => {
            console.error(err);
          });
      }
      handleClose();
    },
  });

  return (
    <Modal
      className="modal-right"
      show={show}
      onHide={() => handleClose()}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h3>Add a New Title</h3>
          <p>Fill in the information to Continue</p>
        </Modal.Title>
      </Modal.Header>

      <Form className="frameworkPopup" onSubmit={formik.handleSubmit}>
        <Modal.Body>
          <Row>
            <Col sm={12}>
              <Form.Group>
                <Form.Label>Title Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Name Here"
                  name="name"
                  onChange={(e) => {
                    setTitleData({ ...titleData, name: e.target.value });
                  }}
                  value={titleData.name || formik.values.name}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Required Instructions</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Teach me what this topic is all about"
                  name="requiredInstructions"
                  onChange={(e) => {
                    setTitleData({
                      ...titleData,
                      required_instruction: e.target.value,
                    });
                  }}
                  value={
                    titleData.required_instruction ||
                    formik.values.required_instruction
                  }
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Examples</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Tell me what is absolutely required to find or to avoid"
                  name="examples"
                  onChange={(e) => {
                    setTitleData({
                      ...titleData,
                      example: e.target.value,
                    });
                  }}
                  value={titleData.example || formik.values.example}
                />
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <button type="reset" className="cancelBtn" onClick={handleClose}>
            Cancel
          </button>
          <button type="submit" className="doneBtn">
            Save Title
          </button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
function MyDeletePopup(props) {
  return (
    <Modal
      className="frameworkModal"
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Are you sure you want to delete this?
        </Modal.Title>
      </Modal.Header>
      <div className="frameworkPopup">
        <Modal.Footer>
          <button type="reset" onClick={props.onHide} className="cancelBtn">
            Close
          </button>
          <button
            onClick={props.confirm}
            disabled={props.deleting}
            className="doneBtn"
          >
            {props.deleting ? "Deleting" : "Delete"}
          </button>
        </Modal.Footer>
      </div>
    </Modal>
  );
}
// ============Popup-End===============

// ==========function-start============
const ItemTypes = {
  ACCORDION_ITEM: "accordion-item",
};

const LeftSideItem = ({
  item,
  index,
  moveItem,
  onDrop,
  setShowTitleModal,
  setTitleData,
  emptyTitleData,
  titleData,
  handleEditTitle,
  setEditTitleSource,
}) => {
  const titlesList = useSelector((state) => state.dataSlice.titles);

  const [, drag] = useDrag({
    type: ItemTypes.ACCORDION_ITEM,
    item: {
      id: item.id,
      name: item.title,
      content: item.content,
      source: "left",
      sortingId: item.sortingId,
    },
    options: {
      dropEffect: "move",
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: ItemTypes.ACCORDION_ITEM,
    drop: (draggedItem) => {
      if (draggedItem.source === "left") {
        if (draggedItem.sortingId !== index) {
          moveItem("left", draggedItem.sortingId, index);
          draggedItem.sortingId = index;
          return;
        }
      } else onDrop("left", draggedItem);
    },
  });

  const [deleteTitle, { isLoading: titleDeleting }] =
    useDeleteFrameworkTitleMutation();

  const [modalDeleteShow, setModalDeleteShow] = useState(false);

  const handleDeleteTitle = (item = {}) => {
    if (item?.id) {
      setTitleData(emptyTitleData);
      const matchingTitle = titlesList.find(
        (title) => title.name === item.title
      );
      setTitleData(matchingTitle);
      setModalDeleteShow(true);
      return;
    } else {
      deleteTitle(titleData.id)
        .unwrap()
        .then(() => {
          setTitleData(emptyTitleData);
          setModalDeleteShow(false);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  return (
    <>
      <div ref={(node) => drag(drop(node))}>
        <div className="title">
          <div className="itemsGroup">
            <img
              src={dndIconWhite}
              alt="drag and drop icon"
              style={{
                height: "20px",
                width: "20px",
              }}
            />
            <p>{item.title}</p>
          </div>
          <div className="itemsGroup" style={{ color: "#fff" }}>
            <i
              className="fa-solid fa-pen"
              onClick={() => {
                setEditTitleSource("left");
                handleEditTitle(item);
              }}
            ></i>
            <i
              className="fa-regular fa-trash-can"
              onClick={() => handleDeleteTitle(item)}
              // onClick={() => setModalDeleteShow(true)}
            ></i>
            {/* <i className="fa-solid fa-arrow-right"></i> */}
          </div>
        </div>
      </div>

      <MyDeletePopup
        show={modalDeleteShow}
        confirm={handleDeleteTitle}
        deleting={titleDeleting}
        onHide={() => {
          setTitleData(emptyTitleData);
          setModalDeleteShow(false);
        }}
      />
    </>
  );
};
const RightSide = ({
  item,
  onDrop,
  index,
  moveItem,
  handleEditTitle,
  setEditTitleSource,
}) => {
  const [, drag] = useDrag({
    type: ItemTypes.ACCORDION_ITEM,
    item: {
      id: item.id,
      name: item.title,
      content: item.content,
      source: "right",
      sortingId: item.sortingId,
    },
    options: {
      dropEffect: "move",
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: ItemTypes.ACCORDION_ITEM,
    drop: (draggedItem) => {
      if (draggedItem.source === "right") {
        if (draggedItem.sortingId !== index) {
          moveItem("right", draggedItem.sortingId, index);
          draggedItem.sortingId = index;
        }
      } else {
        onDrop("right", draggedItem);
        // setItemDropped(true);
      }
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });

  return (
    <>
      <div ref={(node) => drag(drop(node))}>
        <div className="item">
          <div className="number">
            <p>{index + 1}</p>
          </div>
          <div className="title">
            <p>{item?.headline?.name || item?.headline?.title || ""}</p>
            <p>
              {/* <i
                className="fa-solid fa-xmark"
                style={{
                  height: "20px",
                  width: "20px",
                }}
              ></i> */}
              <i
                className="fa-solid fa-pen"
                onClick={() => {
                  setEditTitleSource("right");
                  handleEditTitle(item);
                }}
              ></i>
            </p>
          </div>
          <div className="dnd">
            {" "}
            <img
              src={dndIconWhite}
              alt="drag and drop icon"
              style={{
                height: "20px",
                width: "20px",
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};
// ==========function-end============

// =======drag-and-drop-UI-start=======
// Create a component called Content
const DragandDropBox = ({
  onDrop,
  rightSideItems,
  moveItem,
  handleDelete,
  handleDrop,
  handleEditTitle,
  setEditTitleSource,
}) => {
  const [{ isOver }, drop] = useDrop({
    accept: ItemTypes.ACCORDION_ITEM,
    drop: (item) => {
      onDrop("right", item);
      // setItemDropped(true);
    },
    collect: (monitor) => {
      const item = monitor.getItem();
      return {
        isOver: !!monitor.isOver() && item && item.source === "left",
      };
    },
  });
  return (
    <div ref={drop} className="dragdropbox position-relative custom-scroll">
      {isOver && (
        <div
          className="position-absolute bg-black rounded-2 bg-opacity-50 text-white d-flex align-items-center justify-content-center"
          style={{
            inset: 0,
            width: "100%",
            height: "100%",
          }}
        >
          Drop Items here
        </div>
      )}

      {rightSideItems?.length ? (
        <>
          {rightSideItems.map((item, index) => (
            <RightSide
              key={item.id}
              item={item}
              items={rightSideItems}
              index={index}
              onDrop={handleDrop}
              handleDelete={handleDelete}
              moveItem={moveItem}
              handleEditTitle={handleEditTitle}
              setEditTitleSource={setEditTitleSource}
            />
          ))}
        </>
      ) : (
        <div className={`${isOver ? "opacity-0 " : ""} dragdropbox-empty`}>
          <div>
            <img src={framework64} alt="dnd" />
          </div>
          <h5>Add titles</h5>
          <p>Please drag and drop titles to create framework</p>
        </div>
      )}
    </div>
  );
};
// =======drag-and-drop-UI-start=======

export default FrameworksPage;
