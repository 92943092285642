import React from "react";
import { Col } from "react-bootstrap";
import enterCode from "../../img/entercode.png";
import Questiondetails from "../Resetpasswordpage/Questiondetails";
import Otpentercode from "./Otpentercode";

const Entercode = ({ showComponent3 }) => {
  return (
    <Col lg={6}>
      <div className="loginFieldBox otp">
        <Otpentercode
          heading="Enter Code"
          imgSrc={enterCode}
          paragraph="Check your Email for the 4 Digit Code"
          infPara="johndoe@gmail.com"
        />
        <form>
          <div className="otpFieldBox">
            <input type="text" placeholder="-" maxLength="1" />
            <input type="text" placeholder="-" maxLength="1" />
            <input type="text" placeholder="-" maxLength="1" />
            <input type="text" placeholder="-" maxLength="1" />
          </div>
          <button className="loginButton" onClick={showComponent3}>
            Continue
          </button>
        </form>
        <Questiondetails
          text="Already have an account?"
          linkText="Login"
          link="/login"
        />
      </div>
    </Col>
  );
};

export default Entercode;
