import React from "react";
import { Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Modal heading
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>Centered Modal</h4>
        <p>
          Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
          dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac
          consectetur ac, vestibulum at eros.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

const Pitchdeckcomponent = (props) => {
  const { companyname, account, dealRoom, pitchImg } = props.data;
  // const {pitchImg} = props;

  const [modalShow, setModalShow] = React.useState(false);

  return (
    <>
      <Col md={2}>
        <div className="accountBox">
          {/* <img src={pitchimg1} alt="statisticImg" /> */}
          <img src={pitchImg} alt="statisticImg" />

          <div className="companyName">
            <p className="name">{companyname}</p>
          </div>

          <p className="lastmeeting">
            Account: <span>{account}</span>
          </p>
          <p className="lastmeeting">
            Deal Room: <span>{dealRoom}</span>
          </p>
        </div>
      </Col>

      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

export default Pitchdeckcomponent;
