import React from "react";

const Passwordstrength = () => {
  return (
    <div className="passwordStrengthBox">
      <p>Strength</p>
      <div className="indicator">
        <div className="bar bluebar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>
    </div>
  );
};

export default Passwordstrength;
