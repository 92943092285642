import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import {
  ArcElement,
  CategoryScale,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Tooltip,
} from "chart.js";
import { Chart as ChartJS } from "chart.js/auto";
import React, { useEffect, useState } from "react";
import { Accordion, Col, Container, Form, Modal, Row } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { RxCross1, RxPencil1 } from "react-icons/rx";
import { PiCheckBold } from "react-icons/pi";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import {
  useGenerateEvidenceSummaryMutation,
  useUpdateEvidenceScoreMutation,
} from "../../redux-setup/apiSlice";
import { toast } from "react-toastify";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement
);

const BusinessCase = ({
  templates = {},
  evData = {},
  selectedFrameWork,
  setSelectedFrameWork,
}) => {
  // New setup =================================================================
  const { frameWork = [] } = templates;
  const [selectedFrameWorkData, setSelectedFrameWorkData] = useState({});
  const [evidenceData, setEvidenceData] = useState(evData || {});

  useEffect(() => {
    if (selectedFrameWork) {
      setEvidenceData({ ...evData });
      const tempFrameWork = frameWork.find(
        (fw) => parseInt(fw.id) === parseInt(selectedFrameWork)
      );
      if (tempFrameWork) setSelectedFrameWorkData({ ...tempFrameWork });
    } else {
      setEvidenceData({});
      setSelectedFrameWorkData({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [evData, selectedFrameWork]);
  const displayedItems = selectedFrameWorkData.headlines
    ? selectedFrameWorkData.headlines
        .map((it) => {
          const item = it.headline.title;
          const value = evidenceData?.userContent?.[item] || {
            data: [
              {
                content: [],
                title: "",
              },
            ],
            removedFromBusinessCase: false,
          };
          return { item, value };
        })
        .filter(({ value }) => !value.removedFromBusinessCase)
        .map(({ item, value }) => [item, value])
    : [];

  // New setup End =================================================================

  return (
    <>
      {/* <button >Download PDF</button> */}
      <Container>
        <div className="tabs-wrapper">
          <div className="bcb__evidence">
            <div className="frameworkTitlebox">
              {/* ========== */}
              <Container>
                <Row>
                  <Col sm={12} className="openbuttonbox">
                    <div className="frameworkSelection">
                      <Form.Label
                        style={{
                          fontWeight: 500,
                          fontSize: "14px",
                          lineHeight: "21px",
                          color: "#475569",
                        }}
                      >
                        Framework:{" "}
                      </Form.Label>
                      <Form.Select
                        defaultValue={selectedFrameWork || ""}
                        value={selectedFrameWork}
                        onChange={(e) =>
                          setSelectedFrameWork(
                            e.target.value === "Select" ? "" : e.target.value
                          )
                        }
                        aria-label="Select Framework"
                      >
                        <option>Select</option>;
                        {frameWork?.map((it, i) => (
                          <option key={i} value={it.id}>
                            {it.title}
                          </option>
                        ))}
                      </Form.Select>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
          <ManageDisplayedItems
            displayedItems={displayedItems}
            evidenceData={evidenceData}
            setEvidenceData={setEvidenceData}
          />
        </div>
      </Container>
    </>
  );
};

const ManageDisplayedItems = ({
  displayedItems,
  evidenceData,
  setEvidenceData,
}) => {
  const [dataModel, setDataModel] = useState({});
  const [removeItemData, setRemoveItemData] = useState({
    modal: false,
    item: "",
  });
  const [editTitle, setEditTitle] = useState({
    currentId: null,
    prevTitle: "",
    newTitle: "",
  });

  // Methods

  const handleDataModel = (eventKey, action) => {
    // const expandedItem = eventKey ? displayedItems[eventKey - 1][0] : null;

    setDataModel((prevDataModel) => {
      return Object.keys(prevDataModel).reduce((acc, key, index) => {
        const isExpandedItem = index === eventKey - 1; // Check if this is the expanded item
        acc[key] = {
          ...prevDataModel[key],
          // expanded: action === "expand" ? key === expandedItem : false,
          expanded: action === "expand" ? isExpandedItem : false,
          displayMode:
            action === "edit" && isExpandedItem
              ? prevDataModel[key]?.displayMode === "edit"
                ? "view"
                : "edit"
              : prevDataModel[key]?.displayMode || "view",
        };
        return acc;
      }, {});
    });
  };

  const handleRemovalFromBusinessCase = (item) => {
    let updatedEvidenceData = structuredClone(evidenceData);
    for (const sectionKey in updatedEvidenceData.userContent) {
      if (sectionKey === item)
        updatedEvidenceData.userContent[sectionKey][
          "removedFromBusinessCase"
        ] = true;
    }
    setEvidenceData(updatedEvidenceData);
  };

  const [updateEvidence] = useUpdateEvidenceScoreMutation();
  const handleTitleUpdate = (newTitle, item) => {
    let updatedEvidenceData = structuredClone(evidenceData);
    for (const sectionKey in updatedEvidenceData.userContent) {
      if (sectionKey === item) {
        updatedEvidenceData.userContent[sectionKey]["titleInBusinessCase"] =
          newTitle;
      }
    }
    setEvidenceData(updatedEvidenceData);
    setEditTitle((prev) => ({
      ...prev,
      currentId: null,
      prevTitle: "",
      newTitle: "",
    }));
    updateEvidence({
      id: evidenceData?.id,
      data: { userContent: updatedEvidenceData.userContent },
    })
      .unwrap()
      .then(() => {
        toast.success("Evidence Data Updated Successfully");
      })
      .catch(() => {
        toast.error("Could not Update Evidence Data ");
      });
  };

  useEffect(() => {
    setDataModel((prevDataModel) => {
      const updatedDataModel = displayedItems.reduce((acc, [item]) => {
        acc[item] = prevDataModel[item] || {
          expanded: false,
          displayMode: "view",
        };
        return acc;
      }, {});
      return updatedDataModel;
    });
  }, [displayedItems]);

  return (
    <div
      style={{
        height: "100%",
        maxHeight: "calc(100vh - 460px)",
      }}
      className="custom-scroll"
    >
      <Accordion
        className="mt-3 businessCase-tab"
        onSelect={(eventKey) => handleDataModel(eventKey, "expand")}
      >
        {displayedItems?.length > 0 ? (
          displayedItems.map(
            ([item, value], diplayedItemIndex) =>
              (Array.isArray(value) ? value : value?.data) &&
              (Array.isArray(value) ? value : value?.data).length > 0 && (
                <Accordion.Item
                  eventKey={diplayedItemIndex + 1}
                  key={diplayedItemIndex}
                >
                  <Accordion.Header>
                    <div className="businessCase-tab-title">
                      {editTitle.currentId !== diplayedItemIndex ? (
                        value?.titleInBusinessCase ?? item ?? ""
                      ) : (
                        <input
                          placeholder="Enter title"
                          name="main_heading"
                          type="text"
                          value={
                            editTitle?.newTitle ??
                            value?.titleInBusinessCase ??
                            item ??
                            ""
                          }
                          onChange={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setEditTitle((prev) => ({
                              ...prev,
                              newTitle: e.target.value,
                            }));
                          }}
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                          }}
                          onKeyDown={(e) => {
                            e.stopPropagation();
                          }}
                        />
                      )}
                      {editTitle.currentId !== diplayedItemIndex ? (
                        <RxPencil1
                          size={18}
                          onClick={(e) => {
                            e.stopPropagation();
                            setEditTitle((prev) => ({
                              ...prev,
                              prevTitle: value?.titleInBusinessCase ?? item,
                              newTitle: value?.titleInBusinessCase ?? item,
                              currentId: diplayedItemIndex,
                            }));
                          }}
                        />
                      ) : (
                        <div className="d-flex gap-2">
                          <RxCross1
                            size={18}
                            onClick={(e) => {
                              e.stopPropagation();
                              setEditTitle((prev) => ({
                                ...prev,
                                newTitle: editTitle.prevTitle,
                                currentId: null,
                              }));
                            }}
                          />
                          <PiCheckBold
                            size={18}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleTitleUpdate(editTitle.newTitle, item);
                            }}
                          />
                        </div>
                      )}
                    </div>
                    <div className="d-flex gap-4">
                      {dataModel[item]?.displayMode === "edit" ? (
                        <PiCheckBold
                          size={18}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDataModel(diplayedItemIndex + 1, "edit");
                          }}
                        />
                      ) : (
                        <RxPencil1
                          size={18}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDataModel(diplayedItemIndex + 1, "edit");
                          }}
                        />
                      )}
                      <RxCross1
                        size={18}
                        onClick={(e) => {
                          e.stopPropagation();

                          // handleRemovalFromBusinessCase(e, item)
                          setRemoveItemData({
                            modal: true,
                            item: item,
                          });
                        }}
                      />
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    {dataModel[item]?.displayMode === "edit" ? (
                      <EditModeDisplay
                        mainItem={item}
                        value={value}
                        evidenceData={evidenceData}
                        setEvidenceData={setEvidenceData}
                      />
                    ) : (
                      <ViewModeDisplay
                        value={value}
                        evidenceData={evidenceData}
                        setEvidenceData={setEvidenceData}
                      />
                    )}
                  </Accordion.Body>
                </Accordion.Item>
              )
          )
        ) : (
          <p style={{ marginBottom: 0 }}>No data to show</p>
        )}
      </Accordion>
      <MyDeletePopup
        show={removeItemData.modal}
        item={removeItemData.item}
        removeItem={handleRemovalFromBusinessCase}
        onHide={() => setRemoveItemData(false)}
      />
    </div>
  );
};

const EditModeDisplay = ({
  mainItem,
  value,
  evidenceData,
  setEvidenceData,
}) => {
  const [listOfExpandedViews, setListOfExpandedViews] = useState({});
  const [optionsSliceKeys, setOptionsSliceKeys] = useState({});
  const sortContentByScore = (content) => {
    const inCludedContentOnly = content.filter((item) => item.score !== 0);
    const filteredContent = inCludedContentOnly.filter(
      (item) => item.usedInBusinessCase !== true
    );
    return filteredContent.sort((a, b) => b.score - a.score);
  };

  const handleExpandedViews = (title, actionType) => {
    setOptionsSliceKeys((prevState) => ({
      ...prevState,
      [title]: 0,
    }));

    setListOfExpandedViews((prevViews) => ({
      ...prevViews,
      [title]: {
        expanded:
          actionType === "toggleExpand"
            ? !prevViews[title]?.expanded || false
            : prevViews[title]?.expanded || false,
        summaryEditor:
          actionType === "showEditor"
            ? !prevViews[title]?.summaryEditor || false
            : prevViews[title]?.summaryEditor || false,
      },
    }));
  };

  const handleUpdateSliceKeys = (title, length) => {
    setOptionsSliceKeys((prevState) => {
      const newIndex = optionsSliceKeys[title] + 3;
      if (newIndex >= length) {
        return prevState;
      }
      return {
        ...prevState,
        [title]: newIndex,
      };
    });
  };

  const handleUsageInBusinessCase = (item, evidence, mode) => {
    let updatedEvidenceData = structuredClone(evidenceData);
    for (const sectionKey in updatedEvidenceData.userContent) {
      const section = updatedEvidenceData.userContent[sectionKey];
      const matchingItem = (
        Array.isArray(section) ? section : section?.data
      )?.find((contentItem) => contentItem.title === item.title);

      if (matchingItem) {
        const matchingEvidence = matchingItem.content.find(
          (content) => content.evidence === evidence
        );

        if (matchingEvidence) {
          matchingEvidence.usedInBusinessCase = mode === "check" ? true : false;
          break;
        }
      }
    }
    setEvidenceData(updatedEvidenceData);
  };
  const handleUpdateSummary = (mode, item, data = null) => {
    if (mode === "set") {
      setListOfExpandedViews((prevViews) => ({
        ...prevViews,
        [item.title]: {
          expanded: prevViews[item.title]?.expanded || false,
          summaryEditor: prevViews[item.title]?.summaryEditor || false,
          summaryContent: data,
        },
      }));
    }

    if (mode === "save") {
      let updatedEvidenceData = structuredClone(evidenceData);
      const summaryContent = listOfExpandedViews[item.title]?.summaryContent;
      for (const sectionKey in updatedEvidenceData.userContent) {
        const section = updatedEvidenceData.userContent[sectionKey];

        const matchingItem = (
          Array.isArray(section) ? section : section?.data
        )?.find((contentItem) => contentItem.title === item.title);

        if (matchingItem) {
          matchingItem.summary = summaryContent;
          break;
        }
      }
      setEvidenceData(updatedEvidenceData);
    }

    if (mode === "both") {
      setListOfExpandedViews((prevViews) => ({
        ...prevViews,
        [item.title]: {
          expanded: prevViews[item.title]?.expanded || false,
          summaryEditor: prevViews[item.title]?.summaryEditor || false,
          summaryContent: data,
        },
      }));
      let updatedEvidenceData = structuredClone(evidenceData);
      const summaryContent = data;
      for (const sectionKey in updatedEvidenceData.userContent) {
        const section = updatedEvidenceData.userContent[sectionKey];

        const matchingItem = (
          Array.isArray(section) ? section : section?.data
        )?.find((contentItem) => contentItem.title === item.title);

        if (matchingItem) {
          matchingItem.summary = summaryContent;
          break;
        }
      }
      setEvidenceData(updatedEvidenceData);
      updateEvidence({
        id: updatedEvidenceData?.id,
        data: { userContent: updatedEvidenceData.userContent },
      })
        .unwrap()
        .then(() => {
          toast.success("Evidence Data Updated Successfully");
        })
        .catch(() => {
          toast.error("Could not Update Evidence Data ");
        });
      handleExpandedViews(item?.title || "", "toggleExpand");
    }
  };

  const [updateEvidence] = useUpdateEvidenceScoreMutation();
  const [idForGenerateSummaryButton, setIDForGenerateSummaryButton] =
    useState(null);
  const [generateSummary, { isLoading: isGeneratingSummary }] =
    useGenerateEvidenceSummaryMutation({});
  const handleGenerateSummary = (item, index) => {
    setIDForGenerateSummaryButton(index);
    const payload = {};
    let updatedEvidenceData = structuredClone(evidenceData);
    payload["id"] = updatedEvidenceData?.id || 0;
    payload["main_heading"] = mainItem;
    payload["sub_heading"] = item?.title || "";
    generateSummary(payload)
      .unwrap()
      .then(() => {
        toast.success("Summary generated!");
        setIDForGenerateSummaryButton(null);
      })
      .catch(() => {
        toast.error("Could not generate summary");
        setIDForGenerateSummaryButton(null);
      });
    return;
  };

  return (
    <div className="businessCase-tab-content">
      {(Array.isArray(value) ? value : value?.data).map((item, itemIndex) => (
        <React.Fragment key={itemIndex}>
          <div className="content-title">
            <p>{item?.title ?? ""}</p>
            {!listOfExpandedViews?.[item?.title]?.expanded ? (
              <div className="d-flex gap-2">
                {item?.content.filter(
                  (contentItem) => contentItem.usedInBusinessCase
                ).length > 0 && (
                  <>
                    <button
                      type="button"
                      className="content-title-button"
                      onClick={() => handleGenerateSummary(item, itemIndex)}
                      disabled={isGeneratingSummary || false}
                      style={{
                        color: "#017CFF",
                        backgroundColor: "#fff",
                        display: "flex",
                        justifyContent: "center",
                        width: "127px",
                      }}
                    >
                      {isGeneratingSummary &&
                      idForGenerateSummaryButton === itemIndex ? (
                        <AiOutlineLoading3Quarters className="icon-rotating" />
                      ) : (
                        "Generate Summary"
                      )}
                    </button>
                    <button
                      type="button"
                      className="content-title-button"
                      onClick={() =>
                        handleExpandedViews(item?.title || "", "showEditor")
                      }
                      style={{
                        color: `${
                          listOfExpandedViews?.[item?.title]?.summaryEditor
                            ? "#fff"
                            : "#017CFF"
                        }`,
                        backgroundColor: `${
                          listOfExpandedViews?.[item?.title]?.summaryEditor
                            ? "#E11D48"
                            : "#fff"
                        }`,
                      }}
                    >
                      {`${
                        listOfExpandedViews?.[item?.title]?.summaryEditor
                          ? "Close Editor"
                          : "Edit Evidence"
                      }`}
                    </button>
                  </>
                )}
                <button
                  type="button"
                  className="content-title-button"
                  onClick={() =>
                    handleExpandedViews(item?.title || "", "toggleExpand")
                  }
                  style={{
                    color: `${
                      listOfExpandedViews?.[item?.title]?.expanded
                        ? "#E11D48"
                        : "#017CFF"
                    }`,
                  }}
                >
                  {/* {`${
                                    item?.content.filter(
                                      (contentItem) =>
                                        contentItem.usedInBusinessCase
                                    ).length > 0
                                      ? "Edit Evidence"
                                      : " */}
                  Add Evidence
                </button>
              </div>
            ) : (
              <div
                style={{
                  height: "45px",
                }}
              ></div>
            )}
          </div>
          <div
            className={`content-body ${
              listOfExpandedViews?.[item?.title]?.expanded && "edit-mode"
            }`}
          >
            {listOfExpandedViews?.[item?.title]?.expanded ? (
              <>
                <div className="entry-container">
                  {item?.content
                    .filter((contentItem) => contentItem.usedInBusinessCase)
                    .map((contentItem, index) => (
                      <div key={index} className="entry-item">
                        <p className="entry-evidence">{contentItem.evidence}</p>
                        <ManageInclusionSelectors
                          item={item}
                          contentItem={contentItem}
                          handleUsageInBusinessCase={handleUsageInBusinessCase}
                        />
                      </div>
                    ))}
                </div>
              </>
            ) : (
              <div>
                {item?.content.filter(
                  (contentItem) => contentItem.usedInBusinessCase
                ).length > 0 ? (
                  listOfExpandedViews?.[item?.title]?.summaryEditor ? (
                    <div className="d-flex flex-column gap-2">
                      <CKEditor
                        editor={ClassicEditor}
                        data={
                          item?.summary || ""
                          // ||
                          // item?.content
                          //   ?.filter(
                          //     (contentItem) => contentItem.usedInBusinessCase
                          //   )
                          //   .map((contentItem) => contentItem.evidence)
                          //   .join(" ")
                        }
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          const tempElement = document.createElement("div");
                          tempElement.innerHTML = data;
                          handleUpdateSummary(
                            "set",
                            item,
                            tempElement.textContent ||
                              tempElement.innerText ||
                              ""
                          );
                        }}
                      />
                      <div className="operations">
                        <button
                          className="btn-primary"
                          onClick={() => handleUpdateSummary("save", item)}
                        >
                          Update Summary
                        </button>
                      </div>
                    </div>
                  ) : (
                    <p className="no-evidence-message">
                      {item?.summary ||
                        item?.content
                          ?.filter(
                            (contentItem) => contentItem.usedInBusinessCase
                          )
                          .map((contentItem) => contentItem.evidence)
                          .join(" ")}
                    </p>
                  )
                ) : (
                  <p className="no-evidence-message">
                    No evidence points added
                  </p>
                )}
              </div>
            )}

            {listOfExpandedViews?.[item?.title]?.expanded && (
              <>
                <div className="options-to-choose">
                  {sortContentByScore(item?.content) &&
                  sortContentByScore(item?.content).length > 0 ? (
                    sortContentByScore(item?.content)
                      .slice(
                        optionsSliceKeys[item?.title] || 0,
                        (optionsSliceKeys[item?.title] || 0) + 3
                      )
                      .map((contentItem, contentItemIndex) => (
                        <div key={contentItemIndex} className="entry-item">
                          {contentItem.evidence}
                          <ManageInclusionSelectors
                            item={item}
                            contentItem={contentItem}
                            handleUsageInBusinessCase={
                              handleUsageInBusinessCase
                            }
                          />
                        </div>
                      ))
                  ) : (
                    <p className="no-evidence-message">
                      No more evidence points to display
                    </p>
                  )}
                </div>
                <div className="operations">
                  <button
                    className="btn-tertiary"
                    onClick={() => {
                      let data =
                        item?.summary ||
                        item?.content
                          ?.filter(
                            (contentItem) => contentItem.usedInBusinessCase
                          )
                          .map((contentItem) => contentItem.evidence)
                          .join(" ");
                      handleUpdateSummary("both", item, data);
                    }}
                  >
                    Save & Exit
                  </button>
                  <button
                    className={`btn-secondary ${
                      (optionsSliceKeys[item?.title] || 0) + 3 >=
                      sortContentByScore(item?.content).length
                        ? "disabled"
                        : ""
                    }`}
                    disabled={
                      (optionsSliceKeys[item?.title] || 0) + 3 >=
                      sortContentByScore(item?.content).length
                    }
                    onClick={() =>
                      handleUpdateSliceKeys(
                        item?.title || "",
                        sortContentByScore(item?.content).length
                      )
                    }
                  >
                    Refresh the List
                  </button>
                </div>
              </>
            )}
          </div>
        </React.Fragment>
      ))}
    </div>
  );
};
const ViewModeDisplay = ({ value, evidenceData, setEvidenceData }) => {
  const [listOfExpandedViews, setListOfExpandedViews] = useState({});
  const [optionsSliceKeys, setOptionsSliceKeys] = useState({});
  const sortContentByScore = (content) => {
    const inCludedContentOnly = content.filter((item) => item.score !== 0);
    const filteredContent = inCludedContentOnly.filter(
      (item) => item.usedInBusinessCase !== true
    );
    return filteredContent.sort((a, b) => b.score - a.score);
  };

  return (
    <div className="businessCase-tab-content-view">
      {(Array.isArray(value) ? value : value?.data).map((item, itemIndex) => (
        <div key={itemIndex}>
          <h4>{item?.title ?? ""}</h4>
          <div
            className={`content-body ${
              listOfExpandedViews?.[item?.title]?.expanded && "edit-mode"
            }`}
          >
            {listOfExpandedViews?.[item?.title]?.expanded ? (
              <>
                <div className="entry-container">
                  {item?.content
                    .filter((contentItem) => contentItem.usedInBusinessCase)
                    .map((contentItem, index) => (
                      <div key={index} className="entry-item">
                        <p className="entry-evidence">{contentItem.evidence}</p>
                      </div>
                    ))}
                </div>
              </>
            ) : (
              <div>
                <p className="no-evidence-message">
                  {item?.summary ||
                    item?.content
                      ?.filter((contentItem) => contentItem.usedInBusinessCase)
                      .map((contentItem) => contentItem.evidence)
                      .join(" ") ||
                    "No evidence points added"}
                </p>
              </div>
            )}

            {listOfExpandedViews?.[item?.title]?.expanded && (
              <>
                <div className="options-to-choose">
                  {sortContentByScore(item?.content) &&
                  sortContentByScore(item?.content).length > 0 ? (
                    sortContentByScore(item?.content)
                      .slice(
                        optionsSliceKeys[item?.title] || 0,
                        (optionsSliceKeys[item?.title] || 0) + 3
                      )
                      .map((contentItem, contentItemIndex) => (
                        <div key={contentItemIndex} className="entry-item">
                          {contentItem.evidence}
                        </div>
                      ))
                  ) : (
                    <p className="no-evidence-message">
                      No more evidence points to display
                    </p>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

const ManageInclusionSelectors = ({
  item,
  contentItem,
  handleUsageInBusinessCase,
}) => {
  return (
    <div className="settings">
      <div className="inclusionSelector">
        {contentItem.usedInBusinessCase ? (
          <button
            className="pill"
            onClick={() =>
              handleUsageInBusinessCase(item, contentItem.evidence, "uncheck")
            }
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_4434_12411)">
                <path
                  d="M12 4L4 12"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M4 4L12 12"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_4434_12411">
                  <rect width="16" height="16" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </button>
        ) : (
          <button
            className={`pill ${
              contentItem.usedInBusinessCase === true && "active-green"
            }`}
            onClick={() =>
              handleUsageInBusinessCase(item, contentItem.evidence, "check")
            }
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_4434_12436)">
                <path
                  d="M3.625 8L6.75 11.125L13 4.875"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_4434_12436">
                  <rect
                    width="15"
                    height="15"
                    fill="white"
                    transform="translate(0.5 0.5)"
                  />
                </clipPath>
              </defs>
            </svg>
          </button>
        )}
      </div>
    </div>
  );
};

const MyDeletePopup = (props) => {
  const handleRemove = () => {
    props.removeItem(props.item);
    props.onHide();
  };
  const handleCancel = () => {
    props.onHide();
  };
  return (
    <Modal
      className="frameworkModal"
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Are you sure?
        </Modal.Title>
      </Modal.Header>
      <Form className="frameworkPopup">
        <Modal.Body>
          <p>
            You are about to remove "{props.item}" from the business case. Are
            you sure you want to proceed?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="cancelBtn" onClick={handleCancel}>
            Cancel
          </button>
          <button type="button" className="doneBtn" onClick={handleRemove}>
            Remove
          </button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default BusinessCase;
