import React from "react";

const EmptyState = ({ title = "", subtitle = "" }) => {
  return (
    <div className="emptyState">
      <h3>{title || "No data found"}</h3>
      <p>{subtitle || "Please try again later"}</p>
    </div>
  );
};

export default EmptyState;
