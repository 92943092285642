import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import logo from "../../img/logo.png";

const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row>
          <Col lg={8} md={12} className="mx-auto">
            <div className="footerBox">
              <img src={logo} alt="" />
              <p className="subscribe">Subscribe to get our Newsletter</p>
              <div className="searchField">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Your Email"
                />
                <button className="blue">Subscribe</button>
              </div>
              <div className="links">
                <a href="#">Careers</a>
                <span className="bar" href="#"></span>
                <a href="#">Privacy policy</a>
                <span className="bar" href="#"></span>
                <a href="#">Terms & Conditions</a>
              </div>
              <p className="copyright">© 2023 AI SalesScript Pro Inc.</p>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
